export const FAQ = () => (
    <>
      <meta charSet="UTF-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
      <link
        rel="shortcut icon"
        href="assets/images/fav.png"
        type="image/x-icon"
      />
      <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
      <link rel="stylesheet" href="assets/css/fontawesome.min.css" />
      <link rel="stylesheet" href="assets/css/jquery-ui.css" />
      <link rel="stylesheet" href="assets/css/plugin/nice-select.css" />
      <link rel="stylesheet" href="assets/css/plugin/magnific-popup.css" />
      <link rel="stylesheet" href="assets/css/plugin/slick.css" />
      <link rel="stylesheet" href="assets/css/ara-font.css" />
      <link rel="stylesheet" href="assets/css/plugin/animate.css" />
      <link rel="stylesheet" href="assets/css/style.css" />
      
      {/* Scroll To Top Start*/}
      <a href="javascript:void(0)" className="scrollToTop">
        <i className="fas fa-angle-double-up" />
      </a>
      {/* Scroll To Top End */}
   {/* header-section start */}
   <header className="header-section">
            <div className="overlay">
                <div className="container">
                    <div className="row d-flex header-area">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <a className="navbar-brand" href="/home">
                                <img
                                    src="assets/images/fav.png"
                                    className="fav d-none d-lg-block d-xl-none"
                                    alt="fav"
                                />
                                <img
                                    src="assets/images/logo.png"
                                    className="logo d-block d-lg-none d-xl-block"
                                    alt="logo"
                                />
                            </a>
                            <button
                                className="navbar-toggler collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#navbar-content"
                            >
                                <i className="fas fa-bars" />
                            </button>
                            <div
                                className="collapse navbar-collapse justify-content-between"
                                id="navbar-content"
                            >
                                <div className="right-area header-action d-flex align-items-center max-un">
                                    <button
                                        type="button"
                                        className="login"
                                        data-bs-toggle="modal"
                                        data-bs-target="#loginMod"
                                    >
                                        Sign In
                                    </button>
                                    <button
                                        type="button"
                                        className="cmn-btn reg"
                                        data-bs-toggle="modal"
                                        data-bs-target="#loginMod"
                                    >
                                        Sign Up
                                    </button>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
        {/* header-section end */}
      {/* Login Registration start */}
      <div className="log-reg">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="modal fade" id="loginMod">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header justify-content-center">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <ul className="nav log-reg-btn d-none justify-content-around">
                      <li className="bottom-area" role="presentation">
                        <button
                          className="nav-link"
                          id="regArea-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#regArea"
                          type="button"
                          role="tab"
                          aria-controls="regArea"
                          aria-selected="false"
                        >
                          SIGN UP
                        </button>
                      </li>
                      <li className="bottom-area" role="presentation">
                        <button
                          className="nav-link active"
                          id="loginArea-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#loginArea"
                          type="button"
                          role="tab"
                          aria-controls="loginArea"
                          aria-selected="true"
                        >
                          LOGIN
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div
                        className="tab-pane fade show active"
                        id="loginArea"
                        role="tabpanel"
                        aria-labelledby="loginArea-tab"
                      >
                        <div className="login-reg-content">
                          <div className="modal-body">
                            <div className="form-area">
                              <form action="#">
                                <div className="section-text text-center">
                                  <h3 className="title">Welcome Back</h3>
                                  <p>
                                    Not a member ?{" "}
                                    <a
                                      href="javascript:void(0)"
                                      className="reg-btn"
                                    >
                                      Register
                                    </a>
                                  </p>
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/email-icon.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="Email" />
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/security.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="Password" />
                                </div>
                                <div className="checkbox-item d-flex justify-content-between">
                                  <label className="checkbox-single d-flex align-items-center">
                                    <span className="content-area">
                                      <span className="checkbox-area d-flex">
                                        <input type="checkbox" />
                                        <span className="checkmark" />
                                      </span>
                                      <span className="item-title d-flex align-items-center">
                                        <span>Remember me</span>
                                      </span>
                                    </span>
                                  </label>
                                  <a href="javascript:void(0)">Forget Password</a>
                                </div>
                                <div className="btn-area text-center">
                                  <a
                                    href="javascript:void(0)"
                                    className="cmn-btn mt-4"
                                  >
                                    Login
                                    <i className="icon-d-right-arrow-2" />
                                  </a>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="regArea"
                        role="tabpanel"
                        aria-labelledby="regArea-tab"
                      >
                        <div className="login-reg-content regMode">
                          <div className="modal-body">
                            <div className="form-area">
                              <form action="#">
                                <div className="section-text text-center">
                                  <h5 className="sub-title">
                                    Ready to get started?
                                  </h5>
                                  <h3 className="title">
                                    Welcome Bonus +50% SC!
                                  </h3>
                                  <p>
                                    Already have an account?{" "}
                                    <a
                                      href="javascript:void(0)"
                                      className="log-btn"
                                    >
                                      Login
                                    </a>
                                  </p>
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/email-icon.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="First Name" />
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/email-icon.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="Last Name" />
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/email-icon.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="Phone Number" />
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/email-icon.png"
                                    alt="icon"
                                  />
                                  <input
                                    type="date"
                                    placeholder="Date of Birth"
                                  />
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/email-icon.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="Email" />
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/security.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="Password" />
                                </div>
                                <div className="sidebar-single-item">
                                  <label className="checkbox-single d-flex">
                                    <span className="content-area">
                                      <span className="checkbox-area d-flex">
                                        <input
                                          type="checkbox"
                                          defaultChecked="checked"
                                        />
                                        <span className="checkmark" />
                                      </span>
                                      <span className="item-title d-flex align-items-center">
                                        <span>
                                          I am at least 18 years of age and I have
                                          read, accepted and agreed to the{" "}
                                          <u>
                                            <a href="javascript:void(0)">
                                              Privacy Policy
                                            </a>
                                            , Bonus T&amp;C,{" "}
                                            <a href="javascript:void(0)">
                                              Terms and Conditions
                                            </a>
                                          </u>
                                        </span>
                                      </span>
                                    </span>
                                  </label>
                                  <label className="checkbox-single d-flex align-items-center">
                                    <span className="content-area">
                                      <span className="checkbox-area d-flex">
                                        <input type="checkbox" />
                                        <span className="checkmark" />
                                      </span>
                                      <span className="item-title d-flex align-items-center">
                                        <span>
                                          I want to receive promotions by e-mail.
                                        </span>
                                      </span>
                                    </span>
                                  </label>
                                  <div className="btn-area text-center">
                                    <a
                                      href="javascript:void(0)"
                                      className="cmn-btn mt-4"
                                    >
                                      Get Started Now
                                      <i className="icon-d-right-arrow-2" />
                                    </a>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Login Registration end */}
      {/* Banner Section start */}
      <section className="banner-section inner-banner about-us">
        <div className="overlay">
          <div className="banner-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-10">
                  <div className="main-content">
                    <h2>frequently Asked Questions</h2>
                    <div className="breadcrumb-area">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb d-flex align-items-center">
                          <li className="breadcrumb-item">
                            <a href="index.html">Home</a>
                          </li>
                          <li className="breadcrumb-item">
                            <a href="javascript:void(0)">Pages</a>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            FAQ
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Banner Section end */}
      {/* Responsible Social Gaming */}
      <section className="privacy-content terms">
        <div className="overlay pt-120 pb-120">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="top-wrapper">
                  <h3>FAQ</h3><br />
                  <h5>General Questions</h5><br />
                  <h6>1. What is UltraSpin?</h6>
                  <p>
                  UltraSpin is a social gaming platform where users can play games and participate in daily and hourly prize wheels to win sweep stars, which can be redeemed for real prizes. We operate under a sweepstakes model to comply with legal requirements and provide an exciting, fun, and fair experience for our users.
                  </p>
                  <h6>2. How do I get started?</h6>
                  <p>
                  Simply sign up by creating an account on our website. Once registered, you’ll be able to play games, spin the prize wheels, and take advantage of promotions. We also require KYC (Know Your Customer) verification to comply with regulations, which involves submitting some identification documents.
                  </p>
                  <h6>3. Is UltraSpin legal?</h6>
                  <p>
                  Yes, UltraSpin operates legally in most states under a sweepstakes model. However, we are restricted from offering our services to residents of certain states, which are listed in our Terms and Conditions.
                  </p>
                  <h6>4. Which states are restricted?</h6>
                  <p>
                  As of now, UltraSpin does not accept users from Washington, Montana, Maryland, Pennsylvania, New Jersey, Connecticut, West Virginia, Louisiana, Rhode Island, Delaware, Nevada, Michigan, and Idaho. We also do not operate in any country or territory sanctioned by the Office of Foreign Assets Control (OFAC).
                  </p>
                  <h5>Account & Security</h5><br />
                  <h6>1. How do I verify my account?</h6>
                  <p>
                  Once you sign up, you’ll need to complete a KYC (Know Your Customer) verification process. This is done through our partner, Sumsub, who will ask for a government-issued ID and a proof of address. The verification helps us ensure compliance with legal and security standards.
                  </p>
                  <h6>2. What if I forget my password?</h6>
                  <p>
                  If you’ve forgotten your password, click on the "Forgot Password" link on the login page. You’ll receive an email with instructions on how to reset your password.
                  </p>
                  <h6>3. Can I change my personal information?</h6>
                  <p>
                  Yes, you can update your personal information by logging into your account and visiting the "Profile" section. Please ensure all details are accurate and up-to-date, especially for KYC purposes.
                  </p>
                  <h6>4. How do I protect my account?</h6>
                  <p>
                  o protect your account, use a strong, unique password, enable two-factor authentication (2FA) when available, and never share your login credentials with anyone. If you notice any suspicious activity, contact us immediately at support@ultraspin.vip.
                  </p>
                  <h5>Payments & Withdrawals</h5><br />
                  <h6>1. How do I deposit funds into my account?</h6>
                  <p>
                  UltraSpin accepts several forms of cryptocurrency for deposits. Currently, you can deposit using Litecoin (LTC). Each user is provided with a unique wallet address for depositing funds. Your account will be credited with the corresponding in-game currency after the transaction is confirmed on the blockchain.
                  </p>
                  <h6>2. How do withdrawals work?</h6>
                  <p>
                  Withdrawals are currently handled manually. You can request a withdrawal by submitting a request through your account. Please note that all withdrawal requests are subject to review and may take some time to process due to verification checks.
                  </p>
                  <h6>3. What are sweep stars, and how can I use them?</h6>
                  <p>
                  Sweep stars are a virtual currency that you can win through daily and hourly prize wheels, in-game events, and promotions. Sweep stars can be redeemed for real prizes or used to participate in sweepstakes on UltraSpin.
                  </p>
                  <h6>4. Can I purchase sweep stars?</h6>
                  <p>
                  No, sweep stars cannot be purchased directly. However, you can earn them through gameplay, prize wheels, and by participating in promotions.
                  </p>
                  <h5>Prize Wheels & Promotions</h5><br />
                  <h6>1. How do the prize wheels work?</h6>
                  <p>
                  UltraSpin offers an hourly prize wheels. You can spin these wheels for free for a chance to win sweep stars. 
                  </p>
                  <h6>2. How do I claim my prize after winning?</h6>
                  <p>
                  If you win sweep stars on the prize wheel, they will automatically be credited to your UltraSpin account. You can view your balance in the "Account" section.
                  </p>
                  <h6>3. Are there any other ways to win prizes?</h6>
                  <p>
                  Yes, aside from the prize wheels, we regularly run promotions, tournaments, and in-game events where you can win sweep stars, gold coins, and other rewards.
                  </p>
                  <h5>KYC & Legal Compliance</h5><br />
                  <h6>1. Why do I need to complete KYC verification?</h6>
                  <p>
                  KYC verification is required to ensure compliance with anti-money laundering (AML) regulations and to maintain the integrity of the platform. It also helps us protect against fraud and verify that all users are of legal age.
                  </p>
                  <h6>2. What documents are required for KYC?</h6>
                  <p>
                  You’ll need to provide a government-issued ID (such as a passport, driver’s license, or national ID card) and proof of address (such as a utility bill or bank statement). These documents will be securely processed by our KYC partner, Sumsub.
                  </p>
                  <h6>3. Is my personal information safe?</h6>
                  <p>
                  Yes, we take your privacy seriously. All information submitted for KYC purposes is handled securely and in compliance with data protection regulations, including GDPR. We do not share your information with third parties unless required by law.
                  </p>
                  <h5>Technical Support</h5><br />
                  <h6>1. I’m having trouble logging in, what should I do?</h6>
                  <p>
                  If you’re unable to log in, first ensure that your username and password are correct. If you’re still experiencing issues, you can reset your password by clicking "Forgot Password." If problems persist, contact our support team at support@ultraspin.vip. 
                  </p>
                  <h6>2. What if I encounter a bug or technical issue?</h6>
                  <p>
                  If you encounter any technical issues while using UltraSpin, please report them to our support team at support@ultraspin.vip. Our team will work to resolve any problems as quickly as possible. 
                  </p>
                  <h5>Still have questions?</h5>
                  <p>
                  If you didn’t find the answer you were looking for, feel free to reach out to us at support@ultraspin.vip, and we’ll be happy to assist you! 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Responsible Social Gaming */}
      {/* Footer Area Start */}
      <footer className="footer-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="footer-form">
                <div className="section-area mb-30 text-center">
                  <h4 className="title">To Get Exclusive Benefits</h4>
                </div>
                <form action="#">
                  <div className="form-group input-area d-flex align-items-center">
                    <input type="text" placeholder="Enter your email address" />
                    <button className="btn-area">
                      <img src="assets/images/icon/send-icon.png" alt="icon" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="footer-area pt-120">
            <div className="row">
              <div className="col-xl-12">
                <div className="footer-top d-flex align-items-center justify-content-between">
                  <a href="/home">
                    <img
                      src="assets/images/logo.png"
                      className="logo"
                      alt="logo"
                    />
                  </a>
                  <div className="footer-box">
                    <ul className="footer-link d-flex align-items-center gap-4">
                    <li>
                        <a href="/contact-us">Contact</a>
                      </li>
                      <li>
                        <a href="/privacy-policy">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/termandconditions">Terms of Service</a>
                      </li>
                      <li>
                        <a href="/kyc">KYC/AML</a>
                      </li>
                      <li>
                        <a href="/rsg">Responsible Social Gaming</a>
                      </li>
                      <li>
                        <a href="/faq">FAQ</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-bottom">
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-7 d-flex justify-content-center justify-content-lg-start order-lg-0 order-1">
                  <div className="copyright text-center">
                    <p>© Tropic Gamers 2023 | All rights reserved.</p>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-5 d-flex justify-content-center justify-content-lg-end">
                  <div className="social">
                    <ul className="footer-link gap-2 d-flex align-items-center">
                      <li>
                        <a href="javascript:void(0)">
                          <i className="fb fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <i className="ins fab fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <i className="tw fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <i className="in fab fa-linkedin-in" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* Footer Area End */}
      {/*==================================================================*/}
    </>
  )
  