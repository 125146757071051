// Preloader.js
import React from 'react';
import './CustomLoader.css'; // Your custom CSS for the animation
import SpinnerImage from "../../images/spinner.png";
const Preloader = () => {
  return (
    <div className="newpreloader">
      <img src={SpinnerImage} alt="Loading..." className="spinner" />
    </div>
  );
};

export default Preloader;
