export const RSGpolicy = () => (
    <>
      <meta charSet="UTF-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
      <link
        rel="shortcut icon"
        href="assets/images/fav.png"
        type="image/x-icon"
      />
      <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
      <link rel="stylesheet" href="assets/css/fontawesome.min.css" />
      <link rel="stylesheet" href="assets/css/jquery-ui.css" />
      <link rel="stylesheet" href="assets/css/plugin/nice-select.css" />
      <link rel="stylesheet" href="assets/css/plugin/magnific-popup.css" />
      <link rel="stylesheet" href="assets/css/plugin/slick.css" />
      <link rel="stylesheet" href="assets/css/ara-font.css" />
      <link rel="stylesheet" href="assets/css/plugin/animate.css" />
      <link rel="stylesheet" href="assets/css/style.css" />
      
      {/* Scroll To Top Start*/}
      <a href="javascript:void(0)" className="scrollToTop">
        <i className="fas fa-angle-double-up" />
      </a>
      {/* Scroll To Top End */}
   {/* header-section start */}
   <header className="header-section">
            <div className="overlay">
                <div className="container">
                    <div className="row d-flex header-area">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <a className="navbar-brand" href="/home">
                                <img
                                    src="assets/images/fav.png"
                                    className="fav d-none d-lg-block d-xl-none"
                                    alt="fav"
                                />
                                <img
                                    src="assets/images/logo.png"
                                    className="logo d-block d-lg-none d-xl-block"
                                    alt="logo"
                                />
                            </a>
                            <button
                                className="navbar-toggler collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#navbar-content"
                            >
                                <i className="fas fa-bars" />
                            </button>
                            <div
                                className="collapse navbar-collapse justify-content-between"
                                id="navbar-content"
                            >
                                <div className="right-area header-action d-flex align-items-center max-un">
                                    <button
                                        type="button"
                                        className="login"
                                        data-bs-toggle="modal"
                                        data-bs-target="#loginMod"
                                    >
                                        Sign In
                                    </button>
                                    <button
                                        type="button"
                                        className="cmn-btn reg"
                                        data-bs-toggle="modal"
                                        data-bs-target="#loginMod"
                                    >
                                        Sign Up
                                    </button>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
        {/* header-section end */}
      {/* Login Registration start */}
      <div className="log-reg">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="modal fade" id="loginMod">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header justify-content-center">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <ul className="nav log-reg-btn d-none justify-content-around">
                      <li className="bottom-area" role="presentation">
                        <button
                          className="nav-link"
                          id="regArea-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#regArea"
                          type="button"
                          role="tab"
                          aria-controls="regArea"
                          aria-selected="false"
                        >
                          SIGN UP
                        </button>
                      </li>
                      <li className="bottom-area" role="presentation">
                        <button
                          className="nav-link active"
                          id="loginArea-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#loginArea"
                          type="button"
                          role="tab"
                          aria-controls="loginArea"
                          aria-selected="true"
                        >
                          LOGIN
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div
                        className="tab-pane fade show active"
                        id="loginArea"
                        role="tabpanel"
                        aria-labelledby="loginArea-tab"
                      >
                        <div className="login-reg-content">
                          <div className="modal-body">
                            <div className="form-area">
                              <form action="#">
                                <div className="section-text text-center">
                                  <h3 className="title">Welcome Back</h3>
                                  <p>
                                    Not a member ?{" "}
                                    <a
                                      href="javascript:void(0)"
                                      className="reg-btn"
                                    >
                                      Register
                                    </a>
                                  </p>
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/email-icon.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="Email" />
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/security.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="Password" />
                                </div>
                                <div className="checkbox-item d-flex justify-content-between">
                                  <label className="checkbox-single d-flex align-items-center">
                                    <span className="content-area">
                                      <span className="checkbox-area d-flex">
                                        <input type="checkbox" />
                                        <span className="checkmark" />
                                      </span>
                                      <span className="item-title d-flex align-items-center">
                                        <span>Remember me</span>
                                      </span>
                                    </span>
                                  </label>
                                  <a href="javascript:void(0)">Forget Password</a>
                                </div>
                                <div className="btn-area text-center">
                                  <a
                                    href="javascript:void(0)"
                                    className="cmn-btn mt-4"
                                  >
                                    Login
                                    <i className="icon-d-right-arrow-2" />
                                  </a>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="regArea"
                        role="tabpanel"
                        aria-labelledby="regArea-tab"
                      >
                        <div className="login-reg-content regMode">
                          <div className="modal-body">
                            <div className="form-area">
                              <form action="#">
                                <div className="section-text text-center">
                                  <h5 className="sub-title">
                                    Ready to get started?
                                  </h5>
                                  <h3 className="title">
                                    Welcome Bonus +50% SC!
                                  </h3>
                                  <p>
                                    Already have an account?{" "}
                                    <a
                                      href="javascript:void(0)"
                                      className="log-btn"
                                    >
                                      Login
                                    </a>
                                  </p>
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/email-icon.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="First Name" />
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/email-icon.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="Last Name" />
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/email-icon.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="Phone Number" />
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/email-icon.png"
                                    alt="icon"
                                  />
                                  <input
                                    type="date"
                                    placeholder="Date of Birth"
                                  />
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/email-icon.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="Email" />
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/security.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="Password" />
                                </div>
                                <div className="sidebar-single-item">
                                  <label className="checkbox-single d-flex">
                                    <span className="content-area">
                                      <span className="checkbox-area d-flex">
                                        <input
                                          type="checkbox"
                                          defaultChecked="checked"
                                        />
                                        <span className="checkmark" />
                                      </span>
                                      <span className="item-title d-flex align-items-center">
                                        <span>
                                          I am at least 18 years of age and I have
                                          read, accepted and agreed to the{" "}
                                          <u>
                                            <a href="javascript:void(0)">
                                              Privacy Policy
                                            </a>
                                            , Bonus T&amp;C,{" "}
                                            <a href="javascript:void(0)">
                                              Terms and Conditions
                                            </a>
                                          </u>
                                        </span>
                                      </span>
                                    </span>
                                  </label>
                                  <label className="checkbox-single d-flex align-items-center">
                                    <span className="content-area">
                                      <span className="checkbox-area d-flex">
                                        <input type="checkbox" />
                                        <span className="checkmark" />
                                      </span>
                                      <span className="item-title d-flex align-items-center">
                                        <span>
                                          I want to receive promotions by e-mail.
                                        </span>
                                      </span>
                                    </span>
                                  </label>
                                  <div className="btn-area text-center">
                                    <a
                                      href="javascript:void(0)"
                                      className="cmn-btn mt-4"
                                    >
                                      Get Started Now
                                      <i className="icon-d-right-arrow-2" />
                                    </a>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Login Registration end */}
      {/* Banner Section start */}
      <section className="banner-section inner-banner about-us">
        <div className="overlay">
          <div className="banner-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-10">
                  <div className="main-content">
                    <h2>Responsible Social Gaming</h2>
                    <div className="breadcrumb-area">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb d-flex align-items-center">
                          <li className="breadcrumb-item">
                            <a href="index.html">Home</a>
                          </li>
                          <li className="breadcrumb-item">
                            <a href="javascript:void(0)">Pages</a>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            Responsible Social Gaming
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Banner Section end */}
      {/* Responsible Social Gaming */}
      <section className="privacy-content terms">
        <div className="overlay pt-120 pb-120">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="top-wrapper">
                  <h3>Responsible Social Gaming</h3>
                  <p>Version 1.0</p>
                  <p>Effective as of August, 2024, and supersedes and replaces all prior Responsible Social Gaming Policies”.</p>
                  <p>
                  Tropic Gamers LLC (“Tropic Gamers”) is committed to protecting our users and promoting 
                  responsible social gameplay as a policy of customer care and social responsibility. 
                  </p>
                  <p>
                  We are committed to providing you with the necessary tools and information to ensure that 
                  our games are consumed responsibly, so that you can enjoy your experience on our platform 
                  while remaining aware of the potential risks that may be associated with online gaming. 
                  </p>
                  <p>
                    <b>We encourage you to be aware of the responsible social gameplay tools described below that 
                    are available to you and to take advantage of them at your sole discretion. We reserve the right 
                    to activate these measures unilaterally if, in our absolute discretion, we deem it necessary.</b>
                  </p>
                  <h5>1. INTRODUCTION</h5>
                  <p>
                  This Responsible Social Gameplay Policy (RSG Policy) describes the control tools, 
                  information, and resources available to our users.
                  </p>
                  <p>
                  This RSG Policy forms part of the Terms and Conditions (T&C). Terms that are defined in the 
                  T&C have the same meaning in this RSG Policy.
                  </p>
                  <p>
                  We may update the RSG Policy at any time. In that case, we will post any such changes on our 
                  Website and will notify you by email. Whether you receive or review such notifications, you 
                  agree to be bound by such changes.
                  </p>
                  <p>
                  It is your responsibility to read and review this RSG Policy, and you also agree that continued 
                  use of the Website and the Games means you accept and agree to be bound by the revised 
                  RSG Policy.
                  </p>
                  <h5>2. Tropic Gamers RSG PROGRAM</h5>
                  <p>
                  The Tropic Gamers Responsible Social Gameplay Program (RSG Program) is centered around 
                  our guiding principles of providing our customers with control tools, information, and help 
                  resources needed to:
                  </p>
                  <ul>
                    <li>
                    • Make an informed decision when using our Website and Games
                    </li>
                    <li>
                    • Prevent any user problems associated with the use of our Website and Games
                    </li>
                  </ul>
                  <p>
                  The RSG Program is designed to support the needs of individuals at any stage of the user 
                  journey, from registration to redemption, and any level of their use of the Website and Games. 
                  To do this, the RSG Program offers a range of user education, control tools, and resources for 
                  professional help when needed.
                  </p>
                  <p>
                  Tropic Gamers understands that it is a shared responsibility to achieve a fun and affordable 
                  gameplay environment and that it is ultimately an individual’s choice to play. We do not 
                  provide counseling services nor do we police users' behavior. Instead, we focus on providing 
                  control tools and informing, educating, and supporting informed decisions.
                  </p>
                  <p>
                  Tropic Gamers has well-trained staff available to assist you with your gameplay. Our staff are 
                  encouraged and empowered to provide information and offer control tools proactively. 
                  </p>
                  <h5>3. RSG ACCESS CONTROL TOOLS</h5>
                  <p>
                  If your gameplay has become problematic, you can use the following access control tools:
                  </p>
                  <ul>
                    <li>
                    a) Taking a Break – Accessible by sending a request to support@ultraspin.vip
                    Allows you to take a short break (1 day, 3 days, 7 days, 14 days, or 30 days) during which you 
                    cannot access your account.
                    </li>
                    <li>
                    b) Self-Exclusion – Accessible by clicking ‘SELF-EXCLUSION” within the game lobby
                    allows you to suspend your own account. You will be unsubscribed from marketing 
                    communications and will not be able to access your account without a written request.
                    </li>
                    <li>
                    c) Permanent Closure – Accessible by sending a request to support@ultraspin.vip
                    If you choose to permanently close your account, this action is irreversible and cannot be 
                    reactivated under any circumstances.
                    </li>
                  </ul>
                  <h5>4. SELF-ASSESSMENT</h5>
                  <p>
                  If you think your or someone else’s gameplay on the Website is becoming problematic, 
                  consider the self-assessment questions provided in Section 9: What is Problematic 
                  Gameplay?
                  </p>
                  <h5>5. SUPPORT ORGANIZATIONS</h5>
                  <p>
                  If gameplay is negatively impacting your mental health, finances, or relationships, we 
                  encourage you to reach out to the following organizations:
                  </p>
                  <ul>
                    <li>
                    • Gaming Addicts Anonymous (GAA) for support on excessive gameplay.
                    </li>
                    <li>
                    • Financial Counseling Association of America (FCAA) and National Foundation for 
                    Credit Counseling (NFCC) for financial counseling.
                    </li>
                  </ul>
                  <p>
                  These organizations are independent and not affiliated with Tropic Gamers.
                  </p>
                  <h5>6. EDUCATION INFORMATION ON RESPONSIBLE SOCIAL GAMEPLAY</h5>
                  <p>
                    <ul>
                      <li>
                      • Randomness: Game outcomes are random and cannot be predicted.
                      </li>
                      <li>
                      • Return to Player (RTP): The average return on winnings over time.
                      </li>
                      <li>
                      • Advantage: All games have a slight advantage for the operator.
                      </li>
                    </ul>
                    Misconceptions like “I’m due for a win” or using lucky charms have no impact on the random 
                    outcomes of our games.
                  </p>
                  <h5>7. TIPS FOR SAFE GAMEPLAY</h5>
                  <p>
                  Some tips for safe gameplay include:
                  </p>
                  <ul>
                    <li>
                    • Avoid playing while upset or emotional
                    </li>
                    <li>
                    • Take frequent breaks
                    </li>
                    <li>
                    • Avoid playing while intoxicated
                    </li>
                    <li>
                    • Set a budget and time limit before playing
                    </li>
                    <li>
                    • Use money you can afford to lose
                    </li>
                  </ul>
                  <h5>8. WHAT IS PROBLEMATIC GAMEPLAY?</h5>
                  <p>
                  Problematic gameplay can affect your financial, family, employment, and health conditions. 
                  Know the signs of problematic gameplay such as borrowing money to play, hiding gameplay 
                  behavior, and depression associated with playing. If your gameplay is having any negative 
                  effects on your day to day life, consider taking a break or opting for self-exclusion.
                  </p>
                  <h5>9. USER PROTECTION POLICY</h5>
                  <p>
                  a) Protection of the Vulnerable<br />
                  Ensure your decision to play on our Website is your own choice.
                  </p>
                  <p>
                  b) Protection of Minors<br />
                  Tropic Gamers has identity checks in place to prevent underage gameplay. Use filtering 
                  software like Netnanny or Cybersitter to prevent minors from accessing the Website.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Responsible Social Gaming */}
      {/* Footer Area Start */}
      <footer className="footer-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="footer-form">
                <div className="section-area mb-30 text-center">
                  <h4 className="title">To Get Exclusive Benefits</h4>
                </div>
                <form action="#">
                  <div className="form-group input-area d-flex align-items-center">
                    <input type="text" placeholder="Enter your email address" />
                    <button className="btn-area">
                      <img src="assets/images/icon/send-icon.png" alt="icon" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="footer-area pt-120">
            <div className="row">
              <div className="col-xl-12">
                <div className="footer-top d-flex align-items-center justify-content-between">
                  <a href="/home">
                    <img
                      src="assets/images/logo.png"
                      className="logo"
                      alt="logo"
                    />
                  </a>
                  <div className="footer-box">
                    <ul className="footer-link d-flex align-items-center gap-4">
                    <li>
                        <a href="/contact-us">Contact</a>
                      </li>
                      <li>
                        <a href="/privacy-policy">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/termandconditions">Terms of Service</a>
                      </li>
                      <li>
                        <a href="/kyc">KYC/AML</a>
                      </li>
                      <li>
                        <a href="/rsg">Responsible Social Gaming</a>
                      </li>
                      <li>
                        <a href="/faq">FAQ</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-bottom">
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-7 d-flex justify-content-center justify-content-lg-start order-lg-0 order-1">
                  <div className="copyright text-center">
                    <p>© Tropic Gamers 2023 | All rights reserved.</p>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-5 d-flex justify-content-center justify-content-lg-end">
                  <div className="social">
                    <ul className="footer-link gap-2 d-flex align-items-center">
                      <li>
                        <a href="javascript:void(0)">
                          <i className="fb fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <i className="ins fab fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <i className="tw fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <i className="in fab fa-linkedin-in" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* Footer Area End */}
      {/*==================================================================*/}
    </>
  )
  