import React, { useState, useRef, useEffect } from 'react';
import './SpinningWheel.css';
import prizeWheel from './spinning_wheel_assets/prizewheel.png';
import closeIcon from '../../images/close-icon.png'
import prizeindicator from './spinning_wheel_assets/indicator.png';
import ReactConfetti from 'react-confetti';
import spinningSound from './spinning_wheel_assets/spinsound.wav';
import { ROOT_URL } from '../../utils/variable';

const modalStyles = {
  svgContainer: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    transform: 'translateY(-40px)',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '32px',
    fontWeight: '700',
    color: '#000',
    textShadow: '2px 2px 10px rgba(0, 0, 0, 0.6)',
  },
  
  button: {
    marginTop: '4px',
    padding: '10px 24px',
    border: 'none',
    borderRadius: '18px',
    cursor: 'pointer',
    backgroundColor: 'white',
    color: '#6A0DAD',
    transition: 'background-color 0.3s',
    marginBottom: '12px',
  },
  logoutButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center', 
    padding: '6px 20px',
    backgroundColor: 'red',
    border: '6px solid #F7CEB2',
    borderRadius: '36px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    fontSize: '32px',
    fontWeight: '800',
    color: '#fff',
    textShadow: '2px 2px 10px rgba(0, 0, 0, 0.6)',
  },
};

const SpinningWheel = ({ userId, onClose , onRewardCollected }) => {
  const [showSpinResult, setShowSpinResult] = useState(false);
  const [showErrorPopUp, setShowErrorPopUp] = useState(false);
  const [rotationAngle, setRotationAngle] = useState(0);
  const [errorMessage, setErrorMessage] = useState("unknown error");
  const [errorTitle, setErrorTitle] = useState("error");
  const [errorTextColor, setErrorTextColor] = useState("white");
  const [prizeMessage, setPrizeMessage] = useState("");
  const [showConfetti, setShowConfetti] = useState(false);
  const [reward, setReward] = useState(null); // Store reward in state
  const spinningRef = useRef(false);
  const animationRef = useRef(null);
  const audioRef = useRef(new Audio(spinningSound)); // Create audio instance here
  const [targetAngle, setPrizeAngle] = useState(null);
  const [stopDisabled, setStopDisabled] = useState(false);
  const [claimDisabled, setClaimDisabled] = useState(true);
  const [isApiCalled, setIsApiCalled] = useState(false); // Flag to track if the API has already been called

  const prizes = [0.02, 0.05, 0.10, 0.50, 0.02, 1.00, 0.02, 0.02, 0.10, 0.05, 0.02, 0.50, 0.10, 0.02, 0.05, 0.1];
  //please keep 16 values inside this array
  const WHEEL_SPEED = 0.4;
  const SMOOTH_STOP_DURATION = 1000;  
  const defaultRotationAngle = 0;

  useEffect(() => {
    console.log('useEffect triggered, userId:', userId);

    setStopDisabled(true);
    const checkUserId = () => {
      if (userId) {
        fetchPrize();
      } else {
        handleFailedToRetrieveUserDetails();
      }
    };
    checkUserId(); // fetch User Details when the component is mounted
  }, [userId]); // This will run whenever userId changes
  

  useEffect(() => {
    spinWheel(); // Start spinning when the component is mounted
    return () => {
      resetPopUps();
    };
  }, []);

  const fetchPrize = async () => {
    if (isApiCalled) {
      console.log('FETCH PRIZE API CALLED ALREADY')
      return; 
    }

    setIsApiCalled(true); 

    try {
      if (userId == null) {
        handleErrorPopUp("Identification Error","User ID not found","red");
        return;
      }
      // Adding delay (10-15 seconds)
      // const delay = Math.floor(Math.random() * (15000 - 10000 + 1)) + 10000;

      // await new Promise(resolve => setTimeout(resolve, delay));
      const response = await fetch(`${ROOT_URL}game/spin?user_id=${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      console.log("userId: ",userId)
      console.log("data: ",data)
      if (data) {
        if (data.message) {
          console.log("data.value:", data.value);  
          console.log("parsed value:", parseFloat(data.value));  
          
          if (parseFloat(data.value) > 0) {
            setPrizeMessage(data.message);
            getPrizeAngle(data.value);
            setStopDisabled(false);
          } else {
            handleCooldownErrorError(data.message);
          }
        } else {
          handleUnknownFormatError();
        }
      } else {
        handleInvalidDataError();
      }
      
    } catch (error) {
      handleFetchError();
    }finally {
      setIsApiCalled(false); // Reset fetching flag after the API call completes
    }
  };

  const updateBalance = () => {
    console.log("from wheel ",reward)
    onRewardCollected(reward);
  }

  const handleErrorPopUp = (newTitle="UNKNOWN ERROR",newMessage="",newColor="red") => {
    setErrorMessage(newMessage);
    setErrorTitle(newTitle);
    setErrorTextColor(newColor); //you can use colors such as "red" "black" "gren" "white" ..., no caps and no typos please
    toggleErrorMessage();
  }

  const handleFailedToRetrieveUserDetails = () => {
    handleErrorPopUp("Connection Error","Failed to retrieve user details","red");
  };

  const handleCooldownErrorError = (cooldownMessage) => {
    handleErrorPopUp("Spinning Limit Reached",cooldownMessage,"white");
  };

  const handleUnknownFormatError = () => {
    handleErrorPopUp("Parsing Error","Uknown Response Received From The Server","red");
  };

  const handleInvalidDataError = () => {
    handleErrorPopUp("Identification Error","Invalid Data Received From The Server","red");
  };

  const handleFetchError = () => {
    handleErrorPopUp("Connection Error","Error Fetching Data","red");
  };

  const toggleErrorMessage = () => {
    if (showErrorPopUp) {
      resetPopUps();
      onClose();
      setShowErrorPopUp(false);
    } else {
      setShowSpinResult(false);
      setShowConfetti(false);
      setStopDisabled(false);
      resetAudio();
      setShowErrorPopUp(true);
    }
  };

  const getPrizeAngle = (winValue) => {
    const parsedValue = parseFloat(winValue);
    setReward(parsedValue)
  
    const valueIndex = prizes.findIndex(prize => prize === parsedValue);
  
    console.log("index", valueIndex);
    console.log("parsed", parsedValue);
    console.log("stored reward", reward);
    console.log("passed value", winValue);
  
    if (valueIndex >= 0) {
      const angleBetweenNumbers = (360 / prizes.length)
      const angle = angleBetweenNumbers * valueIndex;
      console.log("angle", angle);
      setPrizeAngle(360 - angle + (2*angleBetweenNumbers));//please keep the x2 thing so that the wheel lands in the correct spot
    } else {
      console.log("Default angle set");
      setPrizeAngle(defaultRotationAngle); // Fallback angle
    }
  };

  const spinWheel = () => {
    spinningRef.current = true;
    let startTime;
    let newAngle = rotationAngle;
    setRotationAngle(0);
    audioRef.current.loop = true;
    if (audioRef.current) {
      audioRef.current.play().catch(error => {
        if (error.name === "NotAllowedError" || error.name === "AbortError") {
          console.log("Audio autoplay was prevented. Waiting for user interaction.");
        } else {
          console.error("Audio play failed:", error);
        }
      });
    }
    const animate = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const elapsed = timestamp - startTime;
      newAngle = elapsed * WHEEL_SPEED;
      setRotationAngle(newAngle);
      if (spinningRef.current) {
        animationRef.current = requestAnimationFrame(animate);
      }
    };

    animationRef.current = requestAnimationFrame(animate);
  };

  const stopWheel = () => {
    spinningRef.current = false;
    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
      animationRef.current = null;
    }
    setStopDisabled(true);
    setClaimDisabled(false);
    const angleDifference = targetAngle - (rotationAngle % 360);
    const normalizedAngleDiff = (angleDifference + 360) % 360;
    smoothStop(normalizedAngleDiff);
  };

  const smoothStop = (angleDiff) => {
    let startTime;
    const initialAngle = rotationAngle;

    const animateStop = (timestamp) => {
      if (!startTime) startTime = timestamp;

      const elapsed = timestamp - startTime;
      const progress = Math.min(elapsed / SMOOTH_STOP_DURATION, 1);

      const currentAngle = initialAngle + (angleDiff * progress);
      setRotationAngle(currentAngle);

      if (progress < 1) {
        requestAnimationFrame(animateStop);
      } else {
        setShowConfetti(true);
        resetAudio();
        setTimeout(() => {
          setShowSpinResult(true);
        }, 2000);
      }
    };

    requestAnimationFrame(animateStop);
  };

  const closeCongratsPopup = () => {
    updateBalance();
    onClose();
    setShowSpinResult(false);
    setShowConfetti(false);
    setClaimDisabled(true);
  };

  const resetAudio = () => {
    audioRef.current.loop = false;
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  };

  const resetPopUps = () => {
    cancelAnimationFrame(animationRef.current);
    setClaimDisabled(false);
    animationRef.current = null;
    spinningRef.current = false;
    resetAudio();
    setShowConfetti(false);
    setShowSpinResult(false);
    setShowErrorPopUp(false);
  };

  return (
    <div>
      <div className="popup show">
        <div className="popup-content">
          <img src={prizeindicator} className='indicator-img' alt="prize" />
          <div className='wheel-component'
            style={{ transform: `rotate(${rotationAngle}deg)`, transition: 'transform 0.1s linear' }}>
            <img src={prizeWheel} alt="Spinning Wheel Component" className='wheel-component-img' />
            <div className="wheel-component-values">
              {prizes.map((prize, index) => {
                const angle = (360 / prizes.length) * index;
                return (
                  <div key={index}
                    className="wheel-component-value"
                    style={{
                      transform: `rotate(${angle}deg)`,
                    }}>
                    <span className="wheel-component-number">
                      {prize}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          {!stopDisabled && (
            <button onClick={stopWheel} className="stop-button">
              Stop Spinning
            </button>
          )}
        </div>
      </div>
      {showSpinResult && (
        <div className="popup show">
          <div className="popup-content">
            <h2 className="result-title">Congratulations!</h2>
            <p className="result-hint">{prizeMessage}</p>
            {!claimDisabled && (
              <button onClick={closeCongratsPopup} className="claim-button">
                Claim
              </button>
            )}
          </div>
          {showConfetti && <ReactConfetti className="confetti-effect" />}
        </div>
      )}
      {showErrorPopUp && (
        <div className="popup show">
          <div className="popup-content">
          <div style={modalStyles.svgContainer}>
          <svg width="500" height="" viewBox="0 0 716 173" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M714.86 25.035C713.37 24.945 711.12 24.895 708.42 25.155C704.32 25.555 698.96 26.075 695.78 29.055C692.17 32.435 692.35 38.065 692.4 40.175C692.82 57.935 712.92 172.505 621.36 172.505C529.8 172.505 66.76 172.505 66.76 172.505C66.76 172.505 19.02 168.585 17.06 119.535V40.155C17.04 39.515 16.65 31.155 9.70001 27.015C5.61001 24.585 1.48001 24.935 0.0100098 25.135C0.28001 23.115 1.79 20.885 10.56 13.955C18.52 7.66502 27.21 7.76502 29.48 7.86502H695.26C695.26 7.86502 716.7 0.295017 714.86 25.035Z" fill="#EA8500" />
            <path d="M715.1 24.605C713.61 24.515 711.36 24.465 708.66 24.725C704.56 25.125 699.2 25.645 696.02 28.625C692.41 32.005 692.59 37.635 692.64 39.745C693.06 57.505 712.91 164.665 621.35 164.665C529.79 164.665 66.75 164.665 66.75 164.665C66.75 164.665 19.01 160.745 17.05 111.695V39.755C17.03 39.115 16.64 30.755 9.69 26.615C5.6 24.185 1.47 24.535 0 24.735C0.27 22.715 1.77999 13.055 10.55 6.11502C18.51 -0.174977 27.2 -0.0749806 29.47 0.0250194H695.25C695.26 0.0150194 716.94 -0.144979 715.1 24.605Z" fill="url(#paint0_linear_23_6)" />
            <defs>
              <linearGradient id="paint0_linear_23_6" x1="357.882" y1="-1.17058" x2="356.193" y2="150.842" gradientUnits="userSpaceOnUse">
                <stop offset="1.38522e-07" stop-color="#EBAE27" />
                <stop offset="0.0855" stop-color="#F9B815" />
                <stop offset="0.1814" stop-color="#FBEA28" />
                <stop offset="0.519" stop-color="#FDD132" />
                <stop offset="1" stop-color="#FEC833" />
              </linearGradient>
            </defs>
          </svg>
          <h2 style={modalStyles.title}>{errorTitle}</h2>
        </div>
        <p className="result-hint" style={{ color: errorTextColor }}>{errorMessage}</p>

              <button
                style={modalStyles.logoutButton}
                onClick={toggleErrorMessage}
              >
                Cancel
              </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SpinningWheel;
