export const TermCondition = () => (
  <>
    <meta charSet="UTF-8" />
    <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
    <link
      rel="shortcut icon"
      href="assets/images/fav.png"
      type="image/x-icon"
    />
    <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
    <link rel="stylesheet" href="assets/css/fontawesome.min.css" />
    <link rel="stylesheet" href="assets/css/jquery-ui.css" />
    <link rel="stylesheet" href="assets/css/plugin/nice-select.css" />
    <link rel="stylesheet" href="assets/css/plugin/magnific-popup.css" />
    <link rel="stylesheet" href="assets/css/plugin/slick.css" />
    <link rel="stylesheet" href="assets/css/ara-font.css" />
    <link rel="stylesheet" href="assets/css/plugin/animate.css" />
    <link rel="stylesheet" href="assets/css/style.css" />
    
    {/* Scroll To Top Start*/}
    <a href="javascript:void(0)" className="scrollToTop">
      <i className="fas fa-angle-double-up" />
    </a>
    {/* Scroll To Top End */}
 {/* header-section start */}
 <header className="header-section">
          <div className="overlay">
              <div className="container">
                  <div className="row d-flex header-area">
                      <nav className="navbar navbar-expand-lg navbar-light">
                          <a className="navbar-brand" href="/home">
                              <img
                                  src="assets/images/fav.png"
                                  className="fav d-none d-lg-block d-xl-none"
                                  alt="fav"
                              />
                              <img
                                  src="assets/images/logo.png"
                                  className="logo d-block d-lg-none d-xl-block"
                                  alt="logo"
                              />
                          </a>
                          <button
                              className="navbar-toggler collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#navbar-content"
                          >
                              <i className="fas fa-bars" />
                          </button>
                          <div
                              className="collapse navbar-collapse justify-content-between"
                              id="navbar-content"
                          >
                              <div className="right-area header-action d-flex align-items-center max-un">
                                  <button
                                      type="button"
                                      className="login"
                                      data-bs-toggle="modal"
                                      data-bs-target="#loginMod"
                                  >
                                      Sign In
                                  </button>
                                  <button
                                      type="button"
                                      className="cmn-btn reg"
                                      data-bs-toggle="modal"
                                      data-bs-target="#loginMod"
                                  >
                                      Sign Up
                                  </button>
                              </div>
                          </div>
                      </nav>
                  </div>
              </div>
          </div>
      </header>
      {/* header-section end */}
    {/* Login Registration start */}
    <div className="log-reg">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="modal fade" id="loginMod">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header justify-content-center">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <ul className="nav log-reg-btn d-none justify-content-around">
                    <li className="bottom-area" role="presentation">
                      <button
                        className="nav-link"
                        id="regArea-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#regArea"
                        type="button"
                        role="tab"
                        aria-controls="regArea"
                        aria-selected="false"
                      >
                        SIGN UP
                      </button>
                    </li>
                    <li className="bottom-area" role="presentation">
                      <button
                        className="nav-link active"
                        id="loginArea-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#loginArea"
                        type="button"
                        role="tab"
                        aria-controls="loginArea"
                        aria-selected="true"
                      >
                        LOGIN
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div
                      className="tab-pane fade show active"
                      id="loginArea"
                      role="tabpanel"
                      aria-labelledby="loginArea-tab"
                    >
                      <div className="login-reg-content">
                        <div className="modal-body">
                          <div className="form-area">
                            <form action="#">
                              <div className="section-text text-center">
                                <h3 className="title">Welcome Back</h3>
                                <p>
                                  Not a member ?{" "}
                                  <a
                                    href="javascript:void(0)"
                                    className="reg-btn"
                                  >
                                    Register
                                  </a>
                                </p>
                              </div>
                              <div className="input-area d-flex align-items-center">
                                <img
                                  src="assets/images/icon/email-icon.png"
                                  alt="icon"
                                />
                                <input type="text" placeholder="Email" />
                              </div>
                              <div className="input-area d-flex align-items-center">
                                <img
                                  src="assets/images/icon/security.png"
                                  alt="icon"
                                />
                                <input type="text" placeholder="Password" />
                              </div>
                              <div className="checkbox-item d-flex justify-content-between">
                                <label className="checkbox-single d-flex align-items-center">
                                  <span className="content-area">
                                    <span className="checkbox-area d-flex">
                                      <input type="checkbox" />
                                      <span className="checkmark" />
                                    </span>
                                    <span className="item-title d-flex align-items-center">
                                      <span>Remember me</span>
                                    </span>
                                  </span>
                                </label>
                                <a href="javascript:void(0)">Forget Password</a>
                              </div>
                              <div className="btn-area text-center">
                                <a
                                  href="javascript:void(0)"
                                  className="cmn-btn mt-4"
                                >
                                  Login
                                  <i className="icon-d-right-arrow-2" />
                                </a>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="regArea"
                      role="tabpanel"
                      aria-labelledby="regArea-tab"
                    >
                      <div className="login-reg-content regMode">
                        <div className="modal-body">
                          <div className="form-area">
                            <form action="#">
                              <div className="section-text text-center">
                                <h5 className="sub-title">
                                  Ready to get started?
                                </h5>
                                <h3 className="title">
                                  Welcome Bonus +50% SC!
                                </h3>
                                <p>
                                  Already have an account?{" "}
                                  <a
                                    href="javascript:void(0)"
                                    className="log-btn"
                                  >
                                    Login
                                  </a>
                                </p>
                              </div>
                              <div className="input-area d-flex align-items-center">
                                <img
                                  src="assets/images/icon/email-icon.png"
                                  alt="icon"
                                />
                                <input type="text" placeholder="First Name" />
                              </div>
                              <div className="input-area d-flex align-items-center">
                                <img
                                  src="assets/images/icon/email-icon.png"
                                  alt="icon"
                                />
                                <input type="text" placeholder="Last Name" />
                              </div>
                              <div className="input-area d-flex align-items-center">
                                <img
                                  src="assets/images/icon/email-icon.png"
                                  alt="icon"
                                />
                                <input type="text" placeholder="Phone Number" />
                              </div>
                              <div className="input-area d-flex align-items-center">
                                <img
                                  src="assets/images/icon/email-icon.png"
                                  alt="icon"
                                />
                                <input
                                  type="date"
                                  placeholder="Date of Birth"
                                />
                              </div>
                              <div className="input-area d-flex align-items-center">
                                <img
                                  src="assets/images/icon/email-icon.png"
                                  alt="icon"
                                />
                                <input type="text" placeholder="Email" />
                              </div>
                              <div className="input-area d-flex align-items-center">
                                <img
                                  src="assets/images/icon/security.png"
                                  alt="icon"
                                />
                                <input type="text" placeholder="Password" />
                              </div>
                              <div className="sidebar-single-item">
                                <label className="checkbox-single d-flex">
                                  <span className="content-area">
                                    <span className="checkbox-area d-flex">
                                      <input
                                        type="checkbox"
                                        defaultChecked="checked"
                                      />
                                      <span className="checkmark" />
                                    </span>
                                    <span className="item-title d-flex align-items-center">
                                      <span>
                                        I am at least 18 years of age and I have
                                        read, accepted and agreed to the{" "}
                                        <u>
                                          <a href="javascript:void(0)">
                                            Privacy Policy
                                          </a>
                                          , Bonus T&amp;C,{" "}
                                          <a href="javascript:void(0)">
                                            Terms and Conditions
                                          </a>
                                        </u>
                                      </span>
                                    </span>
                                  </span>
                                </label>
                                <label className="checkbox-single d-flex align-items-center">
                                  <span className="content-area">
                                    <span className="checkbox-area d-flex">
                                      <input type="checkbox" />
                                      <span className="checkmark" />
                                    </span>
                                    <span className="item-title d-flex align-items-center">
                                      <span>
                                        I want to receive promotions by e-mail.
                                      </span>
                                    </span>
                                  </span>
                                </label>
                                <div className="btn-area text-center">
                                  <a
                                    href="javascript:void(0)"
                                    className="cmn-btn mt-4"
                                  >
                                    Get Started Now
                                    <i className="icon-d-right-arrow-2" />
                                  </a>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Login Registration end */}
    {/* Banner Section start */}
    <section className="banner-section inner-banner about-us">
      <div className="overlay">
        <div className="banner-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-10">
                <div className="main-content">
                  <h2>Terms Conditions</h2>
                  <div className="breadcrumb-area">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb d-flex align-items-center">
                        <li className="breadcrumb-item">
                          <a href="index.html">Home</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a href="javascript:void(0)">Pages</a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Terms Conditions
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* Banner Section end */}
    {/* Terms Conditions In start */}
    <section className="privacy-content terms">
      <div className="overlay pt-120 pb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="top-wrapper">
                <h3>TERMS AND CONDITIONS</h3>
                <p>Version 1.0</p>
                <p>Effective as of August, 2024, and supersedes and replaces all prior T&C.</p>
                <p>
                Disclosure: This Website does not offer real money gambling Services. This Website is for 
                entertainment purposes only, and no real money is required to play.
                Also, please be aware that this T&C includes a mandatory arbitration provision as established 
                in Section 3 and 29, and you have a right to opt-out by following the procedure established 
                therein.
                </p>
                <h5>1. General</h5>
                <p>
                The present terms and conditions (“T&C”) are considered an agreement between Tropic 
                Gamers LLC, a limited liability company duly organized under the law of Delaware, United 
                States (“us” or “our” or “we”) and you (“you” and “yours”), and therefore are considered a 
                legally binding contract that will govern your use and interaction with our Website, including 
                the use of our social casino games (“Games”) and related features, benefits, platforms, 
                applications, and any services that we provide, all of which are referred to in these T&C as the 
                (“Services”). The Website and Services are owned and/or operated by us and offered to you for 
                your personal, non-commercial use and entertainment. The Games and the Services are not, 
                are not intended to be, and do not involve real-money gambling. Our Games do not allow the 
                deposit of any real money, and no cash or purchase of anything is required to access the 
                Website or play the Games. Your use of the Website and Services (including but not limited to 
                accessing and using the Games) is subject to the following T&C (including Section 29 
                “Arbitration; Waiver of Class Action,” as well as our Privacy Policy, Promotional Play Rules, 
                and Responsible Social Gameplay Policy (“Integrated Policies”), (all of which are incorporated 
                into these T&C), and all applicable laws.

                </p>
                <h5>
                2. Consent and Important provisions
                </h5>
                <p>
                By using the Website or the Games, you acknowledge that you have read, understood, and 
                consent to the present T&C and agree to abide by its terms.
                Therefore, we encourage you to read these T&C carefully before using our Website, creating an 
                account, using the Games or any of our Services.
                You are not permitted to use the Website or any Service if you do not accept these T&C 
                (including the Integrated Policies).
                </p>
                <p>
                It is important to consider that by using the Website or the Games or by creating an account, 
                or by clicking “I accept”, you also represent, warrant, and accept the following: (i) you have the 
                required legal capacity to be bound by the T&C;
                (iii) you have read and agree with the Integrated Policies, which are expressly integrated into 
                and are a part of these T&C;
                (v) all the information you provide is accurate and reliable.
                </p>
                <h5>3. Arbitration, Class Action Waiver, and Dispute Resolution.</h5>
                <p>
                TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, ANY CLAIM, DISPUTE OR 
                CONTROVERSY OF WHATEVER NATURE (“CLAIM”) ARISING OUT OF OR RELATING TO THESE 
                T&C AND/OR OUR WEBSITE OR GAME OR ANY OTHER SERVICES MUST BE RESOLVED BY FINAL 
                AND BINDING ARBITRATION IN ACCORDANCE WITH THE PROCESS DESCRIBED IN THE 
                DISPUTE RESOLUTION SECTION BELOW. PLEASE READ THE DISPUTE RESOLUTION SECTION 
                29 CAREFULLY. IF YOU DON’T WISH TO BE SUBJECT TO ARBITRATION, YOU MAY OPT-OUT 
                FROM THE ARBITRATION BY FOLLOWING THE INSTRUCTIONS PROVIDED IN SECTION 29. TO 
                THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, YOU ARE GIVING UP THE RIGHT 
                TO LITIGATE (OR PARTICIPATE IN AS A PARTY OR CLASS MEMBER) ALL DISPUTES IN COURT 
                BEFORE A JUDGE OR JURY.
                </p>
                <h5>4. Modification to the T&C</h5>
                <p>
                We reserve the right to change, revise, or otherwise modify these T&C at any time including 
                the Integrated Policies. In that case, we will post any such changes on our Website and we will 
                notify you by email. Whether you receive or review such notifications, you agree to be bound 
                by such changes. It is your responsibility to read and review this T&C and the Integrated 
                Policies, and you also agree that continued use of the Website and the Games means you 
                accept and agree to be bound by the revised T&C and Integrated Policies.
                </p>
                <p>
                In case of conflict between the T&C and the Integrated Policies, the T&C shall control.
                </p>
                <h5>5. Violation of the T&C</h5>
                <p>
                If you violate any of the T&C or if we believe that you have violated this T&C, we may terminate your 
                account with or without notice, limit or restrict your access to the Website and Services, and/or 
                exercise any right and remedy under applicable law. Taking any action against you listed in this 
                paragraph does not limit our ability to take any other action permitted by law or by this T&C or to 
                pursue any other legal or equitable remedies that may be available, including without limitation 
                damages and injunctive relief.

                </p>
                <h5>6. Account Eligibility</h5>
                <p>
                <b>To be eligible to create and hold an account, to use our Website, and use our Games, you must:</b>
                </p>
                <ul>
                  <li>• Be a natural person who is at least 18 years of age, or the minimum legal age required by the 
                  laws of your applicable jurisdiction at the time you access the Website and use our Games.
                  </li>
                  <li>• You do not reside and will not access the Games or Website from the states of Washington, 
                  Montana, Maryland, Pennsylvania, New Jersey, Connecticut, West Virginia, Louisiana, 
                  Rhode Island, Delaware, Nevada, Michigan, Idaho, or any other jurisdiction that prohibits 
                  access to the Website or Games.
                  </li>
                  <li>• Understand and accept that we cannot provide any legal advice, and it is your sole 
                  responsibility to ensure that at all times you comply with the applicable regulations.
                  </li>
                  <li>• Be the owner of the email address submitted for the creation of your account.
                  </li>
                  <li>• Be the owner of the payment methods used on the Website.
                  </li>
                  <li>• Have the power and ability to enter into a contract with us.
                  </li>                   
                  <li>• Be a resident or physically located within a jurisdiction that does not prohibit the Website or 
                  the Games and does not prohibit you from accessing the Website and/or using the Games.
                  </li>
                  <li>• Ensure that all information provided by you is true, complete, and correct.
                  </li>
                  <li>• At all times abide by these T&C.
                  </li>
                </ul>
                <p>
                  You are solely responsible for maintaining the confidentiality
                  of your password and account details, if any, and are fully
                  responsible for any and all activities that occur under your
                  password or account. You agree to (a) immediately notify
                  Tropic Gamers of any unauthorized use of your password or
                  account or any other breach of security, and (b) ensure that
                  you exit from your account at the end of each session when
                  accessing the Platform. Tropic Gamers will not be liable for
                  any loss or damage arising from your failure to comply with
                  this paragraph.
                </p>
                <h5>7. Representations and Warranties</h5>
                <p>
                  Tropic Gamers reserves the right to modify or discontinue,
                  temporarily or permanently, the Platform (or any part thereof)
                  with or without notice. You agree that Tropic Gamers will not
                  be liable to you or to any third party for any modification,
                  suspension or discontinuance of the Platform.
                </p>
                <p>
                <b>By agreeing to these T&C, you affirm and certify that the following statements are accurate:</b>
                </p>
                <ul>
                  <li>
                  • You are a natural person who is at least 18 years of age, or the minimum legal age required 
                  by the laws of the applicable jurisdiction at the time you access the Website or use our 
                  Games, and you are legally allowed to access the Website and use the Games.
                  </li>
                  <li>
                  • You are the sole owner of the email address provided for the creation o
                  </li>
                  <li>
                  •  You do not reside and will not access the Games or Website from the states of IWashington, 
                  Montana, Maryland, Pennsylvania, New Jersey, Connecticut, West Virginia, Louisiana, 
                  Rhode Island, Delaware, Nevada, Michigan, Idaho or any other jurisdiction that prohibits access to the 
                  Website or Games.

                  </li>
                  <li>
                  • You do not reside and will not access the Games or Website from outside the USA.
                  </li>
                  <li>
                  • You are accessing our Services and participating in our Games strictly in your personal 
                  capacity and for recreational and entertainment purposes only.
                  </li>
                  <li>
                  • You are accessing our Services and participating in our Games on your own behalf and not 
                  on behalf of any other person, and no one else has access to your account.

                  </li>
                  <li>
                  • You will be solely responsible for any misuse of the account.
                  </li>
                  <li>
                  •  You are using the Website and Games only for legal purposes.
                  </li>
                  <li>
                  • All information that you provide to us during the term of validity of these T&C is true, 
                  complete, and correct, and you will immediately notify us of any changes to such 
                  information in writing.
                  </li>
                  <li>
                  • You will not purchase Gold Coins from a business or corporate account, but only a personal 
                  account in your own personal name.
                  </li>
                  <li>
                  • You are not, will not, and have no intention to be involved in any fraudulent or unlawful 
                  activity in relation to your or another person’s participation in any of the Games, and you will 
                  not use any electronic-assisted methods or techniques (including but not limited to bots) or 
                  hardware devices for your participation in any of the Games.
                  </li>
                  <li>
                  • You are only making Gold Coins purchases from an authorized payment method that 
                  lawfully belongs to you.
                  </li>
                  <li>
                  • You will not sell, trade, or exchange for value (or attempt to do so) your account, GC / SS
                  coins, or any other merchandise, prize, or other thing of value that may be provided to you 
                  by us.
                  </li>
                  <li>
                  • You will hold only one account on the Website.
                  </li>                    
                </ul>
                <h5>8. User Account</h5>
                <p>
                a) Registration. To register for an account, you must provide a valid email address that you own. 
                You will use your email to access the Games. Please ensure that you secure and retain control of 
                your mobile device, as you are responsible for all activity associated with your account, including 
                any purchase (whether or not authorized by you). We reserve the right, in our sole discretion, with or 
                without notice, to reject, change, suspend, modify, or terminate your account and/or block the 
                email address associated with your account.
                </p>
                <p>
                b) Closing your account. You may close your account at any time by contacting 
                support@ultraspin.vip. Closing your account will result in the forfeiture of any existing balance or 
                prize in your account at the time of closure. By closing your account, you will lose the ability to log 
                into the closed account.
                </p>
                <h5>9. Use of the Account</h5>
                <p>
                You are the holder of the account and are the sole person responsible for complying with these 
                T&C. You are also the sole person entitled to the benefits associated with using the account. You 
                are prohibited from allowing any other person to (i) access your account or (ii) use the Services 
                through your account. Your account is not transferable to any other person and may not be merged 
                with any other account. You must create your account with your legal name, which must match a 
                valid ID.
                </p>
                <p>
                You agree to hold only one account on the Website. Creating multiple accounts per person or using 
                the same payment method, bank account, email, legal name, computer, or mobile device to create 
                or access another account is strictly prohibited. We reserve the right to conduct account audits, 
                and if we determine that one person is holding more than one account or is using the same 
                payment method, email, legal name, computer, or mobile device to access more than one account, 
                then we have the right to block, suspend, or delete the principal and duplicate account or accounts 
                without previous notice. In addition, users with more than one account will be penalized by having 
                any balance, prizes, or earnings on the primary or duplicate accounts voided and may subsequently 
                be denied use of the Website.
                </p>
                <p>
                You are prohibited from creating a new account if you lose access to your primary account, or if 
                your primary account is deactivated or suspended. If you lose access to your account or if you wish 
                to make any changes to your information, you should contact us for assistance in resolving any 
                issues by email at support@ultraspin.vip. 
                </p>
                <p>
                User accounts may be limited to one account per IP address at our sole discretion. 
                </p>
                <h5>10. Compromised Account</h5>
                <p>
                You agree to immediately notify us of any unauthorized use of your account or any other security 
                breach, whether actual, threatened, or suspected, associated with your account or the Services.
                </p>
                <h5>11. Personal Location Data</h5>
                <p>
                As part of providing the Services, we may collect information about your location from the device 
                you are using to access the Services. You may disable the location at any time on your device; 
                however, certain Services may require us to verify the location of the user, and accordingly, your 
                access may be limited or denied if we are unable to verify your location.
                </p>
                <h5>12. Use of Information Collected</h5>
                <p>
                We may use the information you provide us according to the Privacy Policy accessible at https://www.ultraspin.vip/privacy-policy
                </p>
                <h5>13. Verification Checks</h5>
                <p>
                You agree that we are entitled to conduct any identification, credit, and other verification checks 
                that we may reasonably require or that are required of us under applicable laws and regulations, by 
                relevant regulatory authorities, or to otherwise prevent financial crime at any time.
                </p>
                <p>
                Until all required verification checks are completed to our sole and absolute satisfaction, any 
                request to open an account, use the account, or to make a redemption of any benefit will remain 
                pending, and we are entitled to restrict your account in any manner that we may reasonably deem 
                appropriate, including by refusing to open, suspending, or closing your account. 
                </p>
                <p>
                Verification procedures may, for example, include requests for, and our examination of, copies of 
                your (1) valid identification issued by the government (including photo identification) such as a 
                passport or driver’s license matching the user-name that you provided; (2) proof of your address 
                such as a utility bill matching the address registered in your account; and (3) source of funds 
                documentation such as a bank statement or payslip. 
                </p>
                <p>
                Where any identification, credit, or other verification check we require cannot be completed to our 
                satisfaction because you have not provided any document we request from you in the form that we 
                require within 30 days from the date the document was first requested, then we are under no 
                obligation to continue with the verification check and we may, in our sole discretion, refuse to open, 
                close, or otherwise restrict your account in any manner that we may reasonably deem appropriate, 
                and the balance in your account could be forfeited. 
                </p>
                <p>
                We may use third-party service providers to run external identification and other verification checks 
                on all users based on the information provided by you from time to time. 
                </p>
                <p>
                Please note that our verification process for redeeming a prize may take up to 30 days from the time 
                we receive the complete, legible, and accurate information and documents requested from you. 
                </p>
                <h5>14. Services</h5>
                <p>
                We may at any time, with or without prior notice to you, (i) modify, suspend, or terminate your 
                access to the Services (including the Games) without any liability to you and for any reason (or no 
                reason) whatsoever; (ii) interrupt access to the Services at any time and without liability for the 
                purposes of maintenance, repairs, and patching.
                </p>
                <h5>15. Software</h5>
                <p>
                To access the Games and use our Services, you may be required to download certain software and 
                associated program interfaces, license keys, and patches (“Software”) on your device. By 
                downloading the Software, you agree and understand that periodic updates may be necessary and 
                if you do not allow access for the purposes of updates, the Software may no longer be usable, and 
                you may no longer be able to access the Games.
                </p>
                <h5>16. Changes</h5>
                <p>
                We reserve the right to suspend, modify, remove, or add Games or other content to the Services at 
                our sole discretion with immediate effect and without notice to you. We will not be liable to you for 
                any loss suffered as a result of any changes made or for any modification or suspension of or 
                discontinuance of the Services (including any Games), and you understand and agree that you have 
                no claims against us in such regard.
                </p>
                <h5>17. The Games and Features</h5>
                <p>
                <b>a) Description and Nature of the Games. </b><br />
                 Our Games are for individual entertainment purposes only. No purchase or exchange of value is 
                necessary to access the Website and use any of our Games. By registering for an account, you 
                receive a periodic allotment of virtual tokens called Gold Coins, which you may use to play the 
                Games. Each of the Games has its own rules, and it is your responsibility to read and understand 
                such rules before playing. No purchase is necessary to play any of our Games. The Games reward 
                successful play with Gold Coins only, which can only be used to play the Games. Gold Coins may 
                not be exchanged for anything of value and may not be traded, redeemed, or exchanged for 
                anything of value.
                </p>
                <p>
                <b>b) Pay-to-Skip; Purchases. </b><br />
                We offer the opportunity for you to purchase additional Gold Coins for immediate continued play 
                and instant entertainment. By purchasing Gold Coins, you are paying to skip the periodic Gold 
                Coins refill period. Purchasing Gold Coins is strictly voluntary and not required to access the 
                Website or use any of the Games.
                </p>
                <p>
                You acknowledge and agree that we may, from time to time and without notice, appoint one or more 
                payment agents to process or make payments from or to you on our behalf. You agree that our 
                payment agents may store your payment details from time to time to process future purchases. By 
                accepting these T&C, you authorize us and our payment agent to store your payment credentials in 
                compliance with applicable regulations. We will not be liable to you for any loss, damage, or liability 
                resulting from the payment agent’s negligence and/or acts beyond the authority given by us to the 
                payment agent. If one or more purchases are subject to a chargeback request, your account will be 
                suspended, and the amount of the chargeback will be a debt owed by you to us, and you must 
                immediately submit payment for such purchases through an alternative and valid payment method.
                </p>
                <p>
                Also, you agree that your payment method must match the name on your account. If we notice that 
                the name on your account and the name on your payment method differs, we will suspend your 
                account, your purchase will be voided, and any balance on your account will be forfeited. You must 
                promptly notify us if your payment method is canceled, lost, or stolen, or if the security of your 
                payment method has otherwise become compromised.
                </p>
                
                <p>
                <b>c) SS Coins Awards, Distributions, and Promotions.</b><br />
                We may periodically and without charge provide you with Sweep Stars. You may not purchase 
                Sweep Stars in any manner. Sweep Stars are equivalent to sweepstakes entries and are governed by 
                our Promotional Play Rules. You may receive Sweep Stars free of charge when you sign up for an 
                account, as a free bonus when you purchase Gold Coins, by the “Hourly Prize Wheel” present in the 
                lobby, by mail post, by social media, by a special promotion or offer, or by any free method pursuant 
                to the Promotional Play Rules
                </p>
                <p>
                <b>IMPORTANT:</b> Any attempt by you to buy, sell, trade for, or otherwise obtain or distribute Sweep
                Stars other than through our free methods is an express violation of these T&C and may be a 
                violation of applicable law. You acknowledge and agree that it is your responsibility to read the 
                Promotional Play Rules.
                </p>
                <p>
                All promotions, including Sweep Stars special promotions, contests, and offers, are subject to 
                these Terms, the Promotional Play Rules, and any additional terms that may be published at the 
                time of the promotion, contest, or special offer. You accept and confirm that you grant an 
                irrevocable, perpetual, worldwide, non-exclusive, royalty-free license to use in whatever way we 
                see fit, and without further acknowledgment of you as the author, any content you post or publish 
                as part of a promotion, contest, or competition.
                </p>
                <p>
                <b>d) No Refunds.</b><br />
                All Gold Coins purchases are final and non-refundable for any reason whatsoever unless otherwise 
                required by law. By purchasing Gold Coins, you are prepaying for the entertainment value that they 
                provide and understand and agree that they cannot be redeemed for any money or anything else of 
                tangible value. 
                </p>
                <p>
                <b>e) Void Games and Final Decision.</b><br />
                We reserve the full and absolute right to void any Games participation or result if we determine, in 
                our sole discretion, that there was a malfunction, mistake, error, or otherwise improper operation of 
                the Games.
                </p>
                <p>
                <b>f) Prize Redemption.</b><br />
                Any prize redemption feature is strictly limited to the SS Coins (Sweep Stars) and is subject to 
                the specific provisions of the Promotional Play Rules. We reserve the right to set Sweep Stars 
                prize minimums, prize maximums for certain states, and charge processing fees.
                Residents of New York and Florida may not receive any prizes with an aggregate value equal to 
                or in excess of US$5,000.00. Any potential prizes in excess of that amount will automatically 
                be reduced to US$4,999.99.
                </p>
                <p>
                Regardless of jurisdiction, you are limited to a maximum daily prize that does not exceed 
                US$10,000.00, and you are limited to one prize redemption request per 24-hour period. 
                However, the maximum payout you may receive within any rolling 24-hour period is capped at 
                US$500.00. If your prize exceeds US$500.00, any remaining amount will be processed in 
                subsequent payouts, subject to the same US$500.00 limit per rolling 24-hour period.
                </p>
                <p>
                When you choose to redeem a prize, you agree to comply with our verification requirements, 
                including providing accurate identifying information, proof of address, or bank account 
                information, as specified in the Promotional Play Rules. Failure to comply with all applicable 
                requirements will result in your forfeiture of the prize.
                Please be aware that prize redemptions with a value equal to or greater than US$2,000.00 may 
                require longer processing time due to banking, security, and fraud checks and controls and 
                may also be paid in several installments rather than in one lump sum.
                </p>
                <h5>18. Inactive Accounts</h5>
                <p>
                An account shall be deemed inactive if you have not logged in within the last 60 days. All SS Coins 
                from an inactive account shall be forfeited. We are not required to give notice before inactivating 
                your account, but we may choose to give notice at our sole discretion. We reserve the right and 
                discretion to disable access to any inactive account, to close such an account, and to limit the right 
                of the associated user to open any new or additional accounts.
                </p>
                <h5>19. Other Restrictions on Use</h5>
                <p>
                You may not use, copy, reproduce, or redistribute the Services, Website, software, or Games or 
                related or derivative products or services without our express written permission. You may not 
                engage in, or assist others to engage in, conduct that would damage or impair our property 
                including, without limitation:
                </p>
                <ul>
                  <li>
                  (i) copying, distributing, transmitting, displaying, performing, framing, linking, hosting, 
                  caching, reproducing, publishing, licensing, or creating derivative works from any 
                  information, software, products, or services obtained from us;
                  </li>
                  <li>
                  (ii) providing unauthorized means through which others may use Services such as through 
                  server emulators or IP spoofing programs;
                  </li>
                  <li>
                  (iii) taking actions that impose an unreasonable or disproportionately large load on network 
                  infrastructure, or that could damage, disable, overburden, or impair our Services or Games;
                  </li>
                  <li>
                  (iv) interfering with any other party’s use and enjoyment of Services and/or Games; and/or
                  </li>
                  <li>
                  (v) attempting to gain unauthorized access to third party accounts, the Service, Software, or 
                  the Games.
                  </li>
                </ul>
                <h5>20. Other Improper Conduct</h5>
                <p>
                In addition to the above, conduct that would be deemed improper also includes, but is not limited 
                to:
                </p>
                <ul>
                  <li>
                  • Any violation of these rules or these T&C;
                  </li>
                  <li>
                  • Using automated means (including but not limited to scripts and third-party tools) to 
                  interact with our Services or Games in any way;
                  </li>
                  <li>
                  • Using automated means (including but not limited to harvesting bots, robots, parser, 
                  spiders, or screen scrapers) to obtain, collect, or access any information from our Services 
                  or Games or other users;
                  </li>
                  <li>
                  • Obtaining other users’ information and spamming other users;
                  </li>
                  <li>
                  • Interfering in any way with others’ use of the Games;
                  </li>
                  <li>
                  • If your communications or content sent to us, to other users, or submitted to the Website or 
                  any of our social media constitutes harassment or offensive behavior, including (but not 
                  limited to) threatening, derogatory, abusive, or defamatory statements, or racist, sexually 
                  explicit, pornographic, obscene, illegal, or offensive language;
                  </li>
                  <li>
                  • You provide incorrect or misleading information;
                  </li>
                  <li>
                  • You attempt to use your account through a VPN, proxy, or similar service that masks or 
                  manipulates the identification of your real location;
                  </li>
                  <li>
                  • Engaging in any illegal or unlawful conduct;
                  </li>
                  <li>
                  • Abusing or misusing our Website, Services, software, or Games in any way.
                  </li>
                </ul>
                <p>
                We reserve the right to suspend or close your account in any such event, without limiting our ability 
                to seek any remedies available under applicable law, including damages. You acknowledge that any 
                balance in your account at the time of closure will be forfeited. 
                </p>
                <h5>21. No Limitation on Remedies</h5>
                <p>
                Players further acknowledge that the closure of their account or its suspension shall in no way 
                prevent us from pursuing criminal or civil proceedings in connection with their misconduct.
                </p>
                <h5>22. Waiver and Indemnification on Using the Games</h5>                  
                <p>
                By registering for an account, accessing our Website, using our Games, and using our Services, you 
                agree to indemnify, release, and to hold us harmless, our affiliates and agents, as well as the 
                officers, directors, employees, shareholders, attorneys, and any of their representatives 
                (collectively, the “Released Parties”), from any and all liability, claims, or actions of any kind 
                whatsoever, including but not limited to injuries, damages, or losses to persons and property which 
                may be sustained in connection with the use of our Games and Services, as well as any claims 
                based on publicity rights, defamation, or invasion of privacy. We are not responsible for: any 
                incorrect, invalid, or inaccurate entry information; human errors; postal delays/postage due mail; 
                technical malfunctions; failures, including public utility or telephone outages; omissions, 
                interruptions, deletions, or defects of any telephone system or network, computer online systems, 
                data, computer equipment, servers, providers, or software, including without limitation any injury 
                or damage to any entrant’s or any other person’s computer or video equipment relating to or 
                resulting from use of our Games; inability to access our Games, or any related Services; theft, 
                tampering, destruction, or unauthorized access to, or alteration of, entries and/or images of any 
                kind; data that is processed late or incorrectly or is incomplete or lost due to telephone, postal 
                issues, computer or electronic malfunction or traffic congestion on telephone lines or transmission 
                systems, or the Internet, or any service provider’s facilities, or any phone site or website or for any 
                other reason whatsoever; typographical, printing, or other errors, or any combination thereof.
                </p>
                <h5>23. Hacking</h5>
                <p>
                Any attempt by you or any other individual to deliberately damage our services or undermine the 
                legitimate operation of the Games is a violation of criminal and/or civil laws and should such an 
                attempt be made, we reserve the right to seek damages and other remedies from any such person 
                to the fullest extent permitted by law.
                </p>
                <h5>24. Legal and Regulatory Compliance</h5>
                <p>
                <b>a) Compliance With Laws.</b><br />
                It is your sole responsibility to determine whether there are any laws that prohibit or restrict your 
                ability to access the Website, the Services, or use the Games (“Applicable Laws”). Without limiting 
                the foregoing, access to the Website and Games is prohibited to residents and/or people located in 
                the States of Washington, Montana, Maryland, Pennsylvania, New Jersey, Connecticut, West 
                Virginia, Louisiana, Rhode Island, Delaware, Nevada, Michigan, Idaho.. Please see our 
                Promotional Play Rules for additional information.
                </p>
                <p>
                  <b>b) Your Warranties.</b><br />
                  Without limiting any of your other responsibilities and representations made as part of accepting 
                  these T&C, in consideration of accessing and/or using the Services (including the Games), you 
                  represent and warrant that:
                  <ul>
                    <li>
                    • (i) You have the right, capacity, and authority to agree to and to be bound by these T&C, to 
                    register for an account, and to use the Games;
                    </li>
                    <li>
                    • (ii) You will comply with these T&C;
                    </li>
                    <li>
                    • (iii) All of the information that you provide to us is accurate and complete to the best of your 
                    knowledge, and you will promptly notify us in writing of any inaccuracies or 
                    incompleteness; and
                    </li>
                    <li>
                    • (iv) You are located in a jurisdiction (whether state, territory, or country) where it is not 
                    unlawful for you to access the Games or the Services, and you may otherwise use the 
                    Services and use the Games without violating any applicable federal, state, local, or other 
                    law or administrative regulation.
                    </li>
                  </ul>
                </p>
                <p>
                  <b>c) No Illegal Use.</b><br />
                  You agree that you will not engage in, attempt to engage in, or assist others engaging in any illegal or 
                  unlawful conduct related to or utilizing the Services, Software, or the Games, including but not 
                  limited to any conduct in violation of applicable civil or criminal laws.
                </p>
                <p>
                  <b>d) No Warranty of Access.</b><br />
                  Your ability to access the Games or the SS Games is not a representation from us that the Games or 
                  the Sweepstakes Games are lawful in your jurisdiction of residence and that the 
                  Games/Sweepstakes Games comply with all applicable laws.
                </p>
                <h5>25. Indemnification</h5>
                <p>
                You agree that you will, at your own cost and expense, indemnify and hold us and our directors, 
                officers, employees, and agents harmless from and against any and all claims, disputes, liabilities, 
                judgments, settlements, actions, debts or rights of action, losses of whatever kind, and all costs 
                and fees, including reasonable legal and attorney fees, arising out of or relating to:
                </p>
                <ul>
                  <li>
                  (A) your breach of these T&C;
                  </li>
                  <li>
                  (B) any misuse of your account, the Website, software, the Services, and the Games by any person 
                  including yourself;
                  </li>
                  <li>
                  (C) your violation of any applicable laws; and/or
                  </li>
                  <li>
                  (D) your negligence or misconduct that results in actual or potential liability to us.
                  </li>
                </ul>
                <h5>26. Licensing</h5>
                
                <p>
                <b>User License.</b><br />
                Subject to these T&C, we grant you a personal, nonexclusive, non-transferable, non-assignable, 
                non-sublicensable, revocable, limited license to install and run our Software and use the Games 
                and Services on a device owned or controlled by you, solely for the purpose of accessing and using 
                the Services and playing the Games in accordance with these T&C, and solely for so long as your 
                account is open. You acknowledge that you are receiving license rights only. You may not directly or 
                indirectly, or authorize any person or entity to:
                </p>
                <ul>
                  <li>
                  • (i) reverse engineer, decompile, disassemble, re-engineer, or otherwise create or attempt to 
                  create or permit, allow, or assist others to create the source code of the Software, the 
                  Games, or its structural framework;
                  </li>
                  <li>
                  • (ii) create derivative works of the Software or the Games;
                  </li>
                  <li>
                  • (iii) use the Software or the Games in whole or in part for any purpose except as expressly 
                  provided in these T&C;
                  </li>
                  <li>
                  • (iv) disable or circumvent any access control or related device, process, or procedure 
                  established with respect to the Software or the Games;
                  </li>
                  <li>
                  • (v) exploit the Games and Services in any unauthorized way whatsoever, including without 
                  limitation, by trespass or burdening network capacity;
                  </li>
                  <li>
                  • (vi) make the Games and Services available to multiple users by any means, including by 
                  uploading the Games and Services to a file-sharing service or device or other type of 
                  hosting service or website or otherwise making the Games and Services available over a 
                  network where it could be used by multiple devices at the same time;
                  </li>
                  <li>
                  • (vii) copy or reproduce the Software or any of the Games.
                  </li>
                </ul>
                <p>
                You acknowledge that you have access to sufficient information such that you do not need to 
                reverse engineer the Software or Games in any way to permit other products or information to 
                interoperate with the Software. You are responsible for all use of the Software or the Games that is 
                under your possession or control.
                </p>
                <p>
                You acknowledge and agree that your license to use the Games and Services is limited by these 
                T&C, and if you do not agree to or act in contravention of these T&C, your license to use the Games 
                and Services may be immediately terminated.
                </p>
                <h5>27. Intellectual Property</h5>
                <p>
                All Services, the Website, Software, Games, and related materials, all logos, symbols, expansion 
                names and symbols, play symbols, trade dress or “look and feel”, all digital assets and those 
                portions of the Software and Services which are our property as well as all derivative works or 
                modifications of any of the foregoing, and all related and underlying intellectual property (including 
                without limitation patents, trademarks, trade secrets, and copyrights), are our sole and exclusive 
                property. We reserve all rights not expressly granted herein. These T&C confer only the right to use 
                the Services, and they do not convey any rights of ownership in or to the Services. Except as 
                expressly set forth herein, no other right or license is granted hereunder, express or implied or by 
                way of estoppel, to any intellectual property rights, and your use of our Website, Services, Software, 
                or playing the Games does not convey or imply the right to do so in combination with any other 
                information or products.
                </p>
                <p>
                In addition, you acknowledge and agree that you shall have no ownership or other property interest 
                in the user account and all rights in and to the user account shall forever be owned by and inure to 
                the benefit of us.
                </p>
                <h5>28. Disclaimers and Liability Limitations</h5>
                <p>
                  <b>Disclaimer of Liability; No Warranty.</b><br />
                  IN NO EVENT SHALL WE, OUR AFFILIATES AND SERVICE PROVIDERS, OR ANY RESPECTIVE 
                  OFFICERS, DIRECTORS, AGENTS, JOINT VENTURERS, EMPLOYEES, ATTORNEYS, OR 
                  REPRESENTATIVES, BE LIABLE:
                </p>
                <ul>
                  <li>
                  • i) FOR ANY AMOUNT GREATER THAN THE AMOUNT OF MONEY YOU HAVE PAID US IN 
                  CONNECTION WITH YOUR USE OF THE WEBSITE AND GAMES;
                  </li>
                  <li>
                  • ii) FOR ANY LOST PROFITS, DIMINUTION IN VALUE OR BUSINESS OPPORTUNITY, ANY LOSS, 
                  DAMAGE, CORRUPTION OR BREACH OF DATA OR ANY OTHER INTANGIBLE PROPERTY OR 
                  ANY SPECIAL, INCIDENTAL, INDIRECT, INTANGIBLE, OR CONSEQUENTIAL DAMAGES, 
                  WHETHER BASED IN CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE, 
                  ARISING OUT OF OR IN CONNECTION WITH AUTHORIZED OR UNAUTHORIZED USE OF 
                  OUR SERVICES, GAME, OR SOFTWARE OR THESE T&C, EVEN IF AN AUTHORIZED 
                  REPRESENTATIVE OF OURS HAS BEEN ADVISED OF OR KNEW OR SHOULD HAVE KNOWN 
                  OF THE POSSIBILITY OF SUCH DAMAGES, AND
                  </li>
                  <li>
                  • iii) NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER REMEDY OF ITS 
                  ESSENTIAL PURPOSE, EXCEPT TO THE EXTENT OF A FINAL DETERMINATION THAT SUCH 
                  DAMAGES WERE A RESULT OF OUR GROSS NEGLIGENCE, FRAUD, WILLFUL MISCONDUCT 
                  OR INTENTIONAL VIOLATION OF LAW.
                  </li>
                </ul>
                <p>
                OUR WEBSITE, SERVICES, GAMES, AND SOFTWARE ARE PROVIDED ON AN “AS IS” AND “AS 
                AVAILABLE” BASIS WITHOUT ANY REPRESENTATION OR WARRANTY, WHETHER EXPRESS, 
                IMPLIED, OR STATUTORY. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE 
                SPECIFICALLY DISCLAIM ANY EXPRESS OR IMPLIED WARRANTIES OF ANY KIND, INCLUDING 
                WITHOUT LIMITATION, TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND/OR 
                NON-INFRINGEMENT.
                </p>
                <p>
                TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE SPECIFICALLY DISCLAIM ANY 
                EXPRESS OR IMPLIED WARRANTIES OF ANY KIND, INCLUDING WITHOUT LIMITATION, TITLE, 
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND/OR NON-INFRINGEMENT. WE DO 
                NOT MAKE ANY REPRESENTATIONS OR WARRANTIES THAT ACCESS TO OUR SERVICES, GAME, 
                SOFTWARE, OR ANY PART THEREOF, OR ANY OF THE MATERIALS CONTAINED THEREIN, WILL BE 
                CONTINUOUS, UNINTERRUPTED, TIMELY, ERROR OR VIRUS FREE. WE ALSO DO NOT GUARANTEE 
                THAT ANY FANTASY ORDER IN OUR GAME WILL BE EXECUTED, ACCEPTED, RECORDED, OR 
                REMAIN OPEN.
                </p>
                <p>
                EXCEPT FOR THE EXPRESS STATEMENTS SET FORTH IN THESE T&C, YOU HEREBY ACKNOWLEDGE 
                AND AGREE THAT YOU HAVE NOT RELIED UPON ANY OTHER STATEMENT OR UNDERSTANDING, 
                WHETHER WRITTEN OR ORAL, WITH RESPECT TO YOUR USE AND ACCESS OF OUR SERVICES, 
                SOFTWARE, AND GAME. WITHOUT LIMITING THE FOREGOING, YOU HEREBY UNDERSTAND AND 
                AGREE THAT WE WILL NOT BE LIABLE FOR ANY LOSSES OR DAMAGES ARISING OUT OF OR 
                RELATING TO:
                </p>
                <ul>
                  <li>
                  • (a) ANY INACCURACY, DEFECT, OR OMISSION OF CRYPTOCURRENCY PRICE DATA THAT 
                  MAY BE MADE AVAILABLE TO YOU THROUGH OUR SERVICES, GAME, OR SOFTWARE,
                  </li>
                  <li>
                  • (b) ANY ERROR OR DELAY IN THE TRANSMISSION OF SUCH DATA, OR
                  </li>
                  <li>
                  • (c) INTERRUPTION IN ANY SUCH DATA.
                  </li>
                </ul>
                <h5>29. Dispute Resolution</h5>
                <p>
                  <b>General.</b><br />
                  This Section applies to any Dispute except for Disputes relating to the enforcement or validity of our 
                  intellectual property rights, which we may address through litigation or any other appropriate 
                  method without regard to this Section. The term “Dispute” means any dispute, action, or other 
                  controversy between you and us concerning these T&C, the Website, the Services, the Games, or 
                  any other product, service, or information we make available to you, whether in contract, warranty, 
                  tort, statute, regulation, ordinance, or any other legal or equitable basis. “Dispute” will be given the 
                  broadest possible meaning allowable under law.
                </p>
                <p>
                In the event of a Dispute, you or we must give the other a Notice of Dispute, which is a written 
                statement that sets forth the name, address, and contact information of the party giving it, the facts 
                giving rise to the Dispute, any supporting evidence (such as communications or screenshots), and 
                the relief requested. You must send any Notice of Dispute by U.S. Mail to Tropic Gamers LLC, 300 
                Delaware Ave, Ste 210, Wilmington, DE 19801, USA and by email to support@ultraspin.vip.
                </p>
                <p>
                We will send any Notice of Dispute to you by U.S. Mail to your address if we have it, or otherwise to 
                your email address, and you agree to receive any Notices of Dispute either by mail or email. The 
                parties will strive to negotiate and attempt to resolve the dispute for a period of at least sixty (60) 
                days (“Formal Complaint Process”). After the sixty (60) day period, either party may initiate 
                arbitration as provided in this Section. You agree to use the Formal Complaint Process before 
                initiating arbitration or any small claims case.
                </p>
                <p>
                  <b>Arbitration; Waiver of Class Action.</b><br />
                  If we cannot resolve the dispute through the Formal Complaint Process above, you and we agree 
                  that any dispute arising out of or relating to this Agreement and our Privacy Policy, including, 
                  without limitation, federal and state statutory claims, common law claims, and those based in 
                  contract, tort, fraud, misrepresentation, or any other legal theory, shall be resolved through binding 
                  arbitration, on an individual basis (the “Arbitration Agreement”).
                </p>
                <p>
                Subject to applicable jurisdictional requirements, you may elect to pursue your claim in your local 
                small claims court rather than through arbitration so long as your matter remains in small claims 
                court and proceeds only on an individual (non-class and non-representative) basis. Arbitration shall 
                be conducted in accordance with the American Arbitration Association’s rules for arbitration of 
                consumer-related disputes (accessible at Consumer Arbitration Rules).
                </p>
                <p>
                This Arbitration Agreement includes, without limitation, disputes arising out of or related to the 
                interpretation or application of the Arbitration Agreement, including the enforceability, revocability, 
                scope, or validity of the Arbitration Agreement or any portion of the Arbitration Agreement. All such 
                matters shall be decided by an arbitrator and not by a court or judge.
                </p>
                <p>
                  <b>CLASS ACTION WAIVER:</b><br />
                  TO THE EXTENT PERMISSIBLE BY LAW, ALL CLAIMS MUST BE BROUGHT IN A PARTY’S INDIVIDUAL 
                  CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS, COLLECTIVE 
                  ACTION, OR REPRESENTATIVE PROCEEDING (COLLECTIVELY “CLASS ACTION WAIVER”). THE 
                  ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS OR ENGAGE IN ANY 
                  CLASS ARBITRATION. YOU ACKNOWLEDGE THAT, BY AGREEING TO THESE T&C, YOU ARE EACH 
                  WAIVING THE RIGHT TO A TRIAL BY JURY AND THE RIGHT TO PARTICIPATE IN A CLASS ACTION.
                </p>
                <p>
                The arbitration will be conducted by a single, neutral arbitrator and shall take place telephonically, 
                via video conferencing technology, or in a mutually agreeable location, in the English language. The 
                arbitrator may award any relief that a court of competent jurisdiction could award, and the arbitral 
                decision may be enforced in any court. An arbitrator’s decision and judgment thereon will not have 
                a precedential or collateral estoppel effect. At your request or at the order of the arbitrator, hearings 
                may be conducted in person or by telephone or via video conferencing technology, and the 
                arbitrator may provide for submitting and determining motions on briefs, without oral hearings. To 
                the extent permitted by law, the prevailing party in any action or proceeding to enforce this 
                Agreement, any arbitration pursuant to this Agreement, or any small claims action shall be entitled 
                to costs and attorneys’ fees.
                </p>
                <p>
                You have the right to opt out of and not to be bound by the arbitration and class action waiver 
                provisions set forth in these T&C. To exercise this right, you must send written notice of your 
                decision to Tropic Gamers LLC, 300 Delaware Ave, Ste 210, Wilmington, DE 19801, USA, and by 
                email to support@ultraspin.vip. Your notice must include your name, mailing address, and user 
                name associated with your account, and state that you do not wish to resolve disputes with us 
                through arbitration. To be effective, this notice must be postmarked or deposited or received by us 
                (if sent by email) within 30 days of the date on which you first accepted these T&C unless a longer 
                period is required by applicable law; otherwise, you will be bound to arbitrate disputes in 
                accordance with this section. You are responsible for ensuring that we receive your opt-out notice, 
                so you may wish to send it by a means that provides for a delivery receipt. If you opt out of these 
                arbitration provisions, we will not be bound by them with respect to any Disputes with you.
                </p>
                <p>
                Both parties shall maintain the confidential nature of the arbitration proceeding and the arbitration 
                award unless otherwise required by law or judicial decision.
                Each party shall bear its own costs in the arbitration proceeding, including the payment of its own 
                attorney’s fees.<br />
                Both parties agree that any Dispute must be raised within one year of its occurrence, after which 
                such Dispute will be time-barred and prohibited, without regard to any longer period of time which 
                may be provided by any period of limitation established under applicable law.
                </p>
                <h5>30. Entire Agreement</h5>
                <p>
                These T&C are the entire agreement between you and us. There are no other agreements; any prior 
                agreements, arrangements, or understandings have been merged into these T&C, except as 
                otherwise stated herein with respect to the Integrated Policies.
                </p>
                <h5>31. Severability</h5>
                <p>
                To the extent that any part of the T&C (or any of the Integrated Policies) is found to be unenforceable 
                or invalid by a court, the unenforceable or invalid portion shall be severed from the rest of the T&C, 
                and the remaining T&C shall be given their full effect to the maximum extent permitted by law.
                </p>
                <h5>32. Tax</h5>
                <p>
                You are solely responsible for any state, local, or federal taxes which may apply to your use of the 
                Website or Games.
                </p>
                <h5>33. Notices to You by Email</h5>
                <p>
                You agree that we may provide any and all notices to you via email at the email address you provide 
                at the time of account registration, and that all such notices shall be deemed given at the time that 
                they are sent.
                </p>
                <h5>34. No Waiver</h5>
                <p>
                Any failure or delay by us to enforce any provision of these T&C shall not constitute a waiver of any 
                applicable rights or be construed to prevent us from enforcing such T&C in the future.
                </p>
                <h5>35. Assignment</h5>
                <p>
                You agree that these T&C are exclusive to you, and you are not allowed to assign or transfer these 
                T&C without our prior written consent. We reserve the right to assign, transfer, or delegate any of our 
                rights and obligations hereunder to any third party without notice to you.
                </p>
                <h5>36. Governing Law</h5>
                <p>
                The parties agree that these T&C will be governed, interpreted, and constructed in accordance with 
                the laws of the State of Delaware, USA, without regard to its principles of conflicts of law. Subject to 
                the restrictions of provision 29 “Dispute Resolution,” in case of litigation, the parties agree to be 
                subject to the specific jurisdiction of Delaware and waive any right to contest jurisdiction.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* Terms Conditions In end */}
    {/* Footer Area Start */}
    <footer className="footer-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="footer-form">
              <div className="section-area mb-30 text-center">
                <h4 className="title">To Get Exclusive Benefits</h4>
              </div>
              <form action="#">
                <div className="form-group input-area d-flex align-items-center">
                  <input type="text" placeholder="Enter your email address" />
                  <button className="btn-area">
                    <img src="assets/images/icon/send-icon.png" alt="icon" />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="footer-area pt-120">
          <div className="row">
            <div className="col-xl-12">
              <div className="footer-top d-flex align-items-center justify-content-between">
                <a href="/home">
                  <img
                    src="assets/images/logo.png"
                    className="logo"
                    alt="logo"
                  />
                </a>
                <div className="footer-box">
                  <ul className="footer-link d-flex align-items-center gap-4">
                  <li>
                      <a href="/contact-us">Contact</a>
                    </li>
                    <li>
                      <a href="/privacy-policy">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="/termandconditions">Terms of Service</a>
                    </li>
                    <li>
                      <a href="/kyc">KYC/AML</a>
                    </li>
                    <li>
                      <a href="/rsg">Responsible Social Gaming</a>
                    </li>
                    <li>
                      <a href="/faq">FAQ</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row justify-content-between align-items-center">
              <div className="col-lg-7 d-flex justify-content-center justify-content-lg-start order-lg-0 order-1">
                <div className="copyright text-center">
                  <p>© Tropic Gamers 2023 | All rights reserved.</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-5 d-flex justify-content-center justify-content-lg-end">
                <div className="social">
                  <ul className="footer-link gap-2 d-flex align-items-center">
                    <li>
                      <a href="javascript:void(0)">
                        <i className="fb fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">
                        <i className="ins fab fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">
                        <i className="tw fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">
                        <i className="in fab fa-linkedin-in" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    {/* Footer Area End */}
    {/*==================================================================*/}
  </>
)