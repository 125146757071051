import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import EditModal from './AffiliateEditModal'; // Import the EditModal component
import { ROOT_URL } from '../../../utils/variable';

const TableContainer = styled.div`
  width: 100%;
  padding: 20px;
  color: white;
  background-color: #007b8f;
`;

const TableTitle = styled.h2`
  text-align: left;
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #fff;
  font-weight: 500;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9rem;
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 4px;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #024e68;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
  text-align: left;
`;

const Actions = styled.div`
  display: flex;
  gap: 10px;
  cursor: pointer;
`;

const Pagination = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PageButton = styled.button`
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  margin: 0 10px;
  font-size: 1.2rem;

  &:hover {
    color: #ddd;
  }

  &:disabled {
    color: #555;
    cursor: not-allowed;
  }
`;

const PageIndicator = styled.span`
  font-size: 1rem;
  margin: 0 10px;
`;

const LoadingText = styled.p`
  text-align: center;
  color: #fff;
`;

function AffiliateLists() {
  const [affiliates, setAffiliates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedAffiliate, setSelectedAffiliate] = useState(null); 
  const [isModalOpen, setIsModalOpen] = useState(false); 

  const rowsPerPage = 10;

  useEffect(() => {
    fetchAffiliates();
  }, []);
  const fetchAffiliates = async () => {
    try {
      const sessionToken = localStorage.getItem('authToken');
      const headers = {
        'Authorization': `Bearer ${sessionToken}`
      };
      const response = await axios.get(`${ROOT_URL}admin/affiliates`, { headers });
      setAffiliates(response.data.affiliates);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching affiliates:', error);
      setLoading(false);
    }
  };

  const totalPages = Math.ceil(affiliates.length / rowsPerPage);

  const currentAffiliates = affiliates.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleEditClick = (affiliate) => {
    console.log('HANDLE EDIT CLICK:', affiliate);
    setSelectedAffiliate(affiliate); 
    setIsModalOpen(true); 
  };

  const closeModal = async () => {
    fetchAffiliates();
    setIsModalOpen(false); 
  };

  return (
    <TableContainer>
      <TableTitle>AFFILIATES</TableTitle>
      {loading ? (
        <LoadingText>Loading...</LoadingText>
      ) : (
        <Table>
          <thead>
            <tr>
              <TableHeader>Affiliate ID</TableHeader>
              <TableHeader>Name</TableHeader>
              <TableHeader>Email</TableHeader>
              <TableHeader>Referral Count</TableHeader>
              <TableHeader>Commission Percentage</TableHeader>
              <TableHeader>Active</TableHeader>
              <TableHeader>Actions</TableHeader>
            </tr>
          </thead>
          <tbody>
            {currentAffiliates.map((affiliate) => (
              <TableRow key={affiliate.id}>
                <TableCell>{affiliate.affiliate_id}</TableCell>
                <TableCell>{affiliate.name}</TableCell>
                <TableCell>{affiliate.email}</TableCell>
                <TableCell>{affiliate.referral_count}</TableCell>
                <TableCell>{affiliate.commission_percentage}%</TableCell>
                <TableCell>{affiliate.is_active ? 'Yes' : 'No'}</TableCell>
                <TableCell>
                  <Actions>
                    {/* Ensure that the onClick handler is correctly bound */}
                    <span onClick={() => handleEditClick(affiliate)}>EDIT</span>
                  </Actions>
                </TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>
      )}

      {/* Pagination section */}
      <Pagination>
        <PageButton onClick={handlePreviousPage} disabled={currentPage === 1}>
          {'<<'}
        </PageButton>
        <PageIndicator>page {currentPage}</PageIndicator>
        <PageButton onClick={handleNextPage} disabled={currentPage === totalPages}>
          {'>>'}
        </PageButton>
      </Pagination>

      {/* Render the modal if isModalOpen is true */}
      {isModalOpen && (
        <EditModal
          affiliate={selectedAffiliate}
          closeModal={closeModal}
        />
      )}
    </TableContainer>
  );
}

export default AffiliateLists;
