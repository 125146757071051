import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { ROOT_URL } from '../../../utils/variable';

const TableContainer = styled.div`
  width: 100%;
  padding: 20px;
  color: white;
  background-color: #007b8f;
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 4px;
`;

const Pagination = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PageButton = styled.button`
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  margin: 0 10px;
  font-size: 1.2rem;

  &:hover {
    color: #ddd;
  }

  &:disabled {
    color: #555;
    cursor: not-allowed;
  }
`;

const PageIndicator = styled.span`
  font-size: 1rem;
  margin: 0 10px;
`;

const StatsContainer = styled.div`
  margin-top: 20px;
  color: #fff;
`;

const StatsTitle = styled.h3`
  margin-bottom: 10px;
`;

const StatsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9rem;
`;

const StatsRow = styled.tr`
  &:nth-child(even) {
    background-color: #024e68;
  }
`;

const StatsCell = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

const LinkContainer = styled.div`
  margin-top: 20px;
`;

const GenerateLinkButton = styled.button`
  padding: 10px 15px;
  background-color: #28a745;
  color: white;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #218838;
  }
`;

const GeneratedLink = styled.input`
  margin-top: 10px;
  padding: 5px;
  width: 100%;
  border: none;
  border-radius: 4px;
`;

function AffiliateLists() {
  const [generatedLink, setGeneratedLink] = useState('');

  const generateAffiliateLink = async () => {
    try {
      const sessionToken = localStorage.getItem('authToken');
      const headers = {
        'Authorization': `Bearer ${sessionToken}`
      };
      const response = await axios.get(`${ROOT_URL}api/affiliate/generate-link`, { headers });
      
      // Set only the affiliate link string, not the whole response object
      setGeneratedLink(response.data.affiliate_link);
    } catch (error) {
      console.error('Error generating affiliate link:', error);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(generatedLink);
    alert('Link copied to clipboard!');
  };

  return (
    <TableContainer>
      <LinkContainer>
        <GenerateLinkButton onClick={generateAffiliateLink}>
          Generate Affiliate Link
        </GenerateLinkButton>
        {generatedLink && (
          <>
            <GeneratedLink readOnly value={generatedLink} />
            <PageButton onClick={copyToClipboard}>
              Copy Link
            </PageButton>
          </>
        )}
      </LinkContainer>
    </TableContainer>
  );
}

export default AffiliateLists;
