import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ROOT_URL } from '../../../utils/variable';
import '../GameGrid.css';

const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px',
    zIndex: 50,
  },
  modal: {
    backgroundColor: '#690DAC',
    color: 'white',
    padding: '16px',
    borderRadius: window.innerWidth < 768 ? '24px' : '48px',
    border: window.innerWidth < 768 ? '8px solid #F7CEB2' : '12px solid #F7CEB2',
    width: '95%',
    maxWidth: '650px',
    maxHeight: '90vh',
    overflowY: 'auto',
    position: 'relative',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3), 0 0 40px rgba(255, 204, 0, 0.5)',
  },
  bannerContainer: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: window.innerWidth < 768 ? '-12px' : '-20px',
    marginBottom: window.innerWidth < 768 ? '12px' : '20px',
  },
  svgWrapper: {
    position: 'relative',
    width: '100%',
    maxWidth: window.innerWidth < 768 ? '250px' : '350px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  svg: {
    width: '100%',
    height: 'auto',
    maxHeight: window.innerWidth < 768 ? '70px' : '100px',
  },
  title: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: window.innerWidth < 768 ? '20px' : '32px',
    fontWeight: '700',
    color: '#000',
    textShadow: '2px 2px 10px rgba(0, 0, 0, 0.6)',
    whiteSpace: 'nowrap',
    textAlign: 'center',
  },
  closeButton: {
    position: 'absolute',
    top: '12px',
    right: '12px',
    background: 'none',
    border: 'none',
    color: 'white',
    fontSize: window.innerWidth < 768 ? '20px' : '24px',
    cursor: 'pointer',
    zIndex: 10,
    width: '32px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    transition: 'background-color 0.3s',
  },
  inputField: {
    width: '100%',
    maxWidth: window.innerWidth < 768 ? '300px' : '400px',
    padding: window.innerWidth < 768 ? '8px' : '10px',
    borderRadius: '12px',
    color: '#6A0DAD',
    border: '2px solid #0A162C',
    marginBottom: '4px',
    fontSize: window.innerWidth < 768 ? '14px' : '16px',
    fontWeight: '500',
    backgroundColor: '#142D5B',
  },
  selectField: {
    width: '100%',
    maxWidth: window.innerWidth < 768 ? '300px' : '400px',
    padding: window.innerWidth < 768 ? '8px' : '10px',
    borderRadius: '12px',
    color: '#fff',
    backgroundColor: '#142D5B',
    border: '2px solid #0A162C',
    fontSize: window.innerWidth < 768 ? '14px' : '16px',
    fontWeight: '500',
    marginBottom: '4px',
  },
  button: {
    marginTop: '4px',
    padding: window.innerWidth < 768 ? '8px 20px' : '10px 24px',
    border: 'none',
    borderRadius: '18px',
    cursor: 'pointer',
    backgroundColor: '#3A1BD8',
    color: 'white',
    transition: 'background-color 0.3s',
    marginBottom: '12px',
    border: window.innerWidth < 768 ? '4px solid #F7CEB2' : '6px solid #F7CEB2',
    fontSize: window.innerWidth < 768 ? '24px' : '32px',
    fontWeight: '800',
    textShadow: '2px 2px 10px rgba(0, 0, 0, 0.6)',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: window.innerWidth < 768 ? '8px' : '10px',
    width: '100%',
    padding: '0 8px',
  },
  table: {
    width: '100%',
    marginTop: '10px',
    borderCollapse: 'collapse',
    fontSize: window.innerWidth < 768 ? '12px' : '14px',
  },
  tableHeader: {
    backgroundColor: '#5a0a9c',
    textAlign: 'left',
    padding: window.innerWidth < 768 ? '6px' : '8px',
  },
  tableCell: {
    padding: window.innerWidth < 768 ? '6px' : '8px',
    borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
  },
  cancelButton: {
    backgroundColor: 'white',
    color: '#6A0DAD',
    padding: window.innerWidth < 768 ? '4px 8px' : '5px 10px',
    borderRadius: '5px',
    cursor: 'pointer',
    border: 'none',
    fontSize: window.innerWidth < 768 ? '12px' : '14px',
  },
  warningText: {
    color: '#FFFFFF',
    fontWeight: '700',
    textTransform: 'uppercase',
    width: '100%',
    maxWidth: window.innerWidth < 768 ? '300px' : '400px',
    textAlign: 'center',
    fontSize: window.innerWidth < 768 ? '11px' : '14px',
    marginTop: '8px',
  },
  minimumLabel: {
    color: '#FFFFFF',
    alignSelf: 'flex-start',
    paddingLeft: '10%',
    fontWeight: '600',
    fontSize: window.innerWidth < 768 ? '12px' : '14px',
    marginBottom: '8px',
  },
  contentContainer: {
    width: '100%',
    maxWidth: window.innerWidth < 768 ? '400px' : '500px',
    margin: '0 auto',
  },
};

const RedeemModal = ({ isOpen, onClose }) => {
  const [amount, setAmount] = useState('');
  const [ltcAddress, setLtcAddress] = useState('');
  const [pendingRedeems, setPendingRedeems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userId, setUserId] = useState(null);
  const [walletType, setWalletType] = useState('Bitcoin Lightning');

  useEffect(() => {
    const fetchUserId = async () => {
      const sessionToken = localStorage.getItem('authToken');
      try {
        const response = await axios.get(`${ROOT_URL}api/users/me`, {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        });
        setUserId(response.data.id);
      } catch (err) {
        console.error('Failed to fetch user ID', err);
        if (err.response && err.response.status === 401) {
          localStorage.removeItem('authToken');
          window.location.href = '/';
        }
      }
    };

    fetchUserId();
  }, []);

  useEffect(() => {
    const fetchPendingRedeems = async () => {
      if (isOpen && userId) {
        const sessionToken = localStorage.getItem('authToken');
        try {
          const response = await axios.get(`${ROOT_URL}admin/user/${userId}/redeem-history`, {
            headers: {
              Authorization: `Bearer ${sessionToken}`,
            },
          });
          setPendingRedeems(response.data);
        } catch (err) {
          console.error('Failed to fetch pending redeems', err);
        }
      }
    };

    fetchPendingRedeems();
  }, [isOpen, userId]);

  const handleRedeem = async () => {
    if (amount < 75) {
      setError('Minimum redeem amount is 75');
      return;
    }
    setIsLoading(true);
    setError(null);
    const sessionToken = localStorage.getItem('authToken');

    try {
      const response = await axios.post(`${ROOT_URL}api/redeem`, {
        amount: parseFloat(amount),
        ltc_address: ltcAddress,
        wallet_type: walletType,
      }, {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      });
      alert('Redeem request successful');
      onClose();
    } catch (err) {
      setError(err.response ? err.response.data.detail : 'Redeem failed');
    } finally {
      setIsLoading(false);
    }
  };

  const cancelRedeem = async (redeemId) => {
    try {
      const sessionToken = localStorage.getItem('authToken');
      await axios.delete(`${ROOT_URL}api/redeem/${redeemId}/cancel`, {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      });
      alert('Redeem request canceled successfully');
      setPendingRedeems(prev => prev.filter(redeem => redeem.redeemId !== redeemId));
    } catch (err) {
      console.error('Cancel redeem failed', err);
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div style={modalStyles.overlay} onClick={handleOverlayClick}>
      <div style={modalStyles.modal} onClick={e => e.stopPropagation()}>
        <button 
          style={modalStyles.closeButton}
          onClick={onClose}
        >
          ✕
        </button>

        <div style={modalStyles.bannerContainer}>
          <div style={modalStyles.svgWrapper}>
            <svg viewBox="0 0 716 173" fill="none" xmlns="http://www.w3.org/2000/svg" style={modalStyles.svg}>
              <path d="M714.86 25.035C713.37 24.945 711.12 24.895 708.42 25.155C704.32 25.555 698.96 26.075 695.78 29.055C692.17 32.435 692.35 38.065 692.4 40.175C692.82 57.935 712.92 172.505 621.36 172.505C529.8 172.505 66.76 172.505 66.76 172.505C66.76 172.505 19.02 168.585 17.06 119.535V40.155C17.04 39.515 16.65 31.155 9.70001 27.015C5.61001 24.585 1.48001 24.935 0.0100098 25.135C0.28001 23.115 1.79 20.885 10.56 13.955C18.52 7.66502 27.21 7.76502 29.48 7.86502H695.26C695.26 7.86502 716.7 0.295017 714.86 25.035Z" fill="#EA8500"/>
              <path d="M715.1 24.605C713.61 24.515 711.36 24.465 708.66 24.725C704.56 25.125 699.2 25.645 696.02 28.625C692.41 32.005 692.59 37.635 692.64 39.745C693.06 57.505 712.91 164.665 621.35 164.665C529.79 164.665 66.75 164.665 66.75 164.665C66.75 164.665 19.01 160.745 17.05 111.695V39.755C17.03 39.115 16.64 30.755 9.69 26.615C5.6 24.185 1.47 24.535 0 24.735C0.27 22.715 1.77999 13.055 10.55 6.11502C18.51 -0.174977 27.2 -0.0749806 29.47 0.0250194H695.25C695.26 0.0150194 716.94 -0.144979 715.1 24.605Z" fill="url(#paint0_linear_23_6)"/>
              <defs>
                <linearGradient id="paint0_linear_23_6" x1="357.882" y1="-1.17058" x2="356.193" y2="150.842" gradientUnits="userSpaceOnUse">
                  <stop offset="1.38522e-07" stopColor="#EBAE27"/>
                  <stop offset="0.0855" stopColor="#F9B815"/>
                  <stop offset="0.1814" stopColor="#FBEA28"/>
                  <stop offset="0.519" stopColor="#FDD132"/>
                  <stop offset="1" stopColor="#FEC833"/>
                </linearGradient>
              </defs>
            </svg>
            <h2 style={modalStyles.title}>REDEEM BALANCE</h2>
          </div>
        </div>

        <div style={modalStyles.contentContainer}>
          <div style={modalStyles.row}>
            {error && <div style={{ color: 'red', marginBottom: '10px' }}>{error}</div>}
            <input
              style={modalStyles.inputField}
              type="number"
              placeholder="Enter redeem amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              min="75"
            />
            <span style={modalStyles.minimumLabel}>MINIMUM 75</span>
            <select
              value={walletType}
              onChange={(e) => setWalletType(e.target.value)}
              style={modalStyles.selectField}
            >
              <option value="Bitcoin Lightning">Bitcoin Lightning</option>
              <option value="Litecoin LTC">Litecoin LTC</option>
            </select>
            <input
              style={modalStyles.inputField}
              type="text"
              placeholder="payment address"
              value={ltcAddress}
              onChange={(e) => setLtcAddress(e.target.value)}
            />
            <p style={modalStyles.warningText}>
              Please ensure the correct address. We are not responsible for funds sent to the wrong address.
            </p>
          </div>

          <div style={modalStyles.row}>
            <button
              style={modalStyles.button}
              disabled={isLoading}
              onClick={handleRedeem}
            >
              {isLoading ? 'Processing...' : 'SUBMIT'}
            </button>
          </div>

          {pendingRedeems.length > 0 && (
            <div style={{ padding: '0 10px' }}>
              <h3 style={{ fontSize: 'clamp(14px, 3vw, 16px)', margin: '5px 0' }}>Pending Redeems</h3>
              <div style={{ overflowX: 'auto', width: '100%' }}>
                <table style={modalStyles.table}>
                  <thead>
                    <tr>
                      <th style={modalStyles.tableHeader}>DATE</th>
                      <th style={modalStyles.tableHeader}>AMOUNT</th>
                      <th style={modalStyles.tableHeader}>STATUS</th>
                      <th style={modalStyles.tableHeader}>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pendingRedeems.map((redeem) => (
                      <tr key={redeem.redeemId}>
                        <td style={modalStyles.tableCell}>{redeem.date}</td>
                        <td style={modalStyles.tableCell}>{redeem.amount}</td>
                        <td style={modalStyles.tableCell}>{redeem.status}</td>
                        <td style={modalStyles.tableCell}>
                          <button
                            onClick={() => cancelRedeem(redeem.redeemId)}
                            style={modalStyles.cancelButton}
                          >
                            Cancel
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RedeemModal;