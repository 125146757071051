import React from 'react';

const WalletAddressModal = ({ isOpen, onClose, walletLink }) => {
  if (!isOpen) return null;

  return (
    <div style={styles.modalOverlay}>
      <div style={styles.modalContent}>
        <iframe
          src={walletLink}
          style={styles.iframe}
          title="Wallet Address"
          frameBorder="0"
          allowFullScreen
        ></iframe>
        <button onClick={onClose} style={styles.closeButton}>Close</button>
      </div>
    </div>
  );
};

const styles = {
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: '#690DAC',
    color: 'white',
    padding: '0',
    borderRadius: '0',
    border: 'none',
    maxWidth: '100%',
    maxHeight: '100%',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iframe: {
    width: '100vw', 
    height: '100vh', 
    borderRadius: '0',
    border: 'none',
  },
  closeButton: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    padding: '10px 20px',
    backgroundColor: '#F44336',
    border: 'none',
    borderRadius: '50px',
    color: '#fff',
    fontSize: '16px',
    fontWeight: '600',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
};

export default WalletAddressModal;
