import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import HistoryModal from './HistoryModal'; // Make sure to have this file created
import { ROOT_URL } from '../../../utils/variable';
import StatusModal from './StatusModal'; // Import the StatusModal

const TableContainer = styled.div`
  width: 100%;
  padding: 20px;
  color: white;
  background-color: #007b8f;
`;

const TableTitle = styled.h2`
  text-align: left;
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #fff;
  font-weight: 500;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9rem;
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 4px;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #024e68;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
  text-align: left;
`;

const Actions = styled.div`
  display: flex;
  gap: 10px;
  cursor: pointer;
`;

const Pagination = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PageButton = styled.button`
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  margin: 0 10px;
  font-size: 1.2rem;

  &:hover {
    color: #ddd;
  }

  &:disabled {
    color: #555;
    cursor: not-allowed;
  }
`;

const PageIndicator = styled.span`
  font-size: 1rem;
  margin: 0 10px;
`;

const LoadingText = styled.p`
  text-align: center;
  color: #fff;
`;

function RedeemRequests() {
  const [redeems, setRedeems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [history, setHistory] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [selectedRedeemId, setSelectedRedeemId] = useState(null);
  const [currentStatus, setCurrentStatus] = useState('');
  const rowsPerPage = 10;

  useEffect(() => {
    fetchRedeemRequests();
  }, []);

  const fetchRedeemRequests = async () => {
    try {
      const sessionToken = localStorage.getItem('authToken');
      const headers = {
        'Authorization': `Bearer ${sessionToken}`
      };
      const response = await axios.get(`${ROOT_URL}admin/redeem-requests`, { headers });
      
      if (response.data && response.data.redeems) {
        setRedeems(response.data.redeems);
      } else {
        setRedeems([
          {
            userId: '22',
            username: 'Testy123',
            firstName: 'Testy',
            lastName: 'Tester',
            dateTime: '09/24/2024 9:00AM',
            amount: 500,
            ltcAddress: 'werjfoawehog34...',
            status: 'pending',
          },
        ]);
      }
      
      setLoading(false);
    } catch (error) {
      alert(error.response.data.detail);
      setRedeems([
        {
          userId: '22',
          username: 'Testy123',
          firstName: 'Testy',
          lastName: 'Tester',
          dateTime: '09/24/2024 9:00AM',
          amount: 500,
          ltcAddress: 'werjfoawehog34...',
          status: 'pending',
        },
      ]);
      setLoading(false);
    }
  };

  const fetchRedeemHistory = async (userId) => {
    try {
      const sessionToken = localStorage.getItem('authToken');
      const headers = {
        'Authorization': `Bearer ${sessionToken}`
      };
      const response = await axios.get(`${ROOT_URL}admin/user/${userId}/redeem-history`, { headers });
      setHistory(response.data.history || []); // Assuming history is an array in the response
    } catch (error) {
      console.error('Error fetching redeem history:', error);
    }
  };

  const handleViewHistory = (userId) => {
    setSelectedUserId(userId);
    fetchRedeemHistory(userId);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setHistory([]);
  };

  const totalPages = Math.ceil(redeems.length / rowsPerPage);

  const currentRedeems = redeems.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Wallet address copied to clipboard!');
    }).catch(err => {
      console.error('Error copying text: ', err);
    });
  };

  const handleEditStatus = (redeemId, currentStatus) => {
    setSelectedRedeemId(redeemId);
    setCurrentStatus(currentStatus);
    setShowStatusModal(true);
  };


  const handleCloseStatusModal = () => {
    setShowStatusModal(false);
  };

  const handleUpdateStatus = () => {
    fetchRedeemRequests(); // Refresh the redeem requests after updating
  };


  return (
    <TableContainer>
      <TableTitle>Pending Redeems</TableTitle>
      {loading ? (
        <LoadingText>Loading...</LoadingText>
      ) : (
        <Table>
          <thead>
            <tr>
              <TableHeader>UserID</TableHeader>
              <TableHeader>Username</TableHeader>
              <TableHeader>First Name</TableHeader>
              <TableHeader>Last Name</TableHeader>
              <TableHeader>DateTime</TableHeader>
              <TableHeader>Amount</TableHeader>
              <TableHeader>LTC Address</TableHeader>
              <TableHeader>Status</TableHeader>
              <TableHeader>Actions</TableHeader>
            </tr>
          </thead>
          <tbody>
            {currentRedeems.map((redeem, index) => (
              <TableRow key={index}>
                <TableCell>{redeem.userId}</TableCell>
                <TableCell>{redeem.username}</TableCell>
                <TableCell>{redeem.firstName}</TableCell>
                <TableCell>{redeem.lastName}</TableCell>
                <TableCell>{redeem.dateTime}</TableCell>
                <TableCell>{redeem.amount}</TableCell>
                <TableCell>{redeem.ltcAddress}</TableCell>
                <TableCell>{redeem.status}</TableCell>
                <TableCell>
                  <Actions>
                    <button onClick={() => handleViewHistory(redeem.userId)}>View History</button>
                    <button onClick={() => copyToClipboard(redeem.ltcAddress)}>Copy Wallet</button>
                    <button onClick={() => handleEditStatus(redeem.id, redeem.status)}>Edit Status</button>
                  </Actions>
                </TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>
      )}

      {/* Pagination */}
      <Pagination>
        <PageButton onClick={handlePreviousPage} disabled={currentPage === 1}>
          {'<<'}
        </PageButton>
        <PageIndicator>Page {currentPage}</PageIndicator>
        <PageButton onClick={handleNextPage} disabled={currentPage === totalPages}>
          {'>>'}
        </PageButton>
      </Pagination>

      {/* Modal for Redeem History */}
      {showModal && (
        <HistoryModal userId={selectedUserId} history={history} onClose={handleCloseModal} />
      )}
       {/* Modal for Changing Status */}
       {showStatusModal && (
        <StatusModal 
          redeemId={selectedRedeemId} 
          currentStatus={currentStatus} 
          onClose={handleCloseStatusModal} 
          onUpdate={handleUpdateStatus} 
        />
      )}
    </TableContainer>
  );
}

export default RedeemRequests;
