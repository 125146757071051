import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { ROOT_URL } from '../../../utils/variable';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  color: black;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
`;

const CloseButton = styled.button`
  background-color: #007b8f;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 15px;

  &:hover {
    background-color: #005f6b;
  }
`;

const ModalTitle = styled.h2`
  margin: 0 0 15px;
  font-size: 1.5rem;
  color: #007b8f;
  text-align: center;
`;

const StatusSelect = styled.select`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const SubmitButton = styled.button`
  background-color: #007b8f;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;

  &:hover {
    background-color: #005f6b;
  }
`;

function StatusModal({ redeemId, currentStatus, onClose, onUpdate }) {
  const [status, setStatus] = useState(currentStatus);

  const handleSubmit = async () => {
    try {
      const sessionToken = localStorage.getItem('authToken');
      const headers = {
        'Authorization': `Bearer ${sessionToken}`
      };
      await axios.put(`${ROOT_URL}admin/redeem/${redeemId}/status`, null, {
        params: { status },
        headers
      });
      onUpdate(); // Refresh the redeem requests
      onClose(); // Close the modal
    } catch (error) {
      alert('Error updating status: ' + error.response.data.detail);
    }
  };

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalTitle>Update Redeem Status</ModalTitle>
        <CloseButton onClick={onClose}>Close</CloseButton>
        <StatusSelect value={status} onChange={(e) => setStatus(e.target.value)}>
          <option value="pending">Pending</option>
          <option value="approved">Approved</option>
          <option value="rejected">Rejected</option>
        </StatusSelect>
        <SubmitButton onClick={handleSubmit}>Update Status</SubmitButton>
      </ModalContent>
    </ModalOverlay>
  );
}

export default StatusModal;
