import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ROOT_URL } from '../../../utils/variable';


const TransactionHistoryModal = ({ isOpen, onClose }) => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isOpen) {
      fetchTransactions();
    }
  }, [isOpen]);

  const fetchTransactions = async () => {
    const sessionToken = localStorage.getItem('authToken');

    try {
      const response = await axios.get(`${ROOT_URL}api/transactions`, {
        headers: {
          'Authorization': `Bearer ${sessionToken}`,
          'Content-Type': 'application/json',
        },
      });
      console.log('Transaction data:',response.data);
      setTransactions(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching transactions:', error);
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('authToken');
        window.location.href = '/';
      }
      setLoading(false);
    }
  };


  return (
    isOpen && (
      <div style={modalStyles.overlay}>
        <div style={modalStyles.modal}>
          <div style={modalStyles.svgContainer}>
            <svg width="300" height="" viewBox="0 0 716 173" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M714.86 25.035C713.37 24.945 711.12 24.895 708.42 25.155C704.32 25.555 698.96 26.075 695.78 29.055C692.17 32.435 692.35 38.065 692.4 40.175C692.82 57.935 712.92 172.505 621.36 172.505C529.8 172.505 66.76 172.505 66.76 172.505C66.76 172.505 19.02 168.585 17.06 119.535V40.155C17.04 39.515 16.65 31.155 9.70001 27.015C5.61001 24.585 1.48001 24.935 0.0100098 25.135C0.28001 23.115 1.79 20.885 10.56 13.955C18.52 7.66502 27.21 7.76502 29.48 7.86502H695.26C695.26 7.86502 716.7 0.295017 714.86 25.035Z" fill="#EA8500" />
              <path d="M715.1 24.605C713.61 24.515 711.36 24.465 708.66 24.725C704.56 25.125 699.2 25.645 696.02 28.625C692.41 32.005 692.59 37.635 692.64 39.745C693.06 57.505 712.91 164.665 621.35 164.665C529.79 164.665 66.75 164.665 66.75 164.665C66.75 164.665 19.01 160.745 17.05 111.695V39.755C17.03 39.115 16.64 30.755 9.69 26.615C5.6 24.185 1.47 24.535 0 24.735C0.27 22.715 1.77999 13.055 10.55 6.11502C18.51 -0.174977 27.2 -0.0749806 29.47 0.0250194H695.25C695.26 0.0150194 716.94 -0.144979 715.1 24.605Z" fill="url(#paint0_linear_23_6)" />
              <defs>
                <linearGradient id="paint0_linear_23_6" x1="357.882" y1="-1.17058" x2="356.193" y2="150.842" gradientUnits="userSpaceOnUse">
                  <stop offset="1.38522e-07" stop-color="#EBAE27" />
                  <stop offset="0.0855" stop-color="#F9B815" />
                  <stop offset="0.1814" stop-color="#FBEA28" />
                  <stop offset="0.519" stop-color="#FDD132" />
                  <stop offset="1" stop-color="#FEC833" />
                </linearGradient>
              </defs>
            </svg>
            <h2 style={modalStyles.title}>Transaction History</h2>
          </div>
          {transactions.length === 0 ? (
            <p style={modalStyles.mainTitle}>No transactions found</p>
          ) : (
            <ul style={modalStyles.transactionList}>
              {transactions.map((transaction) => (
                <li key={transaction.id} style={modalStyles.transactionItem}>
                  <span>{transaction.transaction_type}</span>
                  <span>{transaction.amount}</span>
                  <span>{transaction.status}</span>
                </li>
              ))}
            </ul>
          )}
          <div style={modalStyles.row}>
            <button style={modalStyles.logoutButton} onClick={onClose}>
              <h2>
                Close
              </h2>
            </button>
          </div>
        </div>
      </div>
    )
  );
};

const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    backgroundColor: '#690DAC',
    color: 'white',
    padding: '20px',
    borderRadius: '48px',
    border: '12px solid #F7CEB2',
    maxWidth: '525px',
    //boxShadow: 'inset 0 0 8px rgba(0, 0, 0, 0.6), inset 0 0 15px rgba(0, 0, 0, 0.3)', // Stacked inset shadows
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3), 0 0 40px rgba(255, 204, 0, 0.5)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: '95%',
  },
  svgContainer: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    transform: 'translateY(-40px)',
    justifyContent: 'center',
    alignItems: 'center'
  },
  transactionList: {
    listStyleType: 'none',
    padding: 0,
    margin: '10px 0',
    height:'550px',
    overflowY:'scroll'
  },
  transactionItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px 0',
    borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
  },
  logoutButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center', // Center text inside button
    padding: '6px 10px',
    backgroundColor: 'red',
    border: '6px solid #F7CEB2',
    borderRadius: '36px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    fontSize: '32px',
    fontWeight: '800',
    color: '#fff',
    textShadow: '2px 2px 10px rgba(0, 0, 0, 0.6)',
    width: '50%'
  },
  title: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '32px',
    fontWeight: '700',
    color: '#000',
    textShadow: '2px 2px 10px rgba(0, 0, 0, 0.6)',
  },
  mainTitle: {
    fontSize: '32px',
    fontWeight: '800',
    color: '#fff',
    textShadow: '2px 2px 10px rgba(0, 0, 0, 0.6)',
    padding: '24px 0px'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center', // Center the logout button
    marginBottom: '10px',
  },
};

export default TransactionHistoryModal;
