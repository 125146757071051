import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import './GameGrid.css';
import SudoAnimation from './../Sudo/SudoAnimation';
import UserImage from '../../images/avatar.png';
import heartFaded from '../../images/heart-faded.png';
import heartColored from '../../images/heart-colored.png';
import { ROOT_URL } from '../../utils/variable';
import GameModal from '../Modals/GameModal';

const BATCH_SIZE = 30;
const SCROLL_THRESHOLD = 300;
const THROTTLE_MS = 150;

const GameThumbnail = React.memo(({ game, onGameClick, isFavorite, onFavoriteClick }) => (
  <div
    className="grid-item visible"
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      console.log('Thumbnail clicked, game data:', game);
      onGameClick(game);
    }}
  >
    <div className="image-wrapper">
      <img
        src={game.media || 'https://via.placeholder.com/250'}
        alt={game.name}
        className="grid-image"
        loading="lazy"
        decoding="async"
        fetchpriority="low"
      />
      <div
        className="heart-icon"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          console.log('Heart clicked for game:', game.json_id);
          onFavoriteClick(game.json_id);
        }}
      >
        <img
          src={isFavorite ? heartColored : heartFaded}
          alt="Favorite"
          className="heart-icon-image"
        />
      </div>
    </div>
  </div>
));

GameThumbnail.displayName = 'GameThumbnail';


const GameGrid = ({ games = [], loading = false, error, hasMore = false, onLoadMore }) => {
  console.log('GameGrid received props:', { 
    gamesLength: games.length, 
    loading, 
    error, 
    hasMore 
  });

  const [displayedGames, setDisplayedGames] = useState([]);
  const [favorites, setFavorites] = useState(() => {
    try {
      return new Set(JSON.parse(localStorage.getItem('favorites')) || []);
    } catch {
      return new Set();
    }
  });
  const [user, setUser] = useState(null);
  const [selectedGame, setSelectedGame] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [gameData, setGameData] = useState({
    status: null,
    message: null,
    error: null,
    game: null,
    balances: null
  });

  const gridRef = useRef(null);
  const scrollInfo = useRef({ isScrolling: false, startX: 0, scrollLeft: 0 });
  const loadingRef = useRef(false);
  const currentGamesRef = useRef([]);

  const isMobileDevice = useMemo(() => {
    if (typeof window === 'undefined') return false;

    const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;
    const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    const isMobileScreen = window.innerWidth <= 768;

    return mobileRegex.test(userAgent) || isMobileScreen;
  }, []);

  const checkScrollPosition = useCallback(() => {
    if (!gridRef.current) return false;
    const container = gridRef.current;
    const scrollPosition = container.scrollLeft;
    const totalWidth = container.scrollWidth;
    const viewportWidth = container.clientWidth;

    return (totalWidth - (scrollPosition + viewportWidth)) < SCROLL_THRESHOLD;
  }, []);

  const loadMoreGames = useCallback(() => {
    if (loadingRef.current || loading) return;

    loadingRef.current = true;
    const currentLength = currentGamesRef.current.length;

    if (currentLength >= games.length) {
      if (hasMore && !loading) {
        onLoadMore();
      }
      loadingRef.current = false;
      return;
    }

    const nextBatch = games.slice(
      currentLength,
      currentLength + BATCH_SIZE
    );

    if (nextBatch.length > 0) {
      currentGamesRef.current = [...currentGamesRef.current, ...nextBatch];
      setDisplayedGames(currentGamesRef.current);
    }

    loadingRef.current = false;
  }, [games, hasMore, loading, onLoadMore]);

  const handleScroll = useCallback(() => {
    if (checkScrollPosition()) {
      loadMoreGames();
    }
  }, [checkScrollPosition, loadMoreGames]);

  const throttledScroll = useMemo(() => {
    let inThrottle;
    return () => {
      if (!inThrottle) {
        handleScroll();
        inThrottle = true;
        setTimeout(() => inThrottle = false, THROTTLE_MS);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    const newFirstGame = games[0]?.json_id;
    const currentFirstGame = currentGamesRef.current[0]?.json_id;

    if (newFirstGame !== currentFirstGame) {
      currentGamesRef.current = [];
      setDisplayedGames([]);
      loadingRef.current = false;

      if (games.length > 0) {
        loadMoreGames();
      }
    }
  }, [games, loadMoreGames]);

  const handleDragStart = useCallback((e) => {
    if (!gridRef.current) return;
    const pageX = e.pageX || e.touches?.[0].pageX;
    scrollInfo.current = {
      isScrolling: true,
      startX: pageX - gridRef.current.offsetLeft,
      scrollLeft: gridRef.current.scrollLeft
    };
    gridRef.current.style.cursor = 'grabbing';
    gridRef.current.style.userSelect = 'none';
  }, []);

  const handleDragMove = useCallback((e) => {
    if (!scrollInfo.current.isScrolling || !gridRef.current) return;
    e.preventDefault();
    const pageX = e.pageX || e.touches?.[0].pageX;
    const x = pageX - gridRef.current.offsetLeft;
    const walk = (x - scrollInfo.current.startX) * 2;
    gridRef.current.scrollLeft = scrollInfo.current.scrollLeft - walk;
  }, []);

  const handleDragEnd = useCallback(() => {
    if (!gridRef.current) return;
    scrollInfo.current.isScrolling = false;
    gridRef.current.style.cursor = 'grab';
    gridRef.current.style.userSelect = '';
    handleScroll();
  }, [handleScroll]);

      // Debug useEffect
      useEffect(() => {
        console.log('State Debug:', {
          displayedGamesCount: displayedGames.length,
          isModalOpen,
          selectedGame: selectedGame?.name,
          gameDataStatus: gameData.status,
          userLoggedIn: !!user
        });
      }, [displayedGames.length, isModalOpen, selectedGame, gameData, user]);

  const handleGameClick = useCallback((game) => {
    console.log('handleGameClick called with game:', game);
    console.log('Current modal state:', { isModalOpen });

    setSelectedGame(game);
    console.log('Selected game set to:', game);

    setIsModalOpen(true);
    console.log('isModalOpen set to true');

    const sessionToken = localStorage.getItem('authToken');
    console.log('Session token exists:', !!sessionToken);

    const fetchBalance = async () => {
      try {
        console.log('Fetching balance...');
        const balanceResponse = await fetch(`${ROOT_URL}api/users/balance`, {
          headers: {
            'Authorization': `Bearer ${sessionToken}`,
            'Accept': 'application/json'
          },
        });

        console.log('Balance response status:', balanceResponse.status);

        if (balanceResponse.ok) {
          const balanceData = await balanceResponse.json();
          console.log('Balance data received:', balanceData);
          setGameData({
            status: null,
            message: null,
            error: null,
            game: game,
            balances: balanceData
          });
        } else {
          throw new Error('Failed to fetch balance');
        }
      } catch (error) {
        console.error('Error in fetchBalance:', error);
        setGameData({
          status: 'error',
          message: 'Failed to fetch balance',
          error: error.message,
          game: game
        });
      }
    };

    fetchBalance();
  }, []);

  const initGameSession = useCallback(async (currency) => {
    console.log('Initializing game session with currency:', currency);
    const sessionToken = localStorage.getItem('authToken');
    if (!sessionToken || !user || !selectedGame) {
      setGameData(prev => ({
        ...prev,
        status: 'error',
        message: 'Please log in to play',
        error: 'Authentication required'
      }));
      return;
    }
 
    setGameData(prev => ({
      ...prev,
      status: 'loading',
      message: 'Initializing game session...',
    }));
 
    try {
      let clientIP = '';
      try {
        const ipResponse = await fetch('https://api.ipify.org?format=json');
        if (ipResponse.ok) {
          const ipData = await ipResponse.json();
          clientIP = ipData.ip;
        }
      } catch (error) {
        console.warn('Could not fetch IP address:', error);
      }
     
      const payload = {
        game_id: selectedGame.json_id,
        player_id: user.id.toString(),
        ip_address: clientIP,
        currency: currency === 'gold_coins' ? 'GOC' : 'SSC',
        mobile: isMobileDevice ? 1 : 0,
        return_url: window.location.origin + '/games',
        deposit_url: window.location.origin + '/deposit',
        extra: '',
        debug: process.env.NODE_ENV === 'development'
      };
 
      console.log('Initializing game with payload:', payload);
 
      const response = await fetch(`${ROOT_URL}game/games/init`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${sessionToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
      });
 
      if (response.ok) {
        const data = await response.json();
        // Updated to match backend response structure
        if (data.status === 200 && data.response.game_url) {
          setGameData(prev => ({
            ...prev,
            status: 'success',
            message: 'Game session initialized!',
            url: data.response.game_url,
            token: data.response.token // Also storing the token if needed
          }));
         
          setTimeout(() => {
            window.location.href = data.response.game_url;
          }, 1000);
        } else {
          throw new Error('Invalid response format from server');
        }
      } else {
        const errorData = await response.json();
        setGameData(prev => ({
          ...prev,
          status: 'error',
          message: errorData.message || 'Failed to initialize game',
          error: errorData.message
        }));
      }
    } catch (error) {
      console.error('Error initializing game:', error);
      setGameData(prev => ({
        ...prev,
        status: 'error',
        message: 'Failed to connect to game server',
        error: error.message
      }));
    }
  }, [user, selectedGame, isMobileDevice]);

  const toggleFavorite = useCallback((gameId) => {
    setFavorites(prev => {
      const newFavorites = new Set(prev);
      if (newFavorites.has(gameId)) {
        newFavorites.delete(gameId);
      } else {
        newFavorites.add(gameId);
      }
      localStorage.setItem('favorites', JSON.stringify([...newFavorites]));
      return newFavorites;
    });
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      const sessionToken = localStorage.getItem('authToken');
      if (!sessionToken) return;

      try {
        const response = await fetch(`${ROOT_URL}api/users/me`, {
          headers: {
            'Authorization': `Bearer ${sessionToken}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setUser(data);
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUser();
  }, []);

  return (
    <div className="main-container">
      <div className="user-info">
        <img src={UserImage} alt="User Avatar" className="user-avatar" />
        <div className="user-text">{user?.username ?? 'Loading...'}</div>
      </div>
      <div className="animation-container">
        <SudoAnimation />
      </div>
      <div
        className="grid-container"
        ref={gridRef}
        onScroll={throttledScroll}
        onMouseDown={handleDragStart}
        onMouseMove={handleDragMove}
        onMouseUp={handleDragEnd}
        onMouseLeave={handleDragEnd}
        onTouchStart={handleDragStart}
        onTouchMove={handleDragMove}
        onTouchEnd={handleDragEnd}
        style={{ cursor: 'grab' }}
      >
        {loading && displayedGames.length === 0 ? (
          <div className="games-message">Loading games...</div>
        ) : displayedGames.length === 0 ? (
          <div className="games-message">No games available</div>
        ) : (
          displayedGames.map((game, index) => (
            <GameThumbnail
              key={`${game.json_id}-${index}`}
              game={game}
              onGameClick={handleGameClick}
              isFavorite={favorites.has(game.json_id)}
              onFavoriteClick={toggleFavorite}
            />
          ))
        )}
      </div>
      {isModalOpen && (
        <GameModal
          onClose={() => {
            setIsModalOpen(false);
            setSelectedGame(null);
            setGameData({
              status: null,
              message: null,
              error: null,
              game: null,
              balances: null
            });
          }}
          gameData={gameData}
          onPlayGoldCoins={() => initGameSession('gold_coins')}
          onEnterSweepstakes={() => initGameSession('sweep_stars')}
          loading={gameData.status === 'loading'}
        />
      )}
    </div>
  );
};

export default React.memo(GameGrid);

