import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './KYCSucess.css'; 
import usmediumLogo from '../images/usmedium.png';
import sudopRobot from '../images/sudop1.png';

const KYCSucess = () => {
    const navigate = useNavigate();
    const [status, setStatus] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [retryCount, setRetryCount] = useState(0);

    useEffect(() => {
        const fetchKYCStatus = async () => {
            const kycSessionToken = localStorage.getItem('kycSessionToken'); // Retrieve the KYC session token

            console.log("Retrieved kycSessionToken:", kycSessionToken);

            if (!kycSessionToken) {
                setError('KYC session token is missing. Please log in again.');
                setLoading(false);
                return;
            }

            try {
                const response = await fetch(`https://api.ultraspin.vip/kyc-check-status`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${kycSessionToken}`, // Use the KYC session token for authorization
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    console.error("Failed to fetch verification status, response status:", response.status);
                    throw new Error('Failed to fetch verification status');
                }

                const data = await response.json();
                console.log("Fetched KYC status data:", data);

                if (data && data.status) {
                    setStatus(data.status);
                    setError(null);
                } else {
                    setError("Unexpected response format. Please try again.");
                }
            } catch (err) {
                console.error("Error fetching KYC status:", err);
                setError("Something went wrong. Please contact support.");
            } finally {
                setLoading(false);
            }
        };

        // Retry fetch every 5 seconds if needed
        const intervalId = setInterval(() => {
            if (retryCount < 10 && !status) {
                setRetryCount((prevCount) => prevCount + 1);
                fetchKYCStatus();
            } else {
                clearInterval(intervalId); // Stop retrying after 10 tries or if status is set
            }
        }, 5000);

        // Initial fetch
        fetchKYCStatus();

        return () => clearInterval(intervalId);
    }, [retryCount, status]);

    const handleGoToHome = () => {
        navigate('/'); 
    };

    if (loading && !status) {
        return <div className="loading-message">Please wait, fetching your KYC verification results...</div>;
    }

    if (error && retryCount >= 10) {
        return <div className="error-message">Error: {error}</div>;
    }

    const renderMessageContent = () => {
        switch (status) {
            case 'approved':
                return (
                    <>
                        <h1>ID Verified Successfully!</h1>
                        <p>Thank you for verifying. Please log in and claim your welcome bonus...</p>
                        <p>Happy Gaming!</p>
                    </>
                );
            case 'declined':
                return (
                    <>
                        <h1>Verification Declined</h1>
                        <p>Unfortunately, we could not verify your ID. Please contact support for further assistance.</p>
                    </>
                );
            case 'resubmission required':
                return (
                    <>
                        <h1>Verification Incomplete</h1>
                        <p>We need additional information to complete your verification. Please contact support for further assistance.</p>
                    </>
                );
            case 'expired/abandoned':
                return (
                    <>
                        <h1>Verification Expired</h1>
                        <p>Your verification session has expired. Please restart the verification process.</p>
                    </>
                );
            default:
                return (
                    <>
                        <h1>Something Went Wrong</h1>
                        <p>Please contact support for more information.</p>
                    </>
                );
        }
    };

    return (
        <div className="email-verified-container">
            <img src={usmediumLogo} alt="USMedium Logo" className="usmedium-logo" />
            <div className="message-box">
                <div className="message-with-icon">
                    <img src={sudopRobot} alt="Sudo Robot" className="sudop-robot" />
                    <div className="message-content">
                        {renderMessageContent()}
                        <button className="go-home-btn" onClick={handleGoToHome}>
                            Go to Homepage
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default KYCSucess;