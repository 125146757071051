import React from 'react';
import styled from 'styled-components';

const SidebarContainer = styled.div`
  width: 250px;
  background-color: #025b6e;
  padding: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  height: 90vh;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  margin-bottom: 30px;
  color:#007b8f;
`;

const MenuItem = styled.div`
  margin: 15px 0;
  cursor: pointer;
  font-size:18px;
  font-weight:500px;
  margin-left:18px;
  &:hover {
    color: #ddd;
  }
`;

function Sidebar({ onMenuClick }) {
  const menuItems = [
    'Affiliates',
    'Generate Affiliate Link',
  ];

  return (
    <SidebarContainer>
      <Logo>
        Menu
      </Logo>
      {menuItems.map((item) => (
        <MenuItem key={item} onClick={() => onMenuClick(item)}>
          {item}
        </MenuItem>
      ))}
    </SidebarContainer>
  );
}

export default Sidebar;
