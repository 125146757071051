import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { ROOT_URL } from '../../../utils/variable';

const TableContainer = styled.div`
  width: 100%;
  padding: 20px;
  color: white;
  background-color: #007b8f;
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 4px;
`;


const Pagination = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PageButton = styled.button`
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  margin: 0 10px;
  font-size: 1.2rem;

  &:hover {
    color: #ddd;
  }

  &:disabled {
    color: #555;
    cursor: not-allowed;
  }
`;

const PageIndicator = styled.span`
  font-size: 1rem;
  margin: 0 10px;
`;

const LoadingText = styled.p`
  text-align: center;
  color: #fff;
`;

const StatsContainer = styled.div`
  margin-top: 20px;
  color: #fff;
`;

const StatsTitle = styled.h3`
  margin-bottom: 10px;
`;

const StatsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9rem;
`;

const StatsRow = styled.tr`
  &:nth-child(even) {
    background-color: #024e68;
  }
`;

const StatsCell = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

function AffiliateLists() {
  const [affiliates, setAffiliates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const rowsPerPage = 10;

  useEffect(() => {
    fetchAffiliates();
  }, []);

  const fetchAffiliates = async () => {
    try {
      const sessionToken = localStorage.getItem('authToken');
      const headers = {
        'Authorization': `Bearer ${sessionToken}`
      };
      const response = await axios.get(`${ROOT_URL}admin/affiliates`, { headers });
      setAffiliates(response.data.affiliates);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching affiliates:', error);
      setLoading(false);
    }
  };

  const totalPages = Math.ceil(affiliates.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <TableContainer>
 {/* Hardcoded Stats Section */}
      <StatsContainer>
        <StatsTitle>Affiliate Statistics</StatsTitle>
        <StatsTable>
          <thead>
            <tr>
              <TableHeader>Total Players</TableHeader>
              <TableHeader>Total GGR (Month to Date)</TableHeader>
              <TableHeader>Commission %</TableHeader>
              <TableHeader>Estimated Commission</TableHeader>
            </tr>
          </thead>
          <tbody>
          {affiliates?.map((stat, index) => (
              <StatsRow key={index}>
                <StatsCell>{stat.players}</StatsCell>
                <StatsCell>{stat.ggr}</StatsCell>
                <StatsCell>{stat.commissionPercentage}</StatsCell>
                <StatsCell>{stat.estimatedCommission}</StatsCell>
              </StatsRow>
            ))}
          </tbody>
        </StatsTable>
        
      </StatsContainer>
      <Pagination>
        <PageButton onClick={handlePreviousPage} disabled={currentPage === 1}>
          {'<<'}
        </PageButton>
        <PageIndicator>page {currentPage}</PageIndicator>
        <PageButton onClick={handleNextPage} disabled={currentPage === totalPages}>
          {'>>'}
        </PageButton>
      </Pagination>
    </TableContainer>
  );
}

export default AffiliateLists;
