import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import topbarImage from '../../images/topbar.png';
import Settings from '../Modals/Settings';
import PolicyModal from '../Modals/PolicyModal';
import lobbyMusic from '../../images/lobbymusic.mp3';
import { ROOT_URL } from '../../utils/variable';
import DropdownMenu from './HomeDropdown'; 
import ProfileUpdateModal from './ProfileUpdateModal';
import TransactionHistoryModal from './TransactionHistoryModal';
import UserRedeemModal from './UserRedeemModal';
import UserGameHistory from './UserGameHistory';
import CoinPackagesModal from './CoinPackageModal';

const Topbar = forwardRef((props, ref) => {
  // Store balances in pennies internally
  const [goldCoins, setGoldCoins] = useState(0);
  const [sweepsStars, setSweepsStars] = useState(0);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isPolicyOpen, setIsPolicyOpen] = useState(false);
  const [volume, setVolume] = useState(0.5);
  const [audio] = useState(new Audio(lobbyMusic));
  const [isMuted, setIsMuted] = useState(false);
  const [isCoinPackagesOpen, setIsCoinPackagesOpen] = useState(false); 
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); 
  const [isModalOpen, setModalOpen] = useState(false);
  const [isuserRedeemModalOpen, setuserRedeemModalOpen] = useState(false);
  const [isTransactionModalOpen, setTransactionModalOpen] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 2500);
  const [isUserGameHistory, setUserGameHistory] = useState(false);

  // Updated to handle penny values
  useImperativeHandle(ref, () => ({
    updateBalance(newBalance) {
      if (newBalance) {
        console.log('Received balance update (in pennies):', newBalance);
        setSweepsStars((prevSweepsStars) => prevSweepsStars + parseInt(newBalance));
      }
    }
  }));

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const sessionToken = localStorage.getItem('authToken');
        if (sessionToken) {
          const response = await fetch(`${ROOT_URL}api/users/balance`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${sessionToken}`,
              'Content-Type': 'application/json',
            },
          });
          if (response.ok) {
            const data = await response.json();
            setGoldCoins(parseInt(data.gold_coins));
            setSweepsStars(parseInt(data.sweeps_stars));
          } else {
            console.error('Failed to retrieve balance');
          }
        }
      } catch (error) {
        console.error('Error fetching balance:', error);
        if (error.response && error.response.status === 401) {
            alert('Please login again');
            localStorage.removeItem('authToken');
            window.location.href = '/';
        }
      }
    };
    fetchBalance();
  }, []);

  //(convert from pennies to dollars)
  const formatBalance = (pennies, showDecimals = true) => {
    const dollars = pennies / 100;
    return showDecimals ? dollars.toFixed(2) : Math.floor(dollars);
  };

  useEffect(() => {
    const handlePlay = () => {
      audio.volume = volume;
      audio.play().catch((error) => console.log('Playback prevented:', error));
    };
    audio.loop = true;
    window.addEventListener('click', handlePlay, { once: true });

    return () => {
      window.removeEventListener('click', handlePlay);
    };
  }, [audio, volume]);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 2500);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleToggleMute = () => {
    setIsMuted(!isMuted);
    audio.muted = !audio.muted;
  };

  const handleVolumeChange = (newVolume) => {
    setVolume(newVolume);
    audio.volume = newVolume;
  };

  const handleToggleFullscreen = async () => {
    const elem = document.documentElement;
    if (document.fullscreenElement) {
      await document.exitFullscreen();
    } else {
      if (elem.requestFullscreen) {
        await elem.requestFullscreen();
      }
    }
  };

  const handlePayment = () => {
    setIsCoinPackagesOpen(true); 
  };

  const handleHomeClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleDropdownItemClick = (item) => {
    setIsDropdownOpen(false);
    if(item === 'Buy Coins'){
      setIsCoinPackagesOpen(true);
    }
    if(item === 'Redeem'){
      setuserRedeemModalOpen(true);
    }
    else if(item === 'Update Profile'){
      setModalOpen(true);
    }
    else if(item === 'Transactions'){
      setTransactionModalOpen(true);
    }
    else if(item === 'Game history'){
      setUserGameHistory(true);
    }
  };

  const [isLandscape, setIsLandscape] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const checkOrientation = () => {
    const landscape = window.matchMedia("(orientation: landscape)").matches;
    setIsLandscape(landscape);
    setIsMobile(window.innerWidth < 968);
  };

  useEffect(() => {
    checkOrientation();
    window.addEventListener('resize', checkOrientation);
    window.addEventListener('orientationchange', checkOrientation);
    return () => {
      window.removeEventListener('resize', checkOrientation);
      window.removeEventListener('orientationchange', checkOrientation);
    };
  }, []);

  const leftTextStyles = {
    ...styles.leftText,
    top: (isMobile && isLandscape) ? '10px' : (isLargeScreen ? '35px' : '1.5vw'), 
    fontSize: isLargeScreen ? '50px' : '2vw',
    left: (isMobile && isLandscape) ? '14.5%' : (isLargeScreen ? '400px' : '16vw'),
  };

  const rightTextStyles = {
    ...styles.rightText,
    top: (isMobile && isLandscape) ? '10px' : (isLargeScreen ? '35px' : '1.5vw'), 
    fontSize: isLargeScreen ? '50px' : '2vw',
    right: (isMobile && isLandscape) ? '17.5%' : (isLargeScreen ? '420px' : '17.5vw'),
  };

  return (
    <div style={styles.container}>
      <img src={topbarImage} alt="Topbar" style={styles.image} />
      <div style={leftTextStyles}>{formatBalance(goldCoins, false)} GC</div> {/* No decimals for goldCoins */}
      <div style={rightTextStyles}>{formatBalance(sweepsStars)} SS</div> {/* With decimals for sweepsStars */}
  
      <div style={styles.leftClickableArea} onClick={handleHomeClick} />
      <div style={styles.rightClickableArea} onClick={() => setIsSettingsOpen(true)} />
      <div style={styles.centerClickableArea} onClick={handlePayment} />
      <div style={styles.bottomClickableArea} onClick={() => setIsPolicyOpen(true)} /> 
  
      <Settings
        isOpen={isSettingsOpen}
        onClose={() => setIsSettingsOpen(false)}
        volume={volume}
        onVolumeChange={handleVolumeChange}
        onToggleMute={handleToggleMute}
        isMuted={isMuted}
        onToggleFullscreen={handleToggleFullscreen}
      />
  
      <PolicyModal 
        isOpen={isPolicyOpen} 
        onClose={() => setIsPolicyOpen(false)} 
      />
  
      <DropdownMenu 
        isOpen={isDropdownOpen} 
        onItemClick={handleDropdownItemClick} 
      />
  
      <ProfileUpdateModal 
        isOpen={isModalOpen} 
        onClose={() => setModalOpen(false)} 
      />
      <CoinPackagesModal
        isOpen={isCoinPackagesOpen}
        onClose={() => setIsCoinPackagesOpen(false)}
      />
      <TransactionHistoryModal 
        isOpen={isTransactionModalOpen} 
        onClose={() => setTransactionModalOpen(false)} 
      />
      <UserRedeemModal 
        isOpen={isuserRedeemModalOpen} 
        onClose={() => setuserRedeemModalOpen(false)} 
      />
      <UserGameHistory 
        isOpen={isUserGameHistory} 
        onClose={() => setUserGameHistory(false)} 
      />
    </div>
  );
});

const styles = {
  container: {
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 3,
    textAlign: 'center',
  },
  image: {
    width: '100%',
    height: 'auto',
  },
  leftText: {
    position: 'absolute',
    top: '2%', 
    color: 'white',
    fontFamily: 'Roboto, sans-serif',  
    fontWeight: 700, 
  },
  rightText: {
    position: 'absolute',
    top: '2%', 
    color: 'white',
    fontFamily: 'Roboto, sans-serif',  
    fontWeight: 700, 
  },
  leftClickableArea: {
    position: 'absolute',
    left: '1%',
    top: '10%',
    width: '5%', 
    height: '70%', 
    cursor: 'pointer',
  },
  rightClickableArea: {
    position: 'absolute',
    right: '1%',
    top: '10%',
    width: '5%', 
    height: '75%', 
    cursor: 'pointer',
  },
  centerClickableArea: {
    position: 'absolute',
    left: '29%',
    top: '50%',
    width: '2%', 
    height: '50%', 
    transform: 'translate(-50%, -50%)',
    cursor: 'pointer',
  },
  bottomClickableArea: {
    position: 'absolute',
    right: '6%',
    top: '15%',
    width: '5%', 
    height: '50%', 
    transform: 'translate(-50%, 0)',
    cursor: 'pointer',
  },
};

export default Topbar;