import React, { useEffect, useRef } from "react";
import sudoData from "../../images/sudo.json";
import sudoSprite from "../../images/sudo.png";
import RobotStandImage from "../../images/stand.png"; // Import the blue platform image
import styles from "./SudoAnimation.module.css";

// Function to draw each frame on the canvas
function drawSudoFrame(ctx, frameIndex, standImg) {
  const frame = sudoData.frames[frameIndex].frame; // Extract frame from JSON
  const img = new Image();
  img.src = sudoSprite; // Load sprite sheet

  img.onload = () => {
    // Clear previous frame
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    // Calculate the scale factor based on the canvas size
    const scaleFactor = 0.9; // Adjust this value to scale down the images

    // Draw the blue platform at the bottom
    const standWidth = ctx.canvas.width * scaleFactor;
    const standHeight = standImg.height * scaleFactor;
    ctx.drawImage(
      standImg,
      (ctx.canvas.width - standWidth) / 2,
      ctx.canvas.height - standHeight,
      standWidth,
      standHeight
    );

    // Calculate the position to place the Sudo sprite on top of the platform
    const sudoWidth = ctx.canvas.width * scaleFactor;
    const sudoHeight = (sudoWidth / frame.w) * frame.h;
    const offsetY = 50; // Adjust this value to move the sprite down
    const offsetX = 10; // Adjust this value to move the sprite to the right
    const sudoY = ctx.canvas.height - standHeight - sudoHeight + offsetY;

    // Draw the new frame on the canvas
    ctx.drawImage(
      img,
      frame.x,
      frame.y,
      frame.w,
      frame.h, // Source frame coordinates
      (ctx.canvas.width - sudoWidth) / 2 + offsetX,
      sudoY,
      sudoWidth,
      sudoHeight // Destination on canvas
    );
  };
}

const SudoAnimation = () => {
  const canvasRef = useRef(null);
  let frameIndex = 0;

  useEffect(() => {
    const ctx = canvasRef.current.getContext("2d");
    const standImg = new Image();
    standImg.src = RobotStandImage;

    // Function to update the sprite frame
    const animateSudo = () => {
      drawSudoFrame(ctx, frameIndex, standImg);
      frameIndex = (frameIndex + 1) % sudoData.frames.length; // Loop through frames
    };

    const interval = setInterval(animateSudo, 100); // Adjust animation speed (100ms)

    return () => clearInterval(interval); // Clean up interval on unmount
  }, []);

  return (
    <div className={styles.sudoAnimationContainer}>
      <canvas
        ref={canvasRef}
        width={325} // Set canvas width to 325px
        height={625} // Set canvas height to 625px
        className={styles.sudoAnimationCanvas}
      />
    </div>
  );
};

export default SudoAnimation;
