import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { ROOT_URL } from '../../../utils/variable';

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: #024e68;
  padding: 20px;
  width: 400px;
  border-radius: 8px;
  color: white;
`;

const ModalHeader = styled.h2`
  margin-bottom: 20px;
  color:#fff;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

const ModalInput = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const Button = styled.button`
  padding: 8px 16px;
  background-color: #007b8f;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: #005f6b;
  }
`;

const CancelButton = styled(Button)`
  background-color: #555;

  &:hover {
    background-color: #333;
  }
`;

const EditUserModal = ({ user, closeModal, refreshUsers }) => {
  const [formData, setFormData] = useState({
    gold_coins: user.gold_coins,
    sweeps_stars: user.sweeps_stars,
    new_password: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUpdateBalance = async () => {
    try {
      const sessionToken = localStorage.getItem('authToken');
      const headers = {
        'Authorization': `Bearer ${sessionToken}`,
        'Content-Type': 'application/json',
      };

      await axios.put(
        `${ROOT_URL}admin/user/${user.id}/balance?gold_coins=${formData.gold_coins}&sweeps_stars=${formData.sweeps_stars}`,
        {},
        { headers }
      );

      refreshUsers();
      closeModal();
    } catch (error) {
      console.error('Error updating user balance:', error);
    }
  };

  const handleUpdatePassword = async () => {
    try {
      const sessionToken = localStorage.getItem('authToken');
      const headers = {
        'Authorization': `Bearer ${sessionToken}`,
        'Content-Type': 'application/json',
      };

      await axios.put(
        `${ROOT_URL}admin/user/${user.id}/password?new_password=${formData.new_password}`,
        {},
        { headers }
      );

      setFormData((prevState) => ({
        ...prevState,
        new_password: '',
      }));

      refreshUsers();
      closeModal();
    } catch (error) {
      console.error('Error updating user password:', error);
    }
  };

  return (
    <ModalBackground>
      <ModalContent>
        <ModalHeader>Edit User</ModalHeader>

        <FormGroup>
          <Label htmlFor="gold_coins">Gold Coins</Label>
          <ModalInput
            type="number"
            id="gold_coins"
            name="gold_coins"
            value={formData.gold_coins}
            onChange={handleInputChange}
            placeholder="Enter Gold Coins"
          />
        </FormGroup>

        <FormGroup>
          <Label htmlFor="sweeps_stars">Sweeps Stars</Label>
          <ModalInput
            type="number"
            id="sweeps_stars"
            name="sweeps_stars"
            value={formData.sweeps_stars}
            onChange={handleInputChange}
            placeholder="Enter Sweeps Stars"
          />
        </FormGroup>

        <FormGroup>
          <Label htmlFor="new_password">New Password</Label>
          <ModalInput
            type="password"
            id="new_password"
            name="new_password"
            value={formData.new_password}
            onChange={handleInputChange}
            placeholder="Enter New Password (leave blank to keep unchanged)"
          />
        </FormGroup>

        <ModalActions>
          <CancelButton onClick={closeModal}>Cancel</CancelButton>
          <Button onClick={handleUpdateBalance}>Update Balance</Button>
          <Button onClick={handleUpdatePassword}>Update Password</Button>
        </ModalActions>
      </ModalContent>
    </ModalBackground>
  );
};

export default EditUserModal;
