import React, { useState } from 'react';
import axios from 'axios';
import fullscreenIcon from '../../images/asset3.png';
import muteIcon from '../../images/mute.png';
import volumeIcon from '../../images/asset4.png';
import logoutIcon from '../../images/asset6.png';
import { ROOT_URL } from '../../utils/variable';

const Settings = ({ isOpen, onClose, volume, onVolumeChange, onToggleMute, isMuted, onToggleFullscreen }) => {
  const [loading, setLoading] = useState(false);

  if (!isOpen) return null;

  const handleLogout = async () => {
    setLoading(true);
    const sessionToken = localStorage.getItem('authToken');

    try {
      await axios.post(`https://api.ultraspin.vip/api/logout`, {}, {
        headers: {
          'Authorization': `Bearer ${sessionToken}`,
          'Content-Type': 'application/json',
        },
      });
      localStorage.removeItem('authToken'); // Clear the token on logout
      localStorage.removeItem('user_id');
      alert('Logged out successfully!');
      onClose(); // Close the modal after logout
      window.location.href = '/'; 
    } catch (error) {
      console.error('Error logging out:', error);
      if (error.response && error.response.status === 401) {
        alert('Please login again');
        localStorage.removeItem('authToken');
        window.location.href = '/';
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={modalStyles.overlay} onClick={onClose}>
      <div style={modalStyles.modal} onClick={(e) => e.stopPropagation()}>
        <div style={modalStyles.svgContainer}>
          <svg width="300" height="" viewBox="0 0 716 173" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M714.86 25.035C713.37 24.945 711.12 24.895 708.42 25.155C704.32 25.555 698.96 26.075 695.78 29.055C692.17 32.435 692.35 38.065 692.4 40.175C692.82 57.935 712.92 172.505 621.36 172.505C529.8 172.505 66.76 172.505 66.76 172.505C66.76 172.505 19.02 168.585 17.06 119.535V40.155C17.04 39.515 16.65 31.155 9.70001 27.015C5.61001 24.585 1.48001 24.935 0.0100098 25.135C0.28001 23.115 1.79 20.885 10.56 13.955C18.52 7.66502 27.21 7.76502 29.48 7.86502H695.26C695.26 7.86502 716.7 0.295017 714.86 25.035Z" fill="#EA8500"/>
            <path d="M715.1 24.605C713.61 24.515 711.36 24.465 708.66 24.725C704.56 25.125 699.2 25.645 696.02 28.625C692.41 32.005 692.59 37.635 692.64 39.745C693.06 57.505 712.91 164.665 621.35 164.665C529.79 164.665 66.75 164.665 66.75 164.665C66.75 164.665 19.01 160.745 17.05 111.695V39.755C17.03 39.115 16.64 30.755 9.69 26.615C5.6 24.185 1.47 24.535 0 24.735C0.27 22.715 1.77999 13.055 10.55 6.11502C18.51 -0.174977 27.2 -0.0749806 29.47 0.0250194H695.25C695.26 0.0150194 716.94 -0.144979 715.1 24.605Z" fill="url(#paint0_linear_23_6)"/>
            <defs>
              <linearGradient id="paint0_linear_23_6" x1="357.882" y1="-1.17058" x2="356.193" y2="150.842" gradientUnits="userSpaceOnUse">
                <stop offset="1.38522e-07" stop-color="#EBAE27"/>
                <stop offset="0.0855" stop-color="#F9B815"/>
                <stop offset="0.1814" stop-color="#FBEA28"/>
                <stop offset="0.519" stop-color="#FDD132"/>
                <stop offset="1" stop-color="#FEC833"/>
              </linearGradient>
            </defs>
          </svg>
          <h2 style={modalStyles.title}>SETTINGS</h2>
        </div>
        
        <div style={modalStyles.content}>

          {/* Fullscreen Button */}
          <div style={modalStyles.row}>
            <button style={modalStyles.iconButton} onClick={onToggleFullscreen}>
              <img src={fullscreenIcon} alt="Fullscreen" style={modalStyles.icon} />
              <span style={modalStyles.text}>Fullscreen</span>
            </button>
          </div>

          {/* Mute/Volume Control */}
          <div style={modalStyles.row}>
            <button style={modalStyles.iconButton} onClick={onToggleMute}>
              <img src={isMuted ? muteIcon : volumeIcon} alt="Mute" style={modalStyles.icon} />
            </button>
            <input
              type="range"
              id="volume-control"
              min="0"
              max="1"
              step="0.01"
              value={isMuted ? 0 : volume}
              onChange={(e) => onVolumeChange(e.target.value)}
              disabled={isMuted} // Disable slider if muted
              style={modalStyles.range}
            />
            <span style={modalStyles.percentage}>
              {isMuted ? '0%' : `${Math.round(volume * 100)}%`}
            </span>
          </div>

          {/* Logout Button */}
          <div style={modalStyles.row}>
            <button style={modalStyles.logoutButton} onClick={handleLogout} disabled={loading}>
              <img src={logoutIcon} alt="Logout" style={modalStyles.logoutIcon} />
              <h2>
                {loading ? 'Logging out...' : 'LOGOUT'}
              </h2>
            </button>
          </div>

        </div>
      </div>
    </div>
  );
};

const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    backgroundColor: '#690DAC',
    color: 'white',
    padding: '20px',
    borderRadius: '48px',
    border: '12px solid #F7CEB2',
    maxWidth: '525px',
    //boxShadow: 'inset 0 0 8px rgba(0, 0, 0, 0.6), inset 0 0 15px rgba(0, 0, 0, 0.3)', // Stacked inset shadows
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3), 0 0 40px rgba(255, 204, 0, 0.5)', 
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  svgContainer: {
    position: 'relative',
    width: '100%',
    display:'flex',
    transform:'translateY(-40px)',
    justifyContent:'center',
    alignItems:'center'
  },
  title: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '32px',
    fontWeight: '700',
    color: '#000',
    textShadow: '2px 2px 10px rgba(0, 0, 0, 0.6)',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center', // Center content in the modal
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center', // Center the logout button
    marginBottom: '10px',
  },
  iconButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: '28px',
    height: '28px',
    marginRight: '8px',
    color:'#fff',
    filter: 'brightness(0) invert(1)',
  },
  text: {
    color: 'white',
    fontWeight:'600',
    fontSize:'24px',
  },
  range: {
    margin: '0 10px',
    flex: 1,
  },
  percentage: {
    color: 'white',
  },
  logoutButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center', // Center text inside button
    padding: '6px 10px',
    backgroundColor: '#3A1BD8',
    border: '6px solid #F7CEB2',
    borderRadius: '36px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    fontSize: '32px',
    fontWeight: '800',
    color: '#fff',
    textShadow: '2px 2px 10px rgba(0, 0, 0, 0.6)',
  },
  logoutIcon: {
    width: '28px',
    height: '28px',
    marginRight: '8px',
    color:'#fff',
    fontWeight:'800',
    marginTop:'-10px'
  },
};

export default Settings;
