import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { ROOT_URL } from '../../../utils/variable'; // Update this path as necessary

const ContentContainer = styled.div`
  flex: 1;
  padding: 20px;
  background-color: #038ea1;
  color: white;
  padding-top: 40px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 5%;
`;

const InputField = styled.input`
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 0px;
  border: none;
  max-width: 350px;
`;

const SubmitButton = styled.button`
  padding: 10px;
  background-color: #a76ef2;
  color: white;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  max-width: 150px;
  width:100%;

  &:hover {
    background-color: #8a5bc9;
  }
`;

const MainHeading = styled.h1`
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 400;
  color: #fff;
  margin-left: 2%;
`;

const useForm = (initialValues) => {
  const [values, setValues] = useState(initialValues);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const resetForm = () => {
    setValues(initialValues);
  };

  return { values, handleChange, resetForm };
};

const CreateAffiliate = ({ selectedMenu }) => {
  const initialFormData = {
    affiliate_id: '',
    username: '', // New field for username
    name: '',
    email: '',
    password: '',
    commission_percentage: 0,
    is_active: true,
  };

  const { values, handleChange, resetForm } = useForm(initialFormData);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const sessionToken = localStorage.getItem('authToken');
      const headers = {
        'Authorization': `Bearer ${sessionToken}`,
        'Content-Type': 'application/json',
      };

      const response = await axios.post(
        `${ROOT_URL}admin/create-affiliate`,
        values,
        { headers }
      );

      console.log('Affiliate created successfully:', response.data);
      resetForm();
    } catch (error) {
      console.error('Error creating affiliate:', error);
    }
  };

  return (
    <ContentContainer>
      {selectedMenu === 'Create Affiliate' && (
        <FormContainer>
          <MainHeading>CREATE AFFILIATE</MainHeading>
          <form onSubmit={handleSubmit}>
            <InputField
              name="affiliate_id"
              placeholder="Affiliate ID"
              value={values.affiliate_id}
              onChange={handleChange}
              required
            />
            <InputField
              name="username" // New input field for username
              placeholder="Username"
              value={values.username}
              onChange={handleChange}
              required
            />
            <InputField
              name="name"
              placeholder="Name"
              value={values.name}
              onChange={handleChange}
              required
            />
            <InputField
              name="email"
              placeholder="Email"
              type="email"
              value={values.email}
              onChange={handleChange}
              required
            />
            <InputField
              name="password"
              placeholder="Password"
              type="password"
              value={values.password}
              onChange={handleChange}
              required
            />
            <InputField
              name="commission_percentage"
              placeholder="Commission %"
              type="number"
              value={values.commission_percentage}
              onChange={handleChange}
              required
            />
            <div style={{ marginTop: '6px' }}>
              <SubmitButton type="submit">Submit</SubmitButton>
            </div>
          </form>
        </FormContainer>
      )}
    </ContentContainer>
  );
};

export default CreateAffiliate;
