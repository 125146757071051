// DeviceRotationPrompt.js
import React, { useState, useEffect } from 'react';
import rotateImage from '../images/rotate.png';

const DeviceRotationPrompt = ({ onRotated }) => {
  const [showPrompt, setShowPrompt] = useState(() => {
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    return isMobile ? window.innerHeight > window.innerWidth : false;
  });
  
  useEffect(() => {
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
   
    const checkOrientation = () => {
      if (isMobile) {
        const isPortrait = window.innerHeight > window.innerWidth;
        setShowPrompt(isPortrait);
        if (!isPortrait) {
          onRotated(false); // Hide prompt first
        } else {
          onRotated(true); // Show prompt, hide content
        }
      } else {
        setShowPrompt(false);
        onRotated(false);
      }
    };

    checkOrientation();
    window.addEventListener('resize', checkOrientation);
    window.addEventListener('orientationchange', checkOrientation);

    return () => {
      window.removeEventListener('resize', checkOrientation);
      window.removeEventListener('orientationchange', checkOrientation);
    };
  }, [onRotated]);

  if (!showPrompt) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black">
      <div className="text-center p-8">
        <div className="mx-auto mb-6 w-32 h-32">
          <img 
            src={rotateImage} 
            alt="Rotate device"
            className="w-full h-full animate-[spin_2s_linear_infinite]"
          />
        </div>
        <h2 className="text-2xl font-bold text-white mb-4">
          Please Rotate Your Device
        </h2>
        <p className="text-gray-300">
          For the best experience, please rotate your device to landscape mode
        </p>
      </div>
    </div>
  );
};

export default DeviceRotationPrompt;