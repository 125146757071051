import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './ResetPassword.css'; 
import usmediumLogo from '../images/usmedium.png';

const PasswordReset = () => {
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [searchParams] = useSearchParams(); // To get the token from URL
    const token = searchParams.get('token');  // Get the reset token from URL

    const handlePasswordReset = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            alert('Passwords do not match.');
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/reset-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token,
                    new_password: password,
                }),
            });

            if (response.ok) {
                // Assuming success, navigate to home after resetting the password
                alert('Password has been reset successfully. You can now log in.');
                navigate('/');
            } else {
                const data = await response.json();
                alert(`Failed to reset password: ${data.detail}`);
            }
        } catch (error) {
            console.error('Error resetting password:', error);
            alert('An error occurred while resetting the password. Please try again.');
        }
    };

    return (
        <div className="password-reset-container">
            <img src={usmediumLogo} alt="USMedium Logo" className="usmedium-logo" />
            <div className="reset-box">
                <h1>Reset Your Password</h1>
                <form onSubmit={handlePasswordReset} className="reset-form">
                    <input
                        type="password"
                        placeholder="New Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <input
                        type="password"
                        placeholder="Confirm New Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                    <button type="submit" className="reset-btn">
                        Reset Password
                    </button>
                </form>
            </div>
        </div>
    );
};

export default PasswordReset;