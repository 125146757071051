import React from 'react';
import coinImage from '../../../images/coin.png'; 
import starImage from '../../../images/star.png';
import profileIcon from '../../../images/user.png';
import transactionIcon from '../../../images/redeems.png';
import helpIcon from '../../../images/FAQ.png';
const lockIcon = '🔒'; 

const DropdownMenu = ({ isOpen,onItemClick }) => {
  if (!isOpen) return null;
  const handleItemClick = (item) => {
    if (onItemClick) {
      onItemClick(item);
    }
  };

  return (
    <div style={dropdownStyles.dropdownContainer}>
      <ul style={dropdownStyles.menuList}>
        <li style={dropdownStyles.specialMenuItem} onClick={() => handleItemClick('Buy Coins')}>
          <img src={coinImage} alt="Buy Coins" style={dropdownStyles.icon} />
          <span style={dropdownStyles.cointext}>Buy Coins</span>
        </li>
        <li style={dropdownStyles.specialMenuItem} onClick={() => handleItemClick('Redeem')}>
          <img src={starImage} alt="Redeem" style={dropdownStyles.icon} />
          <span style={dropdownStyles.cointext}>Redeem</span>
        </li>
        <li style={dropdownStyles.menuItem} onClick={() => handleItemClick('Update Profile')}>
          <img src={profileIcon} alt="Update Profile" style={dropdownStyles.icon} />
          <span style={dropdownStyles.text}>Update Profile</span>
        </li>
        <li style={dropdownStyles.menuItem} onClick={() => handleItemClick('Transactions')}>
          <img src={transactionIcon} alt="Transactions" style={dropdownStyles.icon} />
          <span style={dropdownStyles.text}>Transactions</span>
        </li>
        <li style={dropdownStyles.menuItem} onClick={() => handleItemClick('Game history')}>
          <img src={transactionIcon} alt="GameHistory" style={dropdownStyles.icon} />
          <span style={dropdownStyles.text}>View Game History</span>
        </li>
        <li style={dropdownStyles.menuItem} onClick={() => handleItemClick('Help / FAQ')}>
          <img src={helpIcon} alt="Help / FAQ" style={dropdownStyles.icon} />
          <span style={dropdownStyles.text}>Help / FAQ</span>
        </li>
        <li style={dropdownStyles.menuItem} onClick={() => handleItemClick('Self Exclusion')}>
          <span style={dropdownStyles.icon}>{lockIcon}</span>
          <span style={dropdownStyles.text}>Self Exclusion</span>
        </li>
      </ul>
    </div>
  );
};

const dropdownStyles = {
  dropdownContainer: {
    position: 'absolute',
    top: '70px', 
    left: '1%', 
    backgroundColor: '#690DAC',
    borderRadius: '48px',
    padding: '20px',
    width: '350px',
    zIndex: 1000,
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3), 0 0 40px rgba(255, 204, 0, 0.5)',
    border: '8px solid #F7CEB2',
  },
  menuList: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '6px 0',
    fontSize: '18px',
    cursor: 'pointer',
  },
  specialMenuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 16px',
    backgroundColor: '#142D5B',
    color: '#CFD4DD !important', 
    fontSize: '18px',
    cursor: 'pointer',
    borderRadius: '16px',
    marginBottom: '8px', 
    flexDirection:'row'
  },
  icon: {
    marginRight: '12px',
    width: '28px',
    height: '28px',
  },
  text: {
    flex: 1,
    textAlign: 'left',
    textTransform: 'uppercase',
    color:'#FFFFFF',
    fontWeight:'600',
  },
  cointext: {
    textAlign: 'left',
    textTransform: 'uppercase',
    color:'#FFFFFF',
    fontWeight:'600',
  },
};

export default DropdownMenu;
