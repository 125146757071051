export const PrivacyPoliy = () => (
  <>
    <meta charSet="UTF-8" />
    <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
    <link
      rel="shortcut icon"
      href="assets/images/fav.png"
      type="image/x-icon"
    />
    <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
    <link rel="stylesheet" href="assets/css/fontawesome.min.css" />
    <link rel="stylesheet" href="assets/css/jquery-ui.css" />
    <link rel="stylesheet" href="assets/css/plugin/nice-select.css" />
    <link rel="stylesheet" href="assets/css/plugin/magnific-popup.css" />
    <link rel="stylesheet" href="assets/css/plugin/slick.css" />
    <link rel="stylesheet" href="assets/css/ara-font.css" />
    <link rel="stylesheet" href="assets/css/plugin/animate.css" />
    <link rel="stylesheet" href="assets/css/style.css" />
    {/* Scroll To Top Start*/}
    <a href="javascript:void(0)" className="scrollToTop">
      <i className="fas fa-angle-double-up" />
    </a>
    {/* Scroll To Top End */}
    {/* header-section start */}
    <header className="header-section">
          <div className="overlay">
              <div className="container">
                  <div className="row d-flex header-area">
                      <nav className="navbar navbar-expand-lg navbar-light">
                          <a className="navbar-brand" href="/home">
                              <img
                                  src="assets/images/fav.png"
                                  className="fav d-none d-lg-block d-xl-none"
                                  alt="fav"
                              />
                              <img
                                  src="assets/images/logo.png"
                                  className="logo d-block d-lg-none d-xl-block"
                                  alt="logo"
                              />
                          </a>
                          <button
                              className="navbar-toggler collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#navbar-content"
                          >
                              <i className="fas fa-bars" />
                          </button>
                          <div
                              className="collapse navbar-collapse justify-content-between"
                              id="navbar-content"
                          >
                              <div className="right-area header-action d-flex align-items-center max-un">
                                  <button
                                      type="button"
                                      className="login"
                                      data-bs-toggle="modal"
                                      data-bs-target="#loginMod"
                                  >
                                      Sign In
                                  </button>
                                  <button
                                      type="button"
                                      className="cmn-btn reg"
                                      data-bs-toggle="modal"
                                      data-bs-target="#loginMod"
                                  >
                                      Sign Up
                                  </button>
                              </div>
                          </div>
                      </nav>
                  </div>
              </div>
          </div>
      </header>
      {/* header-section end */}
    {/* Login Registration start */}
    <div className="log-reg">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="modal fade" id="loginMod">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header justify-content-center">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <ul className="nav log-reg-btn d-none justify-content-around">
                    <li className="bottom-area" role="presentation">
                      <button
                        className="nav-link"
                        id="regArea-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#regArea"
                        type="button"
                        role="tab"
                        aria-controls="regArea"
                        aria-selected="false"
                      >
                        SIGN UP
                      </button>
                    </li>
                    <li className="bottom-area" role="presentation">
                      <button
                        className="nav-link active"
                        id="loginArea-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#loginArea"
                        type="button"
                        role="tab"
                        aria-controls="loginArea"
                        aria-selected="true"
                      >
                        LOGIN
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div
                      className="tab-pane fade show active"
                      id="loginArea"
                      role="tabpanel"
                      aria-labelledby="loginArea-tab"
                    >
                      <div className="login-reg-content">
                        <div className="modal-body">
                          <div className="form-area">
                            <form action="#">
                              <div className="section-text text-center">
                                <h3 className="title">Welcome Back</h3>
                                <p>
                                  Not a member ?{" "}
                                  <a
                                    href="javascript:void(0)"
                                    className="reg-btn"
                                  >
                                    Register
                                  </a>
                                </p>
                              </div>
                              <div className="input-area d-flex align-items-center">
                                <img
                                  src="assets/images/icon/email-icon.png"
                                  alt="icon"
                                />
                                <input type="text" placeholder="Email" />
                              </div>
                              <div className="input-area d-flex align-items-center">
                                <img
                                  src="assets/images/icon/security.png"
                                  alt="icon"
                                />
                                <input type="text" placeholder="Password" />
                              </div>
                              <div className="checkbox-item d-flex justify-content-between">
                                <label className="checkbox-single d-flex align-items-center">
                                  <span className="content-area">
                                    <span className="checkbox-area d-flex">
                                      <input type="checkbox" />
                                      <span className="checkmark" />
                                    </span>
                                    <span className="item-title d-flex align-items-center">
                                      <span>Remember me</span>
                                    </span>
                                  </span>
                                </label>
                                <a href="javascript:void(0)">Forget Password</a>
                              </div>
                              <div className="btn-area text-center">
                                <a
                                  href="javascript:void(0)"
                                  className="cmn-btn mt-4"
                                >
                                  Login
                                  <i className="icon-d-right-arrow-2" />
                                </a>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="regArea"
                      role="tabpanel"
                      aria-labelledby="regArea-tab"
                    >
                      <div className="login-reg-content regMode">
                        <div className="modal-body">
                          <div className="form-area">
                            <form action="#">
                              <div className="section-text text-center">
                                <h5 className="sub-title">
                                  Ready to get started?
                                </h5>
                                <h3 className="title">
                                  Welcome Bonus +50% SC!
                                </h3>
                                <p>
                                  Already have an account?{" "}
                                  <a
                                    href="javascript:void(0)"
                                    className="log-btn"
                                  >
                                    Login
                                  </a>
                                </p>
                              </div>
                              <div className="input-area d-flex align-items-center">
                                <img
                                  src="assets/images/icon/email-icon.png"
                                  alt="icon"
                                />
                                <input type="text" placeholder="First Name" />
                              </div>
                              <div className="input-area d-flex align-items-center">
                                <img
                                  src="assets/images/icon/email-icon.png"
                                  alt="icon"
                                />
                                <input type="text" placeholder="Last Name" />
                              </div>
                              <div className="input-area d-flex align-items-center">
                                <img
                                  src="assets/images/icon/email-icon.png"
                                  alt="icon"
                                />
                                <input type="text" placeholder="Phone Number" />
                              </div>
                              <div className="input-area d-flex align-items-center">
                                <img
                                  src="assets/images/icon/email-icon.png"
                                  alt="icon"
                                />
                                <input
                                  type="date"
                                  placeholder="Date of Birth"
                                />
                              </div>
                              <div className="input-area d-flex align-items-center">
                                <img
                                  src="assets/images/icon/email-icon.png"
                                  alt="icon"
                                />
                                <input type="text" placeholder="Email" />
                              </div>
                              <div className="input-area d-flex align-items-center">
                                <img
                                  src="assets/images/icon/security.png"
                                  alt="icon"
                                />
                                <input type="text" placeholder="Password" />
                              </div>
                              <div className="sidebar-single-item">
                                <label className="checkbox-single d-flex">
                                  <span className="content-area">
                                    <span className="checkbox-area d-flex">
                                      <input
                                        type="checkbox"
                                        defaultChecked="checked"
                                      />
                                      <span className="checkmark" />
                                    </span>
                                    <span className="item-title d-flex align-items-center">
                                      <span>
                                        I am at least 18 years of age and I have
                                        read, accepted and agreed to the{" "}
                                        <u>
                                          <a href="javascript:void(0)">
                                            Privacy Policy
                                          </a>
                                          , Bonus T&amp;C,{" "}
                                          <a href="javascript:void(0)">
                                            Terms and Conditions
                                          </a>
                                        </u>
                                      </span>
                                    </span>
                                  </span>
                                </label>
                                <label className="checkbox-single d-flex align-items-center">
                                  <span className="content-area">
                                    <span className="checkbox-area d-flex">
                                      <input type="checkbox" />
                                      <span className="checkmark" />
                                    </span>
                                    <span className="item-title d-flex align-items-center">
                                      <span>
                                        I want to receive promotions by e-mail.
                                      </span>
                                    </span>
                                  </span>
                                </label>
                                <div className="btn-area text-center">
                                  <a
                                    href="javascript:void(0)"
                                    className="cmn-btn mt-4"
                                  >
                                    Get Started Now
                                    <i className="icon-d-right-arrow-2" />
                                  </a>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Login Registration end */}
    {/* Banner Section start */}
    <section className="banner-section inner-banner contact">
      <div className="overlay">
        <div className="banner-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-10">
                <div className="main-content">
                  <h2>Privacy Policy</h2>
                  <div className="breadcrumb-area">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb d-flex align-items-center">
                        <li className="breadcrumb-item">
                          <a href="index.html">Home</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a href="javascript:void(0)">Pages</a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Privacy Policy
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* Banner Section end */}
    {/* Privacy Content In start */}
    <section className="privacy-content">
      <div className="overlay pt-120 pb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="top-wrapper">
                <h4>
                  We're always looking for new ways to provide privacy for our
                  customers.
                </h4>
                <p>
                PRIVACY POLICY<br />
                Version 1.0<br />
                Last Update: August 2024<br /><br />
                For Tropic Gamers LLC, protecting your private information is the priority. This privacy policy 
                applies to all our users and governs how Tropic Gamers LLC collects, stores, uses, and 
                processes your personal data (“Personal Data”). Tropic Gamers LLC is committed to 
                complying with the Website’s applicable privacy regulations, including without limitation the 
                California Consumer Privacy Act (“CCPA”), as amended by the California Privacy Rights Act 
                (“CPRA”), the Virginia Consumer Data Protection Act (“VCDPA”), and any other applicable 
                regulations. This ultraspin.vip, referred to as “WEBSITE,” is a social casino site. By using the 
                Website, you consent to the data practices described in this privacy policy (“Privacy Policy”).
                </p>
                <h5>1. Contact Information</h5>
                <p>
                The responsible party for the data collection and usage on the Website is Tropic Gamers LLC, a 
                limited liability company duly organized under the laws of Delaware, United States, referred to 
                as “Tropic Gamers” or “we,” “us,” or “our,” with its registered address at 300 Delaware Ave, Ste 
                210, Wilmington, DE 19801, USA
                </p>
                <h5>2. What Personal Data do we gather about you and how do we collect it?</h5>
                <p>
                We do collect certain Personal Data when you elect to use the Website and its features. Most 
                Personal Data that we use is collected directly from you. In particular, we collect Personal 
                Information:</p>
                <ul>
                  <li>
                  1. When you create an account to use the Website;
                  </li>
                  <li>
                  2. When you interact with the Website
                  </li>
                  <li>
                  3. When you contact us;
                  </li>
                  <li>
                  4. From third parties when you link your account with social media;
                  </li>
                  <li>
                  5. Through third-party service providers like Google;
                  </li>
                  <li>
                  6. Through the use of a variety of technologies that collect Personal Data, such as 
                  cookies, trackers, and analytical tools.
                  </li>
                </ul>
                <h5>The Personal Data we collect may include the following:</h5>
                <ul>
                  <li>
                  • Identification Information (name, account name, account password, residential 
                  address, date of birth, email address, phone number, IP address, driver’s license 
                  number, passport number, photo or image of yourself, social media user).
                  </li>
                  <li>
                  • Financial Information (signature, bank account number, bank account statement, 
                  credit or debit card number, and other financial information).
                  </li>
                  <li>
                  • Demographic information (gender, age).
                  </li>
                  <li>
                  • Commercial information (products or services purchased, obtained, or considered, or 
                  other purchasing or consuming histories or tendencies).
                  </li>
                  <li>
                  • Internet activity information (browsing history, search history, interaction with the 
                  Website, and interaction with social media).
                  </li>
                  <li>
                  • Device information (information about the device you use to interact with the Website, 
                  such as model, capacity, etc.).
                  </li>
                  <li>
                  • Geolocation information (provided by your device when you are using our Website).
                  </li>
                  <li>
                  • Sensitive information to comply with our legal and regulatory purposes (such as tax 
                  information, financial information, biometric information, government ID, Social 
                  Security number).
                  </li>
                </ul>
                <h5>3. For what purposes do we use your Personal Data?</h5>
                <p>
                We use the Personal Data collected for the following purposes:
                </p>
                <ul>
                  <li>
                  3.1 To Operate the Website and deliver the services you have requested.
                  </li>
                  <li>
                  3.2 To process payment for services through the Website.
                  </li>
                  <li>
                  3.3 To contact you to redeem any benefit granted through the Website.
                  </li>
                  <li>
                  3.4 To conduct market research and analysis and personalize your experience.
                  </li>
                  <li>
                  3.5 To provide customer support or any other notice or information about your account.
                  </li>
                  <li>
                  3.6 To determine if you comply with the required age to access and use the Website.
                  </li>
                  <li>
                  3.7 To determine if you are located within the applicable territories to access and use the 
                  Website.
                  </li>
                  <li>
                  3.8 Marketing Information from Tropic Gamers and/or third parties.
                  </li>
                  <li>
                  3.9 To comply with all applicable regulations.
                  </li>
                  <li>
                  3.10 To share or sell your Personal Data to third parties, subject to the rights established under 
                  Section 5.
                  </li>
                </ul>
                <h5>5. What are your rights regarding your Personal Data and how can you exercise them?</h5>
                <p>
                You have several rights regarding your Personal Data, including the Right to Know, Right to Optout, Right to Non-discriminatory Treatment, Right to Correct, Right to Delete, Data Portability, 
                and the Right to Limit the Use and Disclosure of Sensitive Personal Data.
                You can exercise any of your rights by contacting us at support@ultraspin.vip.
                </p>
                <h5>
                6. How long do we keep your Personal Data?
                </h5>
                <p>
                Tropic Gamers will keep your Personal Data for the time necessary to fulfill the purposes set 
                forth above, as required by law.
                </p>
                <h5>7. How do we secure your Personal Data?</h5>
                <p>
                  We take necessary technical and organizational measures to protect your Personal Data, 
                  including storing it in secure environments and ensuring only authorized personnel have 
                  access.
                </p>
                <h5>Cross-border processing of your personal information</h5>
                <p>
                  Tropic Gamers LLC is headquartered in the United States. To
                  provide and operate our services, it is necessary for us to
                  process your personal information in the United States.
                </p>
                <h5>8. What happens if you do not wish to provide your Personal Data?</h5>
                <p>
                If you choose not to submit any Personal Data when requested, you may not be able to use 
                some features of the Website.
                </p>
                <h5>9. To whom do we share or sell your Personal Data?</h5>
                <p>
                Tropic Gamers may share or transfer Personal Data to third-party providers for purposes listed 
                above. We may also sell Personal Data unless you decide to opt out.
                </p>
                <h5>10. How do we process children’s Personal Data?</h5>
                <p>
                Our Website is not intended for children under the age of 18. We do not knowingly collect 
                Personal Data from minors. If we discover such data, we will delete it.
                </p>
                <h5>11. How can you contact us?</h5>
                <p>
                For any questions, comments, or complaints regarding this Privacy Policy, please contact us 
                at:
                Email address: support@ultraspin.vip
                Physical address: 300 Delaware Ave, Ste 210, Wilmington, DE 19801, USA
                </p>
                <h5>Children</h5>
                <p>
                  Our Platform is not intended for children under 18 years old.
                  If we learn that we have collected personal information from a
                  child without verification of parental consent, we will delete
                  the information.
                </p>
                <h5>Changes to This Privacy Policy</h5>
                <p>
                Tropic Gamers reserves the right to update this Privacy Policy from time to time. We will notify 
                you of significant changes by email, and your continued use of the Website constitutes your 
                agreement to the modified Privacy Policy.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* Create Account In end */}
    {/* Footer Area Start */}
    <footer className="footer-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="footer-form">
              <div className="section-area mb-30 text-center">
                <h4 className="title">To Get Exclusive Benefits</h4>
              </div>
              <form action="#">
                <div className="form-group input-area d-flex align-items-center">
                  <input type="text" placeholder="Enter your email address" />
                  <button className="btn-area">
                    <img src="assets/images/icon/send-icon.png" alt="icon" />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="footer-area pt-120">
          <div className="row">
            <div className="col-xl-12">
              <div className="footer-top d-flex align-items-center justify-content-between">
                <a href="/home">
                  <img
                    src="assets/images/logo.png"
                    className="logo"
                    alt="logo"
                  />
                </a>
                <div className="footer-box">
                  <ul className="footer-link d-flex align-items-center gap-4">
                  <li>
                      <a href="/contact-us">Contact</a>
                    </li>
                    <li>
                      <a href="/privacy-policy">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="/termandconditions">Terms of Service</a>
                    </li>
                    <li>
                      <a href="/kyc">KYC/AML</a>
                    </li>
                    <li>
                      <a href="/rsg">Responsible Social Gaming</a>
                    </li>
                    <li>
                      <a href="/faq">FAQ</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row justify-content-between align-items-center">
              <div className="col-lg-7 d-flex justify-content-center justify-content-lg-start order-lg-0 order-1">
                <div className="copyright text-center">
                  <p>© Tropic Gamers 2023 | All rights reserved.</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-5 d-flex justify-content-center justify-content-lg-end">
                <div className="social">
                  <ul className="footer-link gap-2 d-flex align-items-center">
                    <li>
                      <a href="javascript:void(0)">
                        <i className="fb fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">
                        <i className="ins fab fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">
                        <i className="tw fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">
                        <i className="in fab fa-linkedin-in" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    {/* Footer Area End */}
    {/*==================================================================*/}
  </>
)
  