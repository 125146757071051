import React from 'react';
import styled from 'styled-components';

const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #007b8f;
  padding: 20px 20px;
  color: white;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-right: 20px;

  img {
    width: 40px;
    margin-right: 10px;
  }
`;

const AdminTitle = styled.h2`
  font-size: 16px;
  margin: 0; 
  color: #e0e0e0; 
`;

const UltraSpinTitle = styled.h1`
  font-size: 28px;
  margin: 0; 
  font-weight: 500;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SearchBar = styled.div`
  position: relative; 
  width: 60%;

  input {
    width: 100%;
    padding: 12px 40px 12px 12px; 
    border-radius: 0px;
    border: none;
    outline: none;
  }
`;

const SearchIcon = styled.img`
  position: absolute;
  right: 10px; 
  top: 50%;
  transform: translateY(-50%); 
  width: 20px; 
  height: 20px; 
  cursor: pointer; 
`;

const IconGroup = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px; 

  .icon {
    margin-left: 15px;
    cursor: pointer;
  }

  .icon img {
    width: 24px;
    height: 24px;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  width: 50%; 
  margin-left: 5%;
`;

function Header() {
  return (
    <HeaderContainer>
      <Logo>
        <img src="/assets/images/admin/logorocket.png" alt="Logo" />
        <TitleContainer>
          <AdminTitle>ADMIN</AdminTitle>
          <UltraSpinTitle>ULTRASPIN</UltraSpinTitle>
        </TitleContainer>
      </Logo>
      <LeftContainer>
        <SearchBar>
          <input type="text" placeholder="Search" />
          <SearchIcon src="/assets/images/admin/search.png" alt="search" />
        </SearchBar>
        <IconGroup>
          <div className="icon">
            <img src="/assets/images/admin/noti.png" alt="Notifications" />
          </div>
          <div className="icon">
            <img src="/assets/images/admin/settings.png" alt="Settings" />
          </div>
        </IconGroup>
      </LeftContainer>
    </HeaderContainer>
  );
}

export default Header;
