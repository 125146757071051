export const ContactUs = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <>
      <meta charSet="UTF-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
      <link
        rel="shortcut icon"
        href="assets/images/fav.png"
        type="image/x-icon"
      />
      <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
      <link rel="stylesheet" href="assets/css/fontawesome.min.css" />
      <link rel="stylesheet" href="assets/css/jquery-ui.css" />
      <link rel="stylesheet" href="assets/css/plugin/nice-select.css" />
      <link rel="stylesheet" href="assets/css/plugin/magnific-popup.css" />
      <link rel="stylesheet" href="assets/css/plugin/slick.css" />
      <link rel="stylesheet" href="assets/css/ara-font.css" />
      <link rel="stylesheet" href="assets/css/plugin/animate.css" />
      <link rel="stylesheet" href="assets/css/style.css" />

      {/* Scroll To Top Start*/}
      <a href="#" className="scrollToTop" onClick={(e) => {
        e.preventDefault();
        scrollToTop();
      }}>
        <i className="fas fa-angle-double-up" />
      </a>
      {/* Scroll To Top End */}
      {/* header-section start */}
      <header className="header-section">
        <div className="overlay">
          <div className="container">
            <div className="row d-flex header-area">
              <nav className="navbar navbar-expand-lg navbar-light">
                <a className="navbar-brand" href="/home">
                  <img
                    src="assets/images/fav.png"
                    className="fav d-none d-lg-block d-xl-none"
                    alt="fav"
                  />
                  <img
                    src="assets/images/logo.png"
                    className="logo d-block d-lg-none d-xl-block"
                    alt="logo"
                  />
                </a>
                <button
                  className="navbar-toggler collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbar-content"
                >
                  <i className="fas fa-bars" />
                </button>
                <div
                  className="collapse navbar-collapse justify-content-between"
                  id="navbar-content"
                >
                  <div className="right-area header-action d-flex align-items-center max-un">
                    <button
                      type="button"
                      className="login"
                      data-bs-toggle="modal"
                      data-bs-target="#loginMod"
                    >
                      Sign In
                    </button>
                    <button
                      type="button"
                      className="cmn-btn reg"
                      data-bs-toggle="modal"
                      data-bs-target="#loginMod"
                    >
                      Sign Up
                    </button>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
      {/* header-section end */}
      {/* Login Registration start */}
      <div className="log-reg">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="modal fade" id="loginMod">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header justify-content-center">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <ul className="nav log-reg-btn d-none justify-content-around">
                      <li className="bottom-area" role="presentation">
                        <button
                          className="nav-link"
                          id="regArea-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#regArea"
                          type="button"
                          role="tab"
                          aria-controls="regArea"
                          aria-selected="false"
                        >
                          SIGN UP
                        </button>
                      </li>
                      <li className="bottom-area" role="presentation">
                        <button
                          className="nav-link active"
                          id="loginArea-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#loginArea"
                          type="button"
                          role="tab"
                          aria-controls="loginArea"
                          aria-selected="true"
                        >
                          LOGIN
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div
                        className="tab-pane fade show active"
                        id="loginArea"
                        role="tabpanel"
                        aria-labelledby="loginArea-tab"
                      >
                        <div className="login-reg-content">
                          <div className="modal-body">
                            <div className="form-area">
                              <form action="#">
                                <div className="section-text text-center">
                                  <h3 className="title">Welcome Back</h3>
                                  <p>
                                    Not a member ?{" "}
                                    <a href="#" className="scrollToTop" onClick={(e) => {
                                      e.preventDefault();
                                      scrollToTop();
                                    }}>
                                      Register
                                    </a>
                                  </p>
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/email-icon.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="Email" />
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/security.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="Password" />
                                </div>
                                <div className="checkbox-item d-flex justify-content-between">
                                  <label className="checkbox-single d-flex align-items-center">
                                    <span className="content-area">
                                      <span className="checkbox-area d-flex">
                                        <input type="checkbox" />
                                        <span className="checkmark" />
                                      </span>
                                      <span className="item-title d-flex align-items-center">
                                        <span>Remember me</span>
                                      </span>
                                    </span>
                                  </label>
                                  <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    scrollToTop();
                                  }}>Forget Password</a>
                                </div>
                                <div className="btn-area text-center">
                                  <a href="#"
                                    className="cmn-btn mt-4"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      scrollToTop();
                                    }}>

                                    Login
                                    <i className="icon-d-right-arrow-2" />
                                  </a>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="regArea"
                        role="tabpanel"
                        aria-labelledby="regArea-tab"
                      >
                        <div className="login-reg-content regMode">
                          <div className="modal-body">
                            <div className="form-area">
                              <form action="#">
                                <div className="section-text text-center">
                                  <h5 className="sub-title">
                                    Ready to get started?
                                  </h5>
                                  <h3 className="title">
                                    Welcome Bonus +50% SC!
                                  </h3>
                                  <p>
                                    Already have an account?{" "}
                                    <a href="#"
                                      className="log-btn"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        scrollToTop();
                                      }}>
                                      Login
                                    </a>
                                  </p>
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/email-icon.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="First Name" />
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/email-icon.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="Last Name" />
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/email-icon.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="Phone Number" />
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/email-icon.png"
                                    alt="icon"
                                  />
                                  <input
                                    type="date"
                                    placeholder="Date of Birth"
                                  />
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/email-icon.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="Email" />
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/security.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="Password" />
                                </div>
                                <div className="sidebar-single-item">
                                  <label className="checkbox-single d-flex">
                                    <span className="content-area">
                                      <span className="checkbox-area d-flex">
                                        <input
                                          type="checkbox"
                                          defaultChecked="checked"
                                        />
                                        <span className="checkmark" />
                                      </span>
                                      <span className="item-title d-flex align-items-center">
                                        <span>
                                          I am at least 18 years of age and I have
                                          read, accepted and agreed to the{" "}
                                          <u>
                                            <a href="#" onClick={(e) => {
                                              e.preventDefault();
                                              scrollToTop();
                                            }}>
                                              Privacy Policy
                                            </a>
                                            , Bonus T&amp;C,{" "}
                                            <a
                                              href="#"
                                              onClick={(e) => {
                                                e.preventDefault(); // Prevent the default anchor behavior
                                              }}
                                            >
                                              Terms and Conditions
                                            </a>

                                          </u>
                                        </span>
                                      </span>
                                    </span>
                                  </label>
                                  <label className="checkbox-single d-flex align-items-center">
                                    <span className="content-area">
                                      <span className="checkbox-area d-flex">
                                        <input type="checkbox" />
                                        <span className="checkmark" />
                                      </span>
                                      <span className="item-title d-flex align-items-center">
                                        <span>
                                          I want to receive promotions by e-mail.
                                        </span>
                                      </span>
                                    </span>
                                  </label>
                                  <div className="btn-area text-center">
                                    <a
                                      href="#"
                                      className="cmn-btn mt-4"
                                      onClick={(e) => {
                                        e.preventDefault(); // Prevent the default anchor behavior
                                      }}
                                    >
                                      Get Started Now
                                      <i className="icon-d-right-arrow-2" />
                                    </a>

                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Login Registration end */}
      {/* Banner Section start */}
      <section className="banner-section inner-banner contact">
        <div className="overlay">
          <div className="banner-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-10">
                  <div className="main-content">
                    <h1>Contact</h1>
                    <div className="breadcrumb-area">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb d-flex align-items-center">
                          <li className="breadcrumb-item">
                            <a href="index.html">Home</a>
                          </li>
                          <li className="breadcrumb-item">
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault(); // Prevent the default anchor behavior
                              }}
                            >
                              Pages
                            </a>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            Contact
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Banner Section end */}
      {/* Get in Touch start */}
      <section className="get-in-touch">
        <div className="overlay pt-120 pb-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="section-text">
                  <h5 className="sub-title">Contact</h5>
                  <h2 className="title">Get in Touch</h2>
                </div>
                <div className="cus-mar d-flex justify-content-between align-items-center">
                  <div className="single-box">
                    <div className="icon-area">
                      <img src="assets/images/icon/info-icon-1.png" alt="icon" />
                    </div>
                    <h5>Location:</h5>
                    <p>300 Delaware Ave Ste 210, Wilmington DE 19801</p>
                  </div>
                  <div className="single-box">
                    <div className="icon-area">
                      <img src="assets/images/icon/info-icon-2.png" alt="icon" />
                    </div>
                    <h5>Get in Touch</h5>
                    <p className="phone-email">support@ultraspin.vip</p>
                    <p className="phone-email">admin@ultraspin.vip</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <form action="#">
                  <div className="col-12">
                    <div className="single-input">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        id="name"
                        placeholder="John Doe"
                        autoComplete="off"
                      />
                    </div>
                    <div className="single-input">
                      <label htmlFor="email">Email</label>
                      <input
                        type="text"
                        id="email"
                        placeholder="your@gmail.com"
                        autoComplete="off"
                      />
                    </div>
                    <div className="single-input">
                      <label htmlFor="message">Message</label>
                      <textarea
                        cols={30}
                        id="message"
                        rows={4}
                        placeholder="Type something here"
                        defaultValue={""}
                      />
                    </div>
                    <div className="btn-area text-center">
                      <button className="cmn-btn">Submit</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Get in Touch end */}
      {/* Footer Area Start */}
      <footer className="footer-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="footer-form">
                <div className="section-area mb-30 text-center">
                  <h4 className="title">To Get Exclusive Benefits</h4>
                </div>
                <form action="#">
                  <div className="form-group input-area d-flex align-items-center">
                    <input type="text" placeholder="Enter your email address" />
                    <button className="btn-area">
                      <img src="assets/images/icon/send-icon.png" alt="icon" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="footer-area pt-120">
            <div className="row">
              <div className="col-xl-12">
                <div className="footer-top d-flex align-items-center justify-content-between">
                  <a href="/home">
                    <img
                      src="assets/images/logo.png"
                      className="logo"
                      alt="logo"
                    />
                  </a>
                  <div className="footer-box">
                    <ul className="footer-link d-flex align-items-center gap-4">
                    <li>
                        <a href="/contact-us">Contact</a>
                      </li>
                      <li>
                        <a href="/privacy-policy">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/termandconditions">Terms of Service</a>
                      </li>
                      <li>
                        <a href="/kyc">KYC/AML</a>
                      </li>
                      <li>
                        <a href="/rsg">Responsible Social Gaming</a>
                      </li>
                      <li>
                        <a href="/faq">FAQ</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-bottom">
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-7 d-flex justify-content-center justify-content-lg-start order-lg-0 order-1">
                  <div className="copyright text-center">
                    <p>© UltraSpin 2024 | All rights reserved.</p>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-5 d-flex justify-content-center justify-content-lg-end">
                  <div className="social">
                    <ul className="footer-link gap-2 d-flex align-items-center">
                      <li>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault(); // Prevent the default anchor behavior
                          }}
                        >
                          <i className="fb fab fa-facebook-f" />
                        </a>

                      </li>
                      <li>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault(); // Prevent the default anchor behavior
                          }}
                        >
                          <i className="ins fab fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault(); // Prevent the default anchor behavior
                          }}
                        >
                          <i className="tw fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault(); // Prevent the default anchor behavior
                          }}
                        >
                          <i className="in fab fa-linkedin-in" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* Footer Area End */}
      {/*==================================================================*/}
    </>

  )
}