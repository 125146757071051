import React, { useState, useEffect } from 'react';
import lowerbarImage from '../../images/lowerbar.png';
import PrizeWheel from './PrizeWheel';
import icon1 from '../../images/all.png'; 
import icon2 from '../../images/jackpot.png'; 
import icon3 from '../../images/fish.png'; 
import icon4 from '../../images/fav.png'; 
import icon5 from '../../images/table.png'; 
import icon6 from '../../images/other.png';  
import './Lowerbar.css';

const Lowerbar = ({ onFilter,userId, onSearchIconClick, onSpinIconClick, cooldownTime }) => {

  const handleSpinClick = () => {
    console.log(userId);
    if(userId){
      onSpinIconClick(userId);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  const handleFavoritesFilter = () => {
    const favoriteGames = JSON.parse(localStorage.getItem('favorites')) || [];
    onFilter('FAVORITES', favoriteGames);
  };

  return (
    <div className="lowerbar-container">
      <PrizeWheel userId={userId} onClick={handleSpinClick}/>
      <img src={lowerbarImage} alt="Lowerbar" className="lowerbar-image" />
      <div className="icons-container">
        <div className="icons-group">
          <button onClick={() => onFilter('ALL')} className="icon-button">
          <div className="icon-wrapper">
            <img src={icon1} alt="All Games" className="nav-icon" />
          </div>
            <span className="icon-label">All Games</span>
          </button>
          <button onClick={() => onFilter('SLOTS')} className="icon-button">
          <div className="icon-wrapper">
            <img src={icon2} alt="Slots" className="nav-icon" />
          </div>
            <span className="icon-label">Slots</span>
          </button>
          <button onClick={() => onFilter('FISHING_ARCADE')} className="icon-button">
          <div className="icon-wrapper">
            <img src={icon3} alt="Fishing and Arcade" className="nav-icon" />
          </div>
            <span className="icon-label">Fish</span>
          </button>
        </div>
        <div className="timer-display">{formatTime(cooldownTime)}</div>
        <div className="icons-group">
          <button onClick={handleFavoritesFilter} className="icon-button">
          <div className="icon-wrapper">
            <img src={icon4} alt="Favorites" className="nav-icon" />
          </div>
            <span className="icon-label">Favorites</span>
          </button>
          <button onClick={() => onFilter('TABLE_BINGO_POKER')} className="icon-button">
          <div className="icon-wrapper">
            <img src={icon5} alt="Table Games" className="nav-icon" />
          </div>
            <span className="icon-label">Other</span>
          </button>
          <button onClick={onSearchIconClick} className="icon-button">
          <div className="icon-wrapper">
            <img src={icon6} alt="Search" className="nav-icon" />
          </div>
            <span className="icon-label">Search</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Lowerbar;