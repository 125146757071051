import React, { useState, useRef, useEffect } from 'react';
import './SearchModal.css'; 

const SearchModal = ({ isOpen, onClose, onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedProvider, setSelectedProvider] = useState('');
  const modalRef = useRef();

  const providers = [
    { label: "Fa Chai", value: "Fa Chai Gaming" },
    { label: "Mancala Gaming", value: "Mancala" },
    { label: "KA Gaming", value: "Kagaming2" },
    { label: "Spadegaming", value: "Spadegaming" },
    { label: "Zeusplay", value: "Zeus Play" },
    { label: "BGaming (Coming Soon)", value: "BGaming" },
    { label: "NetGame (Coming Soon)", value: "Netgame" },
    { label: "PG SOFT (Coming Soon)", value: "PGSoft" }
  ];

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isOpen && modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen, onClose]);

  const handleSearch = () => {
    onSearch(searchTerm, selectedProvider);  
    onClose();  
  };

  const handleRecentlyPlayed = () => {
    onSearch('', 'recentlyPlayed');  // Use a specific identifier for recently played
    onClose();
  };

  return (
    isOpen && (
      <div className="search-modal-overlay">
        <div className="search-modal" ref={modalRef}>
          <div className="search-modal-header">
            <h2>Search Games</h2>
            <button onClick={onClose} className="close-button" aria-label="Close modal">X</button>
          </div>
          <div className="search-modal-body">
            <input
              type="text"
              placeholder="Search for a game..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input my-2"
              aria-label="Search input"
            />
            
            <select
              value={selectedProvider}
              onChange={(e) => setSelectedProvider(e.target.value)}
              className="provider-select"
              aria-label="Select provider"
            >
              <option value="">Select a provider</option>
              {providers.map((provider, index) => (
                <option key={index} value={provider.value}>
                  {provider.label}
                </option>
              ))}
            </select>
          </div>
          <div className="search-modal-footer">
            <button onClick={handleRecentlyPlayed} className="recently-played-button" aria-label="View recently played games">
              Recently Played Games
            </button>
            <button onClick={handleSearch} className="search-button" aria-label="Search for games">
              Search
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default SearchModal;
