import React from 'react';
import backgroundImage from '../../images/bg.png';

const Background = () => {
  return (
    <div style={styles.container}>
      <img src={backgroundImage} alt="Background" style={styles.image} />
    </div>
  );
};

const styles = {
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 0,
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
};

export default Background;