import React from 'react';
import { useNavigate } from 'react-router-dom';
import './EmailVerified.css'; 
import usmediumLogo from '../images/usmedium.png';
import sudopRobot from '../images/sudop1.png';

const EmailVerified = () => {
    const navigate = useNavigate();

    const handleGoToHome = () => {
        navigate('/'); 
    };

    return (
        <div className="email-verified-container">
            <img src={usmediumLogo} alt="USMedium Logo" className="usmedium-logo" />
            <div className="message-box">
                <div className="message-with-icon">
                    <img src={sudopRobot} alt="Sudo Robot" className="sudop-robot" />
                    <div className="message-content">
                        <h1>Email Verified Successfully!</h1>
                        <p>Thank you for verifying your email.</p>
                        <button className="go-home-btn" onClick={handleGoToHome}>
                            Go to Homepage
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmailVerified;

