// src/components/Sudo.js
import React from 'react';
import SudoAnimation from './Sudo/SudoAnimation';  // Assuming your animation component is in this path

const Sudo = () => {
  return (
    <div>
      <SudoAnimation />
    </div>
  );
};

export default Sudo;
