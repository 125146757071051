import React, { useEffect, useState, useRef } from 'react';
import Topbar from '../components/Lobby/TopBar';
import Background from '../components/Lobby/Background';
import Lowerbar from '../components/Lobby/LowerBar';
import GameGrid from '../components/Lobby/GameGrid';
import PolicyModal from '../components/Modals/PolicyModal';
import SearchModal from '../components/Modals/SearchModal';
import lobbyMusic from '../images/lobbymusic.mp3';
import { ROOT_URL } from '../utils/variable';
import SpinningWheel from '../components/Lobby/SpinningWheel';
import DeviceRotationPrompt from './DeviceRotationPrompt';

const ITEMS_PER_PAGE = 10;
const INITIAL_PAGES_TO_LOAD = 3;

const Lobby = () => {
  // State declarations
  const [games, setGames] = useState([]);
  const [filteredGames, setFilteredGames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [isPolicyModalOpen, setIsPolicyModalOpen] = useState(false);
  const [showWheel, setShowWheel] = useState(false);
  const [cooldownTime, setCooldownTime] = useState(0);
  const [currentFilter, setCurrentFilter] = useState('ALL');
  const [userId, setUserId] = useState(null);
  const [showPrompt, setShowPrompt] = useState(() => {
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    return isMobile ? window.innerHeight > window.innerWidth : false;
  });

  // Refs
  const audioRef = useRef(null);
  const topBarRef = useRef(null);

  const FEATURED_GAMES = [
    "bgaming-SnoopDoggDollars",
    "pgsoft-wild-bandito",
    "bgaming-AlienFruits",
    "mancala-astro-jewels",
    "netgame-african-king-hold-n-link",
    "spadegaming-alien-hunter",
    "bgaming-BoneBonanza",
    "pgsoft-fortune-tiger",
    "spadegaming-double-flame",
    "fcgaming-fortune-sheep",
    "mancala-sharkyfrenzy",
    "pgsoft-crypto-gold",
    "bgaming-CandyMonsta",
    "pgsoft-buffalo-win",
    "mancala-coinspin-fever",
    "pgsoft-hawaiian-tiki",
    "netgame-diamond-shoot",
    "mancala-gemblitz-bonanza",
    "gsoft-three-crazy-piggies",
    "spadegaming-candy-pop-2",
    "netgame-b-u-c-k-s-",
    "mancala-eternal-dynasty",
    "pgsoft-hip-hop-panda",
    "bgaming-GodOfWealthHoldAndWin",
    "spadegaming-FishingGod",
    "pgsoft-fortune-rabbit",
    "mancala-hot-fruits-on-ice",
    "bgaming-CarnivalBonanza",
    "gaming-DragonAgeHoldAndWin",
    "bgaming-dragons-gold-100",
    "mancala-hyperxplorer",
    "netgame-hot-cash-hold-n-link",
    "spadegaming-FishingWar",
    "bgaming-FireLightning",
    "pgsoft-fortune-dragon",
    "mancala-reel-reel-hot",
    "bgaming-KrakensHunger",
    "pgsoft-prosperity-lion",
    "gsoft-ways-of-qilin",
    "bgaming-Oof",
    "fcgaming-golden-genie",
    "mancala-bust-and-win",
    "netgame-golden-tree",
    "spadegaming-caishen-deluxe-maxways",
    "netgame-royal-fruits-5",
    "spadegaming-zombie-party",
    "bgaming-Maneki88Gold",
    "spadegaming-fruits-mania",
    "spadegaming-GoldenMonkey",
    "spadegaming-FaFaFa2",
    "pgsoft-reel-love",
    "fcgaming-war-of-the-universe"
  ];

  const sortByFeatured = (games) => {
    return [...games].sort((a, b) => {
      const aIndex = FEATURED_GAMES.indexOf(a.json_id);
      const bIndex = FEATURED_GAMES.indexOf(b.json_id);

      if (aIndex !== -1 && bIndex !== -1) {
        return aIndex - bIndex;
      }
      if (aIndex !== -1) return -1;
      if (bIndex !== -1) return 1;
      return 0;
    });
  };

  // Helper function to deduplicate games
  const deduplicateGames = (games) => {
    return games.reduce((acc, current) => {
      const exists = acc.find(item => item.json_id === current.json_id);
      if (!exists) {
        return [...acc, current];
      }
      return acc;
    }, []);
  };

  const fetchGames = async (page, search = '', provider = '', isInitialLoad = false) => {
    setLoading(true);
    setError(null);

    try {
      const searchParam = search ? `&search=${encodeURIComponent(search)}` : '';
      let url = search 
        ? `https://api.ultraspin.vip/game/?page=${page}&limit=${ITEMS_PER_PAGE}${searchParam}`
        : `${ROOT_URL}game/library?page=${page}&limit=${50}`;

      const response = await fetch(url);
      console.log('Response from games data:', response);

      if (response.ok) {
        const data = await response.json();
        console.log('JSON from games data:', data);

        console.log(`Search:${search} and provider:${provider}`);
        let filteredGames = search ? data.games : data.items;
        console.log('Filtered games after:memo:', filteredGames);

        if (provider) {
          filteredGames = filteredGames.filter(game => game.provider === provider);
        }

        // Sort games to prioritize featured games
        filteredGames = sortByFeatured(filteredGames);

        if (search) {
          setGames(filteredGames);
          setFilteredGames(applyFilter(filteredGames, currentFilter));
        } else {
          setGames(prevGames => {
            const combinedGames = isInitialLoad || search || provider 
              ? filteredGames 
              : [...prevGames, ...filteredGames];
            const uniqueGames = deduplicateGames(combinedGames);
            return sortByFeatured(uniqueGames);
          });

          setFilteredGames(prevFiltered => {
            const combinedGames = isInitialLoad || search || provider 
              ? filteredGames 
              : [...prevFiltered, ...filteredGames];
            const uniqueGames = deduplicateGames(combinedGames);
            return applyFilter(uniqueGames, currentFilter);
          });
        }

        console.log('Filtered games:', filteredGames);

        setHasMore(filteredGames.length === ITEMS_PER_PAGE);
      } else {
        throw new Error('Failed to retrieve games');
      }
    } catch (error) {
      console.error('Error fetching games:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const storedUserId = localStorage.getItem('user_id');
    if (storedUserId) {
      setUserId(storedUserId);
    } else {
      console.error("No User ID available in localStorage");
    }
  }, []);

  useEffect(() => {
    const loadInitialPages = async () => {
      setLoading(true);
      try {
        for (let page = 1; page <= INITIAL_PAGES_TO_LOAD; page++) {
          await fetchGames(page, searchTerm, page === 1);
        }
      } catch (error) {
        console.error('Error during initial load:', error);
      } finally {
        setLoading(false);
      }
    };

    loadInitialPages();
    if (userId) fetchCooldownTime();
  }, [userId]);

  const handleSearch = debounce(async (searchTerm, provider) => {
    console.log("Search term:", searchTerm, "Provider:", provider);  // Debugging log
  
    setSearchTerm(searchTerm);
    setCurrentPage(1);
  
    if (provider === 'recentlyPlayed') {
      await fetchRecentlyPlayedGames();
    } else {
      await fetchGames(1, searchTerm, provider, true);
    }
  }, 300);
  
  const fetchRecentlyPlayedGames = async () => {
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.error("Auth token not found in localStorage.");
        return;
      }
  
      // Step 1: Fetch recent game IDs
      const recentGamesResponse = await fetch('https://api.ultraspin.vip/game/recent-games', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      if (!recentGamesResponse.ok) {
        throw new Error('Failed to fetch recently played games');
      }
  
      const recentGamesData = await recentGamesResponse.json();
      const recentGameIds = recentGamesData.map(game => game.game_id); // Array of recent `game_id`s
      console.log("Recent game IDs:", recentGameIds);
  
      // Step 2: Fetch all games and filter based on recentGameIds
      const libraryResponse = await fetch(`${ROOT_URL}game/library`);
      if (!libraryResponse.ok) {
        throw new Error('Failed to fetch games library');
      }
  
      const libraryData = await libraryResponse.json();
      const allGames = libraryData.items; // Assuming library returns games in `items`
  
      // Step 3: Filter all games to include only recently played games
      const recentGames = allGames.filter(game => recentGameIds.includes(game.json_id));
      console.log("Mapped recent games:", recentGames);
  
      setFilteredGames(recentGames); // Display these games as recently played
    } catch (error) {
      console.error('Error fetching and mapping recently played games:', error);
      setError(error.message);
    }
  };
  
  const handleFilter = (filterType) => {
    setCurrentFilter(filterType);
    setFilteredGames(applyFilter(games, filterType));
  };
  
  const applyFilter = (gamesArray, filterType) => {
    let filtered;
    switch (filterType) {
      case 'ALL':
        filtered = gamesArray;
        break;
      case 'SLOTS':
        filtered = gamesArray.filter(game => 
          game.categories.includes('slots')
        );
        break;
      case 'FISHING_ARCADE':
        filtered = gamesArray.filter(game => 
          game.categories.includes('fishing') || 
          game.categories.includes('shooting')
        );
        break;
      case 'TABLE_BINGO_POKER':
        filtered = gamesArray.filter(game => 
          !game.categories.includes('slots') && 
          !game.categories.includes('fishing') && 
          !game.categories.includes('shooting')
        );
        break;
      case 'FAVORITES':
        const favorites = JSON.parse(localStorage.getItem('favorites')) || [];
        filtered = gamesArray.filter(game => favorites.includes(game.json_id));
        break;
      case 'RECENTLY_PLAYED':
        const recentGames = JSON.parse(localStorage.getItem('recentGames')) || [];
        filtered = gamesArray.filter(game => recentGames.includes(game.json_id));
        break;
      default:
        filtered = gamesArray;
    }
    return sortByFeatured(filtered);
  };

  // Spinning wheel handlers
  const handleSpinButtonClick = () => {
    const storedUserId = localStorage.getItem('user_id');
    if (storedUserId) {
      setShowWheel(true);
    } else {
      console.error("No User ID available");
    }
  };

  const handleCloseWheel = () => {
    setShowWheel(false);
  };

  const handleUpdateBalance = async (wonPrize) => {
    if (topBarRef.current) {
      await topBarRef.current.updateBalance(wonPrize);
    }
    fetchCooldownTime();
  };

  // Cooldown timer
  const fetchCooldownTime = async () => {
    try {
      const sessionToken = localStorage.getItem('authToken');
      if (!sessionToken) throw new Error('No authentication token found');

      const userResponse = await fetch(`${ROOT_URL}api/users/me`, {
        headers: {
          'Authorization': `Bearer ${sessionToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (!userResponse.ok) throw new Error('Failed to fetch user data');

      const userData = await userResponse.json();
      if (!userData.id) throw new Error('No user ID found in user data');

      const cooldownResponse = await fetch(`${ROOT_URL}game/spin-cooldown/${userData.id}`, {
        headers: {
          'Authorization': `Bearer ${sessionToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (!cooldownResponse.ok) throw new Error('Failed to fetch cooldown time');

      const cooldownData = await cooldownResponse.json();
      setCooldownTime(cooldownData.cooldown_seconds);
    } catch (error) {
      console.error('Error fetching cooldown:', error);
      if (error.message.includes('401')) {
        localStorage.removeItem('authToken');
        window.location.href = '/';
      }
    }
  };

  // Cooldown timer effect
  useEffect(() => {
    if (cooldownTime > 0) {
      const timer = setInterval(() => {
        setCooldownTime(prev => Math.max(prev - 1, 0));
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [cooldownTime]);

  // Responsive design handlers
  const updateAspectRatio = () => {
    const container = document.getElementById('lobby-content');
    if (!container) return;

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const aspectRatio = windowWidth / windowHeight;

    if (windowWidth < 768) {
      container.style.aspectRatio = '19 / 9';
      if (aspectRatio < 16 / 9) {
        container.style.width = `${windowHeight * (16 / 9)}px`;
      } else if (aspectRatio > 20 / 9) {
        container.style.width = `${windowHeight * (20 / 9)}px`;
      } else {
        container.style.width = `${windowWidth}px`;
      }
    } else if (windowWidth <= 1196) {
      container.style.aspectRatio = '18 / 9';
      if (aspectRatio < 4 / 3) {
        container.style.width = `${windowHeight * (4 / 3)}px`;
      } else if (aspectRatio > 16 / 9) {
        container.style.width = `${windowHeight * (18 / 9)}px`;
      } else {
        container.style.width = `${windowWidth}px`;
      }
    } else if (windowWidth <= 1496 || windowWidth <= 2500) {
      container.style.aspectRatio = '16 / 9';
      if (aspectRatio < 4 / 3) {
        container.style.width = `${windowHeight * (4 / 3)}px`;
      } else if (aspectRatio > 16 / 9) {
        container.style.width = `${windowHeight * (16 / 9)}px`;
      } else {
        container.style.width = `${windowWidth}px`;
      }
    } else {
      if (aspectRatio < 16 / 9) {
        container.style.width = `${windowHeight * (16 / 9)}px`;
        container.style.height = `${windowHeight}px`;
      } else if (aspectRatio > 20 / 9) {
        container.style.width = `${windowHeight * (20 / 9)}px`;
        container.style.height = `${windowHeight}px`;
      } else {
        container.style.width = `${windowWidth}px`;
        container.style.height = `${windowWidth / aspectRatio}px`;
      }
    }

    container.style.height = 'auto';
    container.style.maxWidth = '2500px';
    container.style.maxHeight = '1406px';
  };

  // Aspect ratio effect
  useEffect(() => {
    updateAspectRatio();
    window.addEventListener('resize', updateAspectRatio);
    return () => window.removeEventListener('resize', updateAspectRatio);
  }, []);

  // Mobile fullscreen handler
  useEffect(() => {
    const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);

    if (isMobileDevice) {
      const enterFullScreen = async () => {
        try {
          const element = document.documentElement;
          if (element.requestFullscreen) {
            await element.requestFullscreen();
          } else if (element.mozRequestFullScreen) {
            await element.mozRequestFullScreen();
          } else if (element.webkitRequestFullscreen) {
            await element.webkitRequestFullscreen();
          } else if (element.msRequestFullscreen) {
            await element.msRequestFullscreen();
          }
        } catch (error) {
          console.error("Fullscreen error:", error);
        }
      };

      enterFullScreen();
      return () => {
        if (document.fullscreenElement) {
          document.exitFullscreen();
        }
      };
    }
  }, []);

  // Utility debounce function
  function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  useEffect(() => {
    // Check mobile and orientation on component mount
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    const isPortrait = window.innerHeight > window.innerWidth;
    setShowPrompt(isMobile && isPortrait);
  }, []);

  const fetchUserId = async () => {
    const sessionToken = localStorage.getItem('authToken');
    if (!sessionToken) {
      console.error('No authentication token found');
      return;
    }
    try {
      const response = await fetch(`${ROOT_URL}api/users/me`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${sessionToken}`,
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('user_id',data.id);
        setUserId(data.id);
      } else {
        console.error('Failed to fetch user data');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      if (error.response && error.response.status === 401) {
        alert('Please login again');
        localStorage.removeItem('authToken');
        window.location.href = '/';
      }
    }
  };
  useEffect(() => {
    fetchUserId(); 
  }, []);
  return (
    <div style={styles.wrapper}>
      <DeviceRotationPrompt onRotated={setShowPrompt} />
      {!showPrompt && (
        <div style={styles.lobbyContainer} id="lobby-content">
          <Background />
          <Topbar ref={topBarRef} />
          <GameGrid
            games={filteredGames}
            loading={loading}
            error={error}
            hasMore={hasMore}
            onLoadMore={() => !loading && hasMore && fetchGames(currentPage + 1, searchTerm)}
          />
          <Lowerbar
            userId={userId}
            onFilter={handleFilter}
            onSearchIconClick={() => setIsSearchModalOpen(true)}
            onSpinIconClick={handleSpinButtonClick}
            cooldownTime={cooldownTime}
          />
          <audio ref={audioRef} src={lobbyMusic} loop />
          <PolicyModal
            isOpen={isPolicyModalOpen}
            onClose={() => setIsPolicyModalOpen(false)}
          />
          <SearchModal
            isOpen={isSearchModalOpen}
            onClose={() => setIsSearchModalOpen(false)}
            onSearch={handleSearch}
          />
        </div>
      )}
      {showWheel && !showPrompt && (
        <SpinningWheel
          userId={userId}
          onClose={handleCloseWheel}
          onRewardCollected={handleUpdateBalance}
        />
      )}
    </div>
  );
};

const styles = {
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
    backgroundColor: 'black',
    overflow: 'hidden',
  },
  lobbyContainer: {
    position: 'relative',
    backgroundColor: 'black',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '2500px',
    maxHeight: '1406px',
  },
};



export default Lobby;