import React, { useState } from 'react';
import prizewheelImage from '../../images/prizewheel.png';
import './PrizeWheel.css';

const PrizeWheel = ({ userId, onClick }) => {
  const [isSpinning, setIsSpinning] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div className="prizewheel-container" style={styles.container} onClick={onClick}>
        <img
          src={prizewheelImage}
          alt="Prize Wheel"
          className="prizewheel"
        />
      </div>

      {isModalOpen && (
        <div className="wheel-modal-overlay" onClick={() => setIsModalOpen(false)}>
          <div className="wheel-modal-content" onClick={(e) => e.stopPropagation()}>
            <div className={`modal-prizewheel ${isSpinning ? 'spinning' : ''}`}>
              {/* Wheel image and numbers inside the same rotating container */}
              <img
                src={prizewheelImage}
                alt="Prize Wheel in Modal"
                className="wheel-image"
              />

              {/* Add numbers overlay on the wheel */}
              <div className="number number-1">1</div>
              <div className="number number-2">2</div>
              <div className="number number-3">3</div>
              <div className="number number-4">4</div>
              <div className="number number-5">5</div>
              <div className="number number-6">6</div>
              <div className="number number-7">7</div>
              <div className="number number-8">8</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const styles = {
  container: {
    position: 'absolute',
    bottom: '-5%',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 3,
    cursor: 'pointer',
  },
};

export default PrizeWheel;
