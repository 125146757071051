import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { ROOT_URL } from '../../../utils/variable';
import EditUserModal from './EditUserModal';
const TableContainer = styled.div`
  width: 100%;
  padding: 20px;
  color: white;
  background-color: #007b8f;
`;

const TableTitle = styled.h2`
  text-align: left;
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #fff;
  font-weight: 500;
  margin-left: 5%;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9rem;
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 4px;
  &:not(:first-child) {
    border-left: 2px solid #ddd; /* Adds the vertical line (left border) */
  }
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #024e68;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
  text-align: left;
`;

const Actions = styled.div`
  display: flex;
  gap: 10px;
  cursor: pointer;
`;

const Pagination = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PageButton = styled.button`
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  margin: 0 10px;
  font-size: 1.2rem;

  &:hover {
    color: #ddd;
  }

  &:disabled {
    color: #555;
    cursor: not-allowed;
  }
`;

const PageIndicator = styled.span`
  font-size: 1rem;
  margin: 0 10px;
`;

const LoadingText = styled.p`
  text-align: center;
  color: #fff;
`;

function UsersList({ selectedMenu }) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const sessionToken = localStorage.getItem('authToken');
      const headers = {
        'Authorization': `Bearer ${sessionToken}`
      };
      const response = await axios.get(`${ROOT_URL}admin/users`, { headers });
      setUsers(response.data.users);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setLoading(false);
    }
  };

  const filteredUsers = users.filter(user => user.role !== 'admin');

  const totalPages = Math.ceil(filteredUsers.length / rowsPerPage);

  const currentUsers = filteredUsers.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const openEditModal = (user) => {
    console.log('CLICKED USER in User:', user)
    setSelectedUser(user);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedUser(null);
  };

  const refreshUsers = async () => {
    fetchData();
  };

  return (
    <TableContainer>
      {selectedMenu === 'Users' && (
        <>
          <TableTitle>USER LIST</TableTitle>
          {loading ? (
            <LoadingText>Loading...</LoadingText>
          ) : (
            <Table>
              <thead>
                <tr>
                  <TableHeader>Username</TableHeader>
                  <TableHeader>First Name</TableHeader>
                  <TableHeader>Last Name</TableHeader>
                  <TableHeader>Phone#</TableHeader>
                  <TableHeader>Gold Coin Bal</TableHeader>
                  <TableHeader>Sweep Stars Bal</TableHeader>
                  <TableHeader></TableHeader>
                </tr>
              </thead>
              <tbody>
                {currentUsers.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell>{user.username}</TableCell>
                    <TableCell>{user.first_name}</TableCell>
                    <TableCell>{user.last_name}</TableCell>
                    <TableCell>{user.phone_number}</TableCell>
                    <TableCell>{user.gold_coins}</TableCell>
                    <TableCell>{user.sweeps_stars}</TableCell>
                    <TableCell>
                      <Actions>
                        <span>VIEW</span>

                        <span onClick={() => openEditModal(user)}>EDIT</span>
                      </Actions>
                    </TableCell>
                  </TableRow>
                ))}
              </tbody>
            </Table>
          )}

          {isModalOpen && (
            <EditUserModal
              user={selectedUser}
              closeModal={closeModal}
              refreshUsers={refreshUsers}
            />
          )}
          <Pagination>
            <PageButton onClick={handlePreviousPage} disabled={currentPage === 1}>
              {'<<'}
            </PageButton>
            <PageIndicator>page {currentPage}</PageIndicator>
            <PageButton onClick={handleNextPage} disabled={currentPage === totalPages}>
              {'>>'}
            </PageButton>
          </Pagination>
        </>
      )}
    </TableContainer>
  );
}

export default UsersList;
